import { Select, List, Spin } from "antd";
import VirtualList from "rc-virtual-list";
import moment from "moment";

import { getTimeFromSeconds } from "../helpers/utils";
import EmptyComponent from "./EmptyComponent";

const MostTrackedTasks = (props) => {
  const {
    mostTrackedTasks,
    isLoading,
    containerHeight,
    onScroll,
    onSelect,
    isTasks,
  } = props;

  return (
    <div
      className="mostTracked"
      style={{
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        width: "600px",
        height: "350px",
        padding: "10px",
        boxShadow:
          "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
      }}
    >
      <div className="" style={{ margin: "0px 10px" }}>
        <span style={{ color: "#17273A", fontSize: "16px" }}>
          <b>Most tracked {isTasks ? "activities" : "projects"} </b>
        </span>

        <Select
          defaultValue={10}
          options={[{ value: 10 }, { value: "all", label: "All" }]}
          style={{
            minWidth: "20px",
            marginLeft: "10px",
          }}
          onSelect={(selectedOption) => {
            onSelect(selectedOption);
          }}
        ></Select>
      </div>
      <Spin spinning={isLoading}>
        {mostTrackedTasks.length === 0 ? (
          <div
            style={{
              width: "300px",
              margin: "0 auto",
              marginTop: "90px",
            }}
          >
            <EmptyComponent
              description={isTasks ? "No data available" : "No data available"}
            />
          </div>
        ) : (
          <List>
            <VirtualList
              data={mostTrackedTasks}
              height={containerHeight}
              itemHeight={5}
              itemKey="id"
              onScroll={onScroll}
            >
              {(task) => {
                const { onlySeconds } = task || {};
                const endTime = moment();
                const { hours, minutes, seconds } = getTimeFromSeconds(
                  onlySeconds
                    ? parseInt(onlySeconds)
                    : endTime.diff(moment(task?.started_at), "seconds")
                );

                return (
                  <List.Item
                    id={task?.id}
                    style={{ margin: "0px 10px", padding: "5px 0px" }}
                  >
                    <>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          gap: "2px",
                          alignItems: "baseline",
                        }}
                      >
                        <span style={{ color: "#17273A", fontSize: "14px" }}>
                          <b>{task?.projectname}</b>
                        </span>
                        {isTasks && (
                          <span style={{ color: "#6D737A", fontSize: "12px" }}>
                            ({task?.taskname})
                          </span>
                        )}

                        <span
                          style={{
                            color: "#17273A",
                            fontSize: "12px",
                            marginLeft: "auto",
                          }}
                        >
                          <b>
                            {hours}:{minutes}:{seconds}
                          </b>
                        </span>
                      </div>
                    </>
                  </List.Item>
                );
              }}
            </VirtualList>
          </List>
        )}
      </Spin>
    </div>
  );
};

export default MostTrackedTasks;
