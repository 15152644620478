import { Select, Space, Spin } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";

import ActivityDoughnutChart from "../components/ActivityDoughnutChart";
import RangePicker from "../components/DateRangePicker";
import MostTrackedTasks from "../components/MostTrackedTasks";
import Bar from "../components/charts/Bar";
import { AuthContext } from "../context/auth-context";
import {
  structureDataForBarChart,
  structureDataForDoughnutChart,
} from "../helpers/project";
import * as Data from "../helpers/server";
import { getDates } from "../helpers/utils";
import useProjectsTasks from "../hooks/useProjectsTasks";

const UserActivityDashboard = () => {
  const pageSize = 10;
  const containerHeight = 270;
  const auth = useContext(AuthContext);

  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf("week"));
  const [endDate, setEndDate] = useState(moment().endOf("week"));
  const [loading, setLoading] = useState(false);
  const [labelsForBarChart, setLabelsForBarChart] = useState([]);
  const [isRecentTasksLoading, setIsRecentTasksLoading] = useState(false);
  const [mostTrackedTasks, setMostTrackedTasks] = useState([]);
  const [mostTrackedProjects, setMostTrackedProjects] = useState([]);
  const [isMostTrackedProjectsLoading, setIsMostTrackedProjectsLoading] =
    useState(false);
  const [shouldLoadMoreProjects, setShouldLoadMoreProjects] = useState(false);
  const [shouldLoadMoreTasks, setShouldLoadMoreTasks] = useState(false);
  const [isLimitAppliedOnTasks, setIsLimitAppliedOnTasks] = useState(true);
  const [isLimitAppliedOnProjects, setIsLimitAppliedOnProjects] =
    useState(false);
  const [isDoughnutLoading, setIsDoughnutLoading] = useState(false);
  const [dataSetsForBarChart, setDataSetsForBarChart] = useState([]);
  const [titleForBarChart, setTitleForBarChart] = useState("00.00.00");
  const [colors, setColors] = useState([]);
  const [labels, setLabels] = useState([]);
  const [percentages, setPercentages] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [projectsPage, setProjectsPage] = useState(1);
  const [tasksPage, setTasksPage] = useState(1);
  const [title, setTitle] = useState(null);
  const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTaskOption, setSelectedTaskOption] = useState("10");
  const [selectedProjectOption, setSelectedProjectOption] = useState("10");

  const onSelectProject = (id) => {
    setMostTrackedProjects([]);
    setMostTrackedTasks([]);
    setProjectsPage(1);
    setTasksPage(1);
    updateTasks(id);
    getUserActivityReports({
      startDate,
      endDate,
      project: id,
      user: selectedUser,
      task: selectedTask,
      team: selectedTeam,
    });
    getUserMostRecentTaskReport({
      page: 1,
      pageSize,
      project: id,
      startDate,
      endDate,
      user: selectedUser,
      task: selectedTask,
      team: selectedTeam,
    });
    getUserMostTrackedProjectsReport({
      page: 1,
      pageSize,
      project: id,
      startDate,
      endDate,
      user: selectedUser,
      task: selectedTask,
      team: selectedTeam,
    });
    getUserTotalTrackedTime({
      startDate,
      endDate,
      user: selectedUser,
      project: id,
      task: selectedTask,
      team: selectedTeam,
    });
  };

  const onSelectTask = (id) => {
    setProjectsPage(1);
    setTasksPage(1);
    setMostTrackedProjects([]);
    setMostTrackedTasks([]);
    getUserActivityReports({
      startDate,
      endDate,
      project: selectedProject,
      user: selectedUser,
      task: id,
      team: selectedTeam,
    });
    getUserMostRecentTaskReport({
      page: 1,
      pageSize,
      project: selectedProject,
      startDate,
      endDate,
      user: selectedUser,
      task: id,
      team: selectedTeam,
    });
    getUserMostTrackedProjectsReport({
      page: 1,
      pageSize,
      project: selectedProject,
      startDate,
      endDate,
      user: selectedUser,
      task: id,
      team: selectedTeam,
    });
    getUserTotalTrackedTime({
      startDate,
      endDate,
      user: selectedUser,
      project: selectedProject,
      task: id,
      team: selectedTeam,
    });
  };

  const onClearProject = () => {
    setMostTrackedProjects([]);
    setMostTrackedTasks([]);
    getUserActivityReports({
      startDate,
      endDate,
      project: null,
      user: selectedUser,
      task: null,
      team: selectedTeam,
    });
    getUserMostRecentTaskReport({
      page: tasksPage,
      pageSize,
      project: null,
      startDate,
      endDate,
      user: selectedUser,
      team: selectedTeam,
    });
    getUserMostTrackedProjectsReport({
      page: projectsPage,
      pageSize,
      project: null,
      startDate,
      endDate,
      user: selectedUser,
      team: selectedTeam,
    });
    getUserTotalTrackedTime({
      startDate,
      endDate,
      user: selectedUser,
      project: null,
      team: selectedTeam,
    });
  };

  const onClearTask = () => {
    setMostTrackedProjects([]);
    setMostTrackedTasks([]);
    setProjectsPage(1);
    setTasksPage(1);
    getUserActivityReports({
      startDate,
      endDate,
      project: selectedProject,
      user: selectedUser,
      task: null,
      team: selectedTeam,
    });
    getUserMostRecentTaskReport({
      page: 1,
      pageSize,
      project: selectedProject,
      startDate,
      endDate,
      user: selectedUser,
      task: null,
      team: selectedTeam,
    });
    getUserMostTrackedProjectsReport({
      page: 1,
      pageSize,
      project: selectedProject,
      startDate,
      endDate,
      user: selectedUser,
      task: null,
      team: selectedTeam,
    });
    getUserTotalTrackedTime({
      startDate,
      endDate,
      user: selectedUser,
      project: selectedProject,
      task: null,
      team: selectedTeam,
    });
  };

  const {
    projects,
    projectsLoading,
    clearProject,
    clearTask,
    tasks,
    tasksLoading,
    selectProject,
    selectTask,
    selectedProject,
    selectedTask,
    updateProjects,
    updateTasks,
  } = useProjectsTasks({
    onSelectProject,
    onClearTask,
    onSelectTask,
    onClearProject,
  });

  const updateUsers = async (keyword) => {
    try {
      if (auth?.token) {
        setIsUsersLoading(true);
        const { users, err } = await Data.getAllUsers(auth?.token, keyword, {
          team: selectedTeam,
        });
        setIsUsersLoading(false);

        if (err) return console.log(err);
        setUsers(users);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const selectUser = (id) => {
    setSelectedUser(id);
    setMostTrackedProjects([]);
    setMostTrackedTasks([]);
    setProjectsPage(1);
    setTasksPage(1);
    getUserActivityReports({
      startDate,
      endDate,
      project: selectedProject,
      user: id,
      task: selectedTask,
      team: selectedTeam,
    });
    getUserMostRecentTaskReport({
      page: 1,
      pageSize,
      project: selectedProject,
      startDate,
      endDate,
      user: id,
      task: selectedTask,
      team: selectedTeam,
    });
    getUserMostTrackedProjectsReport({
      page: 1,
      pageSize,
      project: selectedProject,
      startDate,
      endDate,
      user: id,
      task: selectedTask,
      team: selectedTeam,
    });
    getUserTotalTrackedTime({
      startDate,
      endDate,
      user: id,
      project: selectedProject,
      task: selectedTask,
      team: selectedTeam,
    });
  };

  const updateTeams = async (keyword) => {
    try {
      if (auth?.token) {
        setTeamsLoading(true);
        const { teams, err } = await Data.getTeams(
          { keyword, managing: true },
          auth?.token
        );

        setTeamsLoading(false);
        if (err) return;

        setTeams(
          teams?.map((team) => ({
            ...team,
            label: team.teamname,
            value: team.teamid,
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const selectTeam = (id) => {
    setSelectedTeam(id);
    setProjectsPage(1);
    setTasksPage(1);
    setMostTrackedProjects([]);
    setMostTrackedTasks([]);
    if (!auth?.isadmin) {
      updateUsers();
      setSelectedUser(null);
    }
    getUserActivityReports({
      startDate,
      endDate,
      project: selectedProject,
      user: !auth?.isadmin ? null : selectedUser,
      task: selectedTask,
      team: id,
    });
    getUserMostRecentTaskReport({
      page: 1,
      pageSize,
      project: selectedProject,
      startDate,
      endDate,
      user: !auth?.isadmin ? null : selectedUser,
      task: selectedTask,
      team: id,
    });
    getUserMostTrackedProjectsReport({
      page: 1,
      pageSize,
      project: selectedProject,
      startDate,
      endDate,
      user: !auth?.isadmin ? null : selectedUser,
      task: selectedTask,
      team: id,
    });
    getUserTotalTrackedTime({
      startDate,
      endDate,
      user: !auth?.isadmin ? null : selectedUser,
      project: selectedProject,
      task: selectedTask,
      team: id,
    });
  };

  const onDateRangeChange = async (e) => {
    const range = e.currentTarget.value.split(" - ");
    const startDate = moment(range[0], "DD.MM.YYYY").startOf("day");
    const endDate = moment(range[1], "DD.MM.YYYY").endOf("day");

    setStartDate(startDate);
    setEndDate(endDate);

    const dates = getDates(startDate, endDate);

    await getUserActivityReports({
      startDate,
      endDate,
      project: selectedProject,
      labels: dates,
      user: selectedUser,
      task: selectedTask,
      team: selectedTeam,
    });

    setMostTrackedTasks([]);
    setTasksPage(1);
    await getUserMostRecentTaskReport({
      page: 1,
      pageSize,
      project: selectedProject,
      startDate,
      endDate,
      user: selectedUser,
      task: selectedTask,
      team: selectedTeam,
    });

    setMostTrackedProjects([]);
    setProjectsPage(1);

    getUserMostTrackedProjectsReport({
      page: 1,
      pageSize,
      project: selectedProject,
      startDate,
      endDate,
      user: selectedUser,
      task: selectedTask,
      team: selectedTeam,
    });

    getUserTotalTrackedTime({
      startDate,
      endDate,
      user: selectedUser,
      project: selectedProject,
      task: selectedTask,
      team: selectedTeam,
    });
  };

  const getUserActivityReports = async ({
    user,
    startDate,
    endDate,
    project,
    task,
    team,
  }) => {
    try {
      if (auth?.token) {
        setLoading(true);
        const { tasks, err } = await Data.getUserActivityReports(
          {
            startDate: startDate.startOf("day").format("yyyy-MM-DDTHH:mm:ss"),
            endDate: endDate.endOf("day").format("yyyy-MM-DDTHH:mm:ss"),
            project,
            user: user
              ? user
              : auth?.isadmin
              ? undefined
              : team
              ? undefined
              : auth?.userId,
            task,
            team,
          },
          auth?.token
        );
        setLoading(false);
        if (err) return console.log(err);

        const dates = getDates(startDate, endDate);
        const { dataSets, title } = structureDataForBarChart(tasks, dates);
        setTitleForBarChart(title);
        setDataSetsForBarChart(dataSets);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUserTotalTrackedTime = async ({
    startDate,
    endDate,
    user,
    project,
    task,
    team,
  }) => {
    try {
      setIsDoughnutLoading(true);
      const { tracking, err } = await Data.getTotalTrackedTime(
        {
          startDate: startDate.startOf("day").format("yyyy-MM-DDTHH:mm:ss"),
          endDate: endDate.endOf("day").format("yyyy-MM-DDTHH:mm:ss"),
          id: user
            ? user
            : auth?.isadmin
            ? undefined
            : team
            ? undefined
            : auth?.userId,
          project,
          task,
          team,
        },
        auth?.token
      );
      setIsDoughnutLoading(false);
      if (err) return console.log(err);

      const {
        labels,
        colors,
        title,
        projects: projectsData,
        percentages,
      } = structureDataForDoughnutChart(tracking);

      setTitle(title);
      setLabels(labels);
      setColors(colors);
      setProjectsData(projectsData);
      setPercentages(percentages);
    } catch (err) {
      console.log(err);
    }
  };

  const onTasksScroll = async (e) => {
    if (
      e.currentTarget.scrollHeight - Math.round(e.currentTarget.scrollTop) <=
        e.currentTarget.clientHeight &&
      shouldLoadMoreTasks &&
      !isLimitAppliedOnTasks
    ) {
      const newPage = tasksPage + 1;
      setTasksPage(newPage);
      await getUserMostRecentTaskReport({
        page: newPage,
        pageSize,
        project: selectedProject,
        startDate,
        endDate,
        user: selectedUser,
        team: selectedTeam,
      });
    }
  };

  const onProjectsScroll = async (e) => {
    if (
      e.currentTarget.scrollHeight - Math.round(e.currentTarget.scrollTop) <=
        e.currentTarget.clientHeight &&
      shouldLoadMoreProjects &&
      !isLimitAppliedOnProjects
    ) {
      const newPage = projectsPage + 1;
      setProjectsPage(newPage);
      await getUserMostTrackedProjectsReport({
        page: newPage,
        pageSize,
        project: selectedProject,
        startDate,
        endDate,
        user: selectedUser,
        team: selectedTeam,
      });
    }
  };

  const getUserMostRecentTaskReport = async ({
    page,
    pageSize,
    project,
    startDate,
    endDate,
    user,
    task,
    team,
  }) => {
    try {
      if (auth?.token) {
        setIsRecentTasksLoading(true);
        const { mostTrackedTasks, err } =
          await Data.getUserMostRecentTaskReport(
            {
              page,
              pageSize,
              project,
              startDate: startDate.startOf("day").format("yyyy-MM-DDTHH:mm:ss"),
              endDate: endDate.endOf("day").format("yyyy-MM-DDTHH:mm:ss"),
              user: user
                ? user
                : auth?.isadmin
                ? undefined
                : team
                ? undefined
                : auth?.userId,
              task,
              team,
            },
            auth?.token
          );
        setIsRecentTasksLoading(false);

        if (err) return console.log(err);

        if (mostTrackedTasks.length < pageSize) setShouldLoadMoreTasks(false);
        else setShouldLoadMoreTasks(true);

        for (let i = 0; i < mostTrackedTasks.length; i++) {
          mostTrackedTasks[i].color = mostTrackedTasks[0]?.projectcolor;
        }

        setMostTrackedTasks((prev) => prev.concat(mostTrackedTasks));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUserMostTrackedProjectsReport = async ({
    page,
    pageSize,
    project,
    startDate,
    endDate,
    user,
    task,
    team,
  }) => {
    try {
      if (auth?.token) {
        setIsMostTrackedProjectsLoading(true);
        const { mostTrackedProjects, err } =
          await Data.getUserMostTrackedProjectsReport(
            {
              page,
              pageSize,
              project,
              startDate: startDate.startOf("day").format("yyyy-MM-DDTHH:mm:ss"),
              endDate: endDate.endOf("day").format("yyyy-MM-DDTHH:mm:ss"),
              user: user
                ? user
                : auth?.isadmin
                ? undefined
                : team
                ? undefined
                : auth?.userId,
              task,
              team,
            },
            auth?.token
          );
        setIsMostTrackedProjectsLoading(false);

        if (err) return console.log(err);

        if (mostTrackedProjects.length < pageSize)
          setShouldLoadMoreProjects(false);
        else setShouldLoadMoreProjects(true);

        setMostTrackedProjects((prev) => prev.concat(mostTrackedProjects));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onTasksSelect = (selectedOption) => {
    setSelectedTaskOption(selectedOption);
    setTasksPage(1);
    if (selectedOption === "10") {
      if (mostTrackedTasks.length > "10") {
        setMostTrackedTasks((prev) => prev.slice(0, 10));
      }
      setIsLimitAppliedOnTasks(true);
    } else if (selectedOption === "all") {
      setIsLimitAppliedOnTasks(false);
    }
  };

  const onProjectsSelect = (selectedOption) => {
    setSelectedProjectOption(selectedOption);
    setProjectsPage(1);
    if (selectedOption === "10") {
      if (mostTrackedProjects.length > 10) {
        setMostTrackedProjects((prev) => prev.slice(0, 10));
      }
      setIsLimitAppliedOnProjects(true);
    } else if (selectedOption === "all") {
      setIsLimitAppliedOnProjects(false);
    }
  };

  useEffect(() => {
    const dates = getDates(startDate, endDate);
    setLabelsForBarChart(dates);
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectedTaskOption !== "10" && selectedTaskOption.length < pageSize)
      setShouldLoadMoreTasks(true);
    if (
      selectedProjectOption !== "10" &&
      selectedProjectOption.length < pageSize
    )
      setShouldLoadMoreProjects(true);
    if (selectedTaskOption === "10") setIsLimitAppliedOnTasks(true);
    else setIsLimitAppliedOnTasks(false);

    if (selectedProjectOption === "10") setIsLimitAppliedOnProjects(true);
    else setIsLimitAppliedOnProjects(false);
  }, [selectedTaskOption, selectedProjectOption]);

  useEffect(() => {
    if (auth?.token) {
      updateProjects();
      updateTeams();
      if (auth?.isadmin) updateUsers();
      getUserActivityReports({
        startDate,
        endDate,
        project: selectedProject,
        user: selectedUser,
        task: selectedTask,
        team: selectedTeam,
      });
      getUserMostRecentTaskReport({
        page: tasksPage,
        pageSize,
        project: selectedProject,
        startDate,
        endDate,
        user: selectedUser,
        task: selectedTask,
        team: selectedTeam,
      });
      getUserMostTrackedProjectsReport({
        page: projectsPage,
        pageSize,
        project: selectedProject,
        startDate,
        endDate,
        user: selectedUser,
        task: selectedTask,
        team: selectedTeam,
      });
      getUserTotalTrackedTime({
        startDate,
        endDate,
        user: selectedUser,
        project: selectedProject,
        task: selectedTask,
        team: selectedTeam,
      });
    }
  }, [auth?.token]);

  const clearUser = () => {
    setSelectedUser(null);
    setMostTrackedProjects([]);
    setMostTrackedTasks([]);
    setTasksPage(1);
    setProjectsPage(1);
    getUserActivityReports({
      startDate,
      endDate,
      project: selectedProject,
      user: null,
      task: selectedTask,
      team: selectedTeam,
    });
    getUserMostRecentTaskReport({
      page: 1,
      pageSize,
      project: selectedProject,
      startDate,
      endDate,
      user: null,
      task: selectedTask,
      team: selectedTeam,
    });
    getUserMostTrackedProjectsReport({
      page: 1,
      pageSize,
      project: selectedProject,
      startDate,
      endDate,
      user: null,
      task: selectedTask,
      team: selectedTeam,
    });
    getUserTotalTrackedTime({
      startDate,
      endDate,
      user: null,
      project: selectedProject,
      task: selectedTask,
      team: selectedTeam,
    });
  };

  const clearTeam = () => {
    setSelectedTeam(null);
    setMostTrackedProjects([]);
    setMostTrackedTasks([]);
    setProjectsPage(1);
    setTasksPage(1);

    if (!auth?.isadmin) {
      setSelectedUser(null);
    }

    getUserActivityReports({
      startDate,
      endDate,
      project: selectedProject,
      user: !auth?.isadmin ? null : selectedUser,
      task: selectedTask,
      team: null,
    });
    getUserMostRecentTaskReport({
      page: 1,
      pageSize,
      project: selectedProject,
      startDate,
      endDate,
      user: !auth?.isadmin ? null : selectedUser,
      task: selectedTask,
      team: null,
    });
    getUserMostTrackedProjectsReport({
      page: 1,
      pageSize,
      project: selectedProject,
      startDate,
      endDate,
      user: !auth?.isadmin ? null : selectedUser,
      task: selectedTask,
      team: null,
    });
    getUserTotalTrackedTime({
      startDate,
      endDate,
      user: !auth?.isadmin ? null : selectedUser,
      project: selectedProject,
      task: selectedTask,
      team: null,
    });
  };

  return (
    <>
      <main className="content container-new UserActivityDashboard">
        <div className="">
          {/* <Search placeholder="Search" style={{ width: "130px" }} /> */}

          <Space style={{ float: "right", marginBottom: "4px" }}>
            <Select
              allowClear
              onClear={clearTeam}
              placeholder="Select Team"
              loading={teamsLoading}
              value={selectedTeam}
              onSearch={updateTeams}
              onSelect={selectTeam}
              filterOption={false}
              showSearch
              dropdownMatchSelectWidth={false}
              options={teams}
              style={{ minWidth: "100px" }}
            ></Select>

            <Select
              allowClear
              onClear={clearUser}
              placeholder="Select User"
              loading={isUsersLoading}
              value={selectedUser}
              onSearch={updateUsers}
              onSelect={selectUser}
              filterOption={false}
              showSearch
              dropdownMatchSelectWidth={false}
              options={users}
              style={{ minWidth: "100px" }}
              disabled={!selectedTeam && !auth?.isadmin}
            ></Select>

            <Select
              allowClear
              onClear={clearProject}
              placeholder="Select Project"
              loading={projectsLoading}
              value={selectedProject}
              onSearch={updateProjects}
              onSelect={selectProject}
              filterOption={false}
              showSearch
              dropdownMatchSelectWidth={false}
              options={projects}
              style={{ minWidth: "100px" }}
            ></Select>
            <Select
              allowClear
              onClear={clearTask}
              placeholder="Select Task"
              loading={tasksLoading}
              value={selectedTask}
              onSearch={(keyword) => updateTasks(selectedProject, keyword)}
              onSelect={selectTask}
              filterOption={false}
              showSearch
              dropdownMatchSelectWidth={false}
              options={tasks}
              style={{ minWidth: "100px" }}
              disabled={
                !selectedProject ||
                (auth?.latestTask && !auth?.latestTask?.stopped_at)
              }
            ></Select>
            <RangePicker
              onChange={onDateRangeChange}
              startDate={startDate}
              endDate={endDate}
            />
          </Space>
        </div>
        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "90%",
            margin: "20px auto",
          }}
        >
          <div
            className=""
            style={{
              backgroundColor: "#ffffff",
              borderRadius: "10px",
              padding: "10px",
              marginTop: "10px",
              boxShadow:
                "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
            }}
          >
            <Spin spinning={isRecentTasksLoading}>
              <Bar
                labels={labelsForBarChart}
                label={"Working Hours"}
                data={dataSetsForBarChart}
                dataSets={dataSetsForBarChart}
              />
              <p
                style={{
                  color: "#17273A",
                  fontSize: "14px",
                  marginTop: "17px",
                  marginLeft: "40px",
                }}
              >
                Total Tracked {startDate.format("DD MMM YYYY")}&nbsp;-&nbsp;
                {endDate.format("DD MMM YYYY")}:&nbsp;&nbsp;&nbsp;&nbsp;
                <b>{titleForBarChart} </b>
              </p>
            </Spin>
          </div>
          <div
            className=""
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <ActivityDoughnutChart
              {...{
                colors,
                isDoughnutLoading,
                labels,
                percentages,
                projectsData,
                title,
              }}
            />

            <MostTrackedTasks
              isLoading={isMostTrackedProjectsLoading}
              mostTrackedTasks={mostTrackedProjects}
              onScroll={onProjectsScroll}
              onSelect={onProjectsSelect}
              containerHeight={containerHeight}
              style={{ width: "100%" }}
            />

            <MostTrackedTasks
              isLoading={isRecentTasksLoading}
              mostTrackedTasks={mostTrackedTasks}
              onScroll={onTasksScroll}
              onSelect={onTasksSelect}
              containerHeight={containerHeight}
              style={{ width: "100%" }}
              isTasks={true}
            />
          </div>
        </div>
      </main>
    </>
  );
};

export default UserActivityDashboard;
