import React from "react";

import "./style.css";

function NumberedParagraphsSection() {
  return (
    <section className="how-its-working" id="working">
      <div className="main-container">
        <div className="heading-large">
          <h2 className="section-heading">How it’s working</h2>
        </div>
        <div className="section-row">
          <div className="p-container">
            <h3 className="section-subheading">01.</h3>
            <p className="writing-content">
              You just have to click on the “sign-up” button in Slack and
              Axolotl will run
            </p>
          </div>
          <div className="p-container">
            <h3 className="section-subheading">02.</h3>
            <p className="writing-content">
              You can track your time by clicking the “Start” button in the
              dashboard but you can track the time also in Slack
            </p>
          </div>
          <div className="p-container">
            <h3 className="section-subheading">03.</h3>
            <p className="writing-content">
              By pressing the "Stop" button, you end the work of the Axolotl and
              receive an activity report on the activity graph.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default NumberedParagraphsSection;
