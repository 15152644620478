import { Doughnut as DoughnutChart } from "react-chartjs-2";

const Doughnut = (props) => {
  const { labels, colors, projects, percentages, width, height } = props;

  const plugins = [
    {
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          const centerConfig = chart.config.options.elements.center;
          const title = centerConfig.text;
          const width = chart.width;
          const height = chart.height;
          const ctx = chart.ctx;

          var fontSize = (height / 240).toFixed(2);
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = "top";
          var text = `${title}`,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
          ctx.fillText(text, textX, textY);
        }
      },
    },
  ];

  const options = {
    plugins: {
      tooltip: {
        usePointStyle: true,
        boxWidth: 6,
        boxPadding: 2,
        padding: { top: 8, left: 5, right: 5, bottom: 6 },
        borderWidth: 1,
        callbacks: {
          label: (tooltipItem) => {
            const { dataIndex } = tooltipItem || {};
            return `${labels[dataIndex]}    ${
              projects[dataIndex].tracked
            }     ${percentages[dataIndex]?.toLocaleString("fullwide", {
              maximumFractionDigits: 2,
            })}%`;
          },
          labelColor: (context) => {
            const { dataIndex } = context || {};
            return {
              borderColor: colors[dataIndex],
              backgroundColor: colors[dataIndex],
              borderRadius: "50%",
            };
          },
        },
      },
    },
    elements: {
      center: {
        text: props.title,
      },
    },
  };

  const data = {
    datasets: [
      {
        data: percentages,
        backgroundColor: colors,
      },
    ],
  };

  return (
    <DoughnutChart
      data={data}
      options={options}
      plugins={plugins}
      width={width}
      height={height}
    />
  );
};

export default Doughnut;
