import { Button } from "@themesberg/react-bootstrap";
import { useSetState } from "ahooks";
import { Card, Form, Input } from "antd";
import { useCallback, useContext, useEffect } from "react";

import { AuthContext } from "../context/auth-context";
import { chromeExtensionId } from "../data/constants";
import * as Data from "../helpers/server";

/* global chrome */

const UpdateUserConfigs = () => {
  const auth = useContext(AuthContext);
  const [form] = Form.useForm();

  const [state, setState] = useSetState({
    status: "pending",
    breakstatus: auth?.breakstatus,
    error: null,
  });

  useEffect(() => {
    if (auth && status === "pending") {
      setState({
        status: "idle",
      });
    }
  }, [auth]);

  const updateConfigs = useCallback(async ({ breakstatus }) => {
    setState({ status: "pending" });

    const { err } = await Data.updateUserConfigs({ breakstatus }, auth?.token);
    if (err) return setState({ status: "error", error: err });

    setState({
      status: "complete",
      error: null,
      breakstatus,
    });

    if (window.navigator.userAgent.toLowerCase().includes("chrome")) {
      chrome.runtime?.sendMessage(chromeExtensionId, auth);
    }

    auth.login(
      auth.userId,
      auth.token,
      auth.name,
      auth.email,
      auth.image,
      auth.isadmin,
      auth.isprojectmanager,
      auth.uid,
      auth.isAppInstalled,
      auth.workspaceId,
      breakstatus,
      auth?.isteammanager
    );
  });

  const { status, error, breakstatus } = state;
  return (
    <div className="userSettingList">
      <Card title="Update Configs" loading={status === "pending"}>
        <Form
          form={form}
          style={{}}
          wrapperCol={{ span: 16 }}
          labelCol={{ span: 7 }}
          name="basic"
          initialValues={{
            breakstatus,
          }}
          onFinish={({ breakstatus }) => {
            updateConfigs({
              breakstatus,
            });
          }}
          onFieldsChange={() => {
            setState({ status: "idle", error: null });
          }}
        >
          <Form.Item
            label="Break Status"
            name="breakstatus"
            rules={[
              {
                required: true,
                message: "Please input break status.",
                min: 3,
                message: "Must be 3 letters long.",
              },
            ]}
            hasFeedback={!!error}
            validateStatus={error && "error"}
          >
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item
            label=" "
            colon={false}
            help={(() => {
              if (error) {
                return error.message;
              }

              if (status === "complete") {
                return "status updated successfully!";
              }
            })()}
          >
            <Button
              disabled={status === "pending"}
              type="primary"
              htmlType="submit"
              className="mb-1"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default UpdateUserConfigs;
