import Navbar from "./Navbar";
import Header from "./Header";
import AboutUs from "./AboutUs";
import ImageCon from "./ImageCon";
import HowItsWorking from "./HowItsWorking";
import ImageL from "./ImageL";
import SlackCommand from "./SlackCommand/SlackCommand";
import LandingPageFooter from "./Footer";

import "./HomePage.css";

const HomePage = () => {
  return (
    <div class="background">
      <Navbar />
      <Header />
      <AboutUs />
      <ImageCon />
      <HowItsWorking />
      <ImageL />
      <SlackCommand />
      <LandingPageFooter />
    </div>
  );
};

export default HomePage;
