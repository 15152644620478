import { Modal, Form, Input, DatePicker, TimePicker, Row, Col } from "antd";
import { Button } from "@themesberg/react-bootstrap";
import { useEffect, useState } from "react";
import moment from "moment";
import BootstrapLoader from "./BootstrapLoader";

const format = "HH:mm";

const EditTrackingTimeModal = (props) => {
  const [form] = Form.useForm();

  const startTime = Form.useWatch("starttime", form);
  const stopTime = Form.useWatch("stoptime", form);
  const date = Form.useWatch("date", form);

  const [err, setErr] = useState(null);
  const { showModal, onCancel, onOk, selectedTrackingHistory, loading } = props;

  const isFormEmpty = () => {
    const startTimeValue = form.getFieldValue("starttime");
    const stopTimeValue = form.getFieldValue("stoptime");
    const dateValue = form.getFieldValue("date");

    return !startTimeValue || !stopTimeValue || !dateValue;
  };

  const handleClear = () => {
    form.resetFields();
    setErr(null);
  };
  useEffect(() => {
    form.setFieldsValue({});
  }, [selectedTrackingHistory]);

  useEffect(() => {
    if (!date || !startTime || !stopTime) return;

    const startDate = moment(date);
    startDate.set("hours", startTime.get("hours"));
    startDate.set("minutes", startTime.get("minutes"));
    const stopDate = moment(date);
    stopDate.set("hours", stopTime.get("hours"));
    stopDate.set("minutes", stopTime.get("minutes"));

    if (startDate > stopDate)
      setErr("Start time can not be earlier than stop time");
    else if (stopDate > moment()) setErr("You can not set future time");
    else setErr(null);
  }, [startTime, stopTime, date]);

  return (
    <Modal
      title="Update Tracking Time"
      open={showModal}
      className="select-none"
      onOk={onOk}
      centered
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      footer={[
        <div>
          {err && <p style={{ color: "red", textAlign: "center" }}>{err}</p>}
          <div>
            <Button
              key="back"
              onClick={() => {
                handleClear();
                onCancel();
              }}
              style={{
                border: "2px solid #d1d5db",
                backgroundColor: "transparent",
                color: "black",
              }}
            >
              Cancel
            </Button>{" "}
            <Button
              value={selectedTrackingHistory.taskhistoryid}
              key="submit"
              type="primary"
              onClick={form.submit}
              disabled={loading || isFormEmpty()}
            >
              Update
              {loading && <BootstrapLoader />}
            </Button>
          </div>
        </div>,
      ]}
    >
      <Form
        name="basic"
        form={form}
        layout="vertical"
        style={{ marginTop: "10px" }}
        initialValues={{
          starttime: moment(
            selectedTrackingHistory?.updated_started_at ||
              selectedTrackingHistory?.original_started_at ||
              selectedTrackingHistory?.started_at
          ),
          stoptime: moment(
            selectedTrackingHistory?.updated_stopped_at ||
              (selectedTrackingHistory?.th_original_stopped_at !==
              selectedTrackingHistory?.stopped_at
                ? selectedTrackingHistory?.th_original_stopped_at
                : selectedTrackingHistory?.original_stopped_at ||
                  selectedTrackingHistory?.stopped_at)
          ),
          originalstarttime: moment(
            selectedTrackingHistory?.original_started_at ||
              selectedTrackingHistory?.started_at
          ),
          originalstoptime: moment(
            selectedTrackingHistory?.th_original_stopped_at !==
              selectedTrackingHistory?.stopped_at
              ? selectedTrackingHistory?.th_original_stopped_at
              : selectedTrackingHistory?.original_stopped_at ||
                  selectedTrackingHistory?.stopped_at
          ),
          originaldate: moment(
            selectedTrackingHistory?.th_original_stopped_at ||
              selectedTrackingHistory?.taskhistory_original_stopped_at
          ),
          date: moment(
            selectedTrackingHistory?.updated_started_at ||
              selectedTrackingHistory?.original_started_at ||
              selectedTrackingHistory?.started_at
          ),
        }}
        onFinish={onOk}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Start Time" name="originalstarttime">
              <TimePicker
                format={format}
                disabled={true}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Updated Start Time" name="starttime">
              <TimePicker format={format} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Date" name="originaldate">
              <DatePicker disabled={true} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Updated Date" name="date">
              <DatePicker
                style={{ width: "100%" }}
                disabledDate={(current) => current.isAfter(moment())}
                showTime
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>
        </Row>

        {selectedTrackingHistory.stopped_at && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Stop Time" name="originalstoptime">
                <TimePicker
                  format={format}
                  disabled={true}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Updated Stop Time" name="stoptime">
                <TimePicker format={format} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default EditTrackingTimeModal;
