import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Footer from "../components/Footer";
import Loader from "../components/Loader";
import SuccessfulSignup from "../components/SuccessfulSignup";
import { chromeExtensionId } from "../data/constants";
import * as Data from "../helpers/server";
import ServerError from "./ServerError";

/* global chrome */

const SlackAuthRedirectInstall = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [successfullyInstalled, setSuccessfullyInstalled] = useState(false);
  const [code] = useSearchParams();

  useEffect(() => {
    const postRedirectRequest = async () => {
      setIsLoading(true);

      if (sessionStorage.getItem("slack-state") !== code.get("state")) {
        setErr(true);
        setIsLoading(false);
        return;
      }

      const { user, token } = await Data.installSlackApp(code.get("code"));

      if (window.navigator.userAgent.toLowerCase().includes("chrome")) {
        chrome.runtime?.sendMessage(chromeExtensionId, {
          userId: user?.id,
          token,
          ...user,
        });
      }

      setIsLoading(false);
      setSuccessfullyInstalled(true);
    };

    postRedirectRequest();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (err) return <ServerError />;

  if (successfullyInstalled) return <SuccessfulSignup />;

  return (
    <>
      <main className="content container-new"></main>
      <Footer />
    </>
  );
};

export default SlackAuthRedirectInstall;
