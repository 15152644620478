import axios from "axios";
import moment from "moment";
import { PagesRoutes } from "../routes";

export const postAPI = async (
  endpoint,
  data = {},
  headers = {},
  method = "POST",
  options = {}
) => {
  const config = {
    url: endpoint,
    method,
    data: data,
    headers,
    ...options,
  };

  const res = await axios(config).catch((err) => {
    if (err?.response?.status === 401) {
      localStorage.clear();
      window.location.replace(PagesRoutes.HomePage.path);
    }
  });

  return res?.data || res?.err;
};

export const getTimeStringFromSeconds = (secondsParam) => {
  const hours = Math.floor(secondsParam / 3600);
  const minutes = Math.floor((secondsParam / 60) % 60);
  const seconds = Math.floor(secondsParam % 60);

  const hoursString =
    hours > 0 ? `${hours} ${hours === 1 ? "hour" : "hours"}` : "";
  const minutesString =
    minutes > 0 ? `${minutes} ${minutes === 1 ? "minute" : "minutes"}` : "";
  const secondsString =
    seconds > 0 ? `${seconds} ${seconds === 1 ? "second" : "seconds"}` : "";

  return `${hoursString} ${minutesString} ${secondsString}`;
};

export const getTimeFromSeconds = (secondsParam) => {
  const hours = Math.floor(secondsParam / 3600).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const minutes = Math.floor((secondsParam / 60) % 60).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const seconds = Math.floor(secondsParam % 60).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  return { hours, minutes, seconds };
};

export const getHourFromSeconds = (secondsParam) => {
  return parseFloat(
    (secondsParam / 3600).toLocaleString("fullwide", {
      maximumFractionDigits: 2,
    })
  );
};

export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const getDates = (startDate, endDate) => {
  const dateArr = [];

  startDate = moment(startDate).startOf("day");
  endDate = moment(endDate).startOf("day");

  const currentDate = startDate;
  while (endDate.diff(currentDate, "days") >= 0) {
    dateArr.push(currentDate.format("ddd, MMM DD"));
    currentDate.add(1, "day");
  }

  return dateArr;
};

export const downloadFile = (data, name, type) => {
  const dataStr =
    typeof data === "string"
      ? `data:text/${type};charset=utf-8,${encodeURIComponent(data)}`
      : `data:text/${type};charset=utf-8,${encodeURIComponent(
          JSON.stringify(data)
        )}`;
  const downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", `${name}.${type}`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const getHourMinuteStringsFromSeconds = (secondsParam) => {
  const hours = Math.floor(secondsParam / 3600);
  const minutes = Math.floor((secondsParam / 60) % 60);
  const seconds = Math.floor(secondsParam % 60);

  const hoursString = hours > 0 ? `${hours} ${hours === 1 ? "hr" : "hrs"}` : "";
  const minutesString =
    minutes > 0 ? `${minutes} ${minutes === 1 ? "min" : "min"}` : "";
  const secondsString =
    seconds > 0 ? `${seconds} ${seconds === 1 ? "sec" : "sec"}` : "";

  if (minutes === 0 && hours === 0) return `${secondsString}`;
  if (hours === 0) return `${minutesString}`;

  return `${hoursString}, ${minutesString}`;
};

export const getIndexOfOccurance = (string, character, index) => {
  return string.split(character, index).join(character).length;
};

export const capitalizeFirstLetter = (string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : "";

export const removeDuplicates = (arr, key) => {
  const uniqueObjects = new Map();
  arr.forEach((obj) => {
    const id = obj[key];
    if (!uniqueObjects.has(id)) uniqueObjects.set(id, obj);
  });

  return Array.from(uniqueObjects.values());
};

export const mergeTasks = (tasksArray) => {
  const mergedTasks = {};
  const mergedArrayTasks = {};

  for (let i = 0; i < tasksArray.length; i++) {
    const task = tasksArray[i];

    const key = `${task.projectid}-${task.taskid}-${task.userId}`;
    const stopped_at = task.stopped_at;
    const started_at = task.started_at;
    const endTime = stopped_at ? moment(stopped_at) : moment();
    const diff = endTime.diff(moment(started_at), "seconds");
    const newTask = { ...task, key, totalSeconds: diff };

    if (!mergedTasks[key]) {
      mergedTasks[key] = newTask;
      mergedArrayTasks[key] = [newTask];
    } else {
      mergedTasks[key].totalSeconds += diff;
      mergedArrayTasks[key] = [...mergedArrayTasks[key], newTask];
    }
  }

  const updatedMergedTasks = {};

  for (let i = 0; i < Object.keys(mergedArrayTasks).length; i++) {
    const key = Object.keys(mergedArrayTasks)[i];
    const values = mergedArrayTasks[key];
    const updatedValues = values.map((task) => {
      const stopped_at = task.stopped_at;
      const started_at = task.started_at;
      const endTime = stopped_at ? moment(stopped_at) : moment();
      const diff = endTime.diff(moment(started_at), "seconds");

      return { ...task, totalSeconds: diff };
    });

    updatedMergedTasks[key] = updatedValues;
  }

  return { tasks: Object.values(mergedTasks), mergedTasks: updatedMergedTasks };
};

export const getUpdatedObjects = (firstArray, secondArray) => {
  return secondArray.filter((secondObj) => {
    const matchingObj = firstArray.find(
      (firstObj) => firstObj.id === secondObj.id
    );
    return matchingObj && !isEqual(matchingObj, secondObj);
  });
};

const isEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;

  return keys1.every((key) => obj1[key] === obj2[key]);
};
