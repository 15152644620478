import moment from "moment";
import { useCallback, useEffect, useState } from "react";

export function useAuth() {
  const [token, setToken] = useState(false);
  const [userId, setUserId] = useState();
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [email, setEmail] = useState();
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [isadmin, setIsAdmin] = useState(false);
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [uid, setUid] = useState();
  const [isprojectmanager, setIsProjectManager] = useState(false);
  const [isteammanager, setIsTeamManager] = useState(false);
  const [workspaceId, setWorkspaceId] = useState(null);
  const [breakstatus, setBreakStatus] = useState(null);

  const login = useCallback(
    (
      userId,
      token,
      name,
      email,
      image,
      isadmin,
      isprojectmanager,
      uid,
      isAppInstalled,
      workspaceId,
      breakstatus,
      isteammanager
    ) => {
      setToken(token);
      setUserId(userId);
      setName(name);
      setImage(image);
      setEmail(email);
      setIsAdmin(isadmin);
      setIsAppInstalled(isAppInstalled);
      setUid(uid);
      setIsProjectManager(isprojectmanager);
      setIsTeamManager(isteammanager);
      setWorkspaceId(workspaceId);
      setBreakStatus(breakstatus);

      const tokenExpirationDate = moment().add(1, "day");
      setTokenExpirationDate(tokenExpirationDate);

      localStorage.setItem("token", token);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId,
          token,
          name,
          image,
          email,
          expiration: tokenExpirationDate.toISOString(),
          isadmin,
          uid,
          isprojectmanager,
          isteammanager,
          isAppInstalled,
          workspaceId,
          breakstatus,
        })
      );
    },
    []
  );
  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setName(null);
    setEmail(null);
    setImage(null);
    setTokenExpirationDate(null);
    localStorage.removeItem("userData");
    setIsAdmin(false);
    setIsProjectManager(false);
    setIsTeamManager(false);
    setUid(null);
    setIsAppInstalled(false);
    setWorkspaceId(null);
    setBreakStatus(null);
    localStorage.clear();
  }, []);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));

    if (
      userData &&
      userData.token &&
      new Date(userData.expiration) > new Date()
    ) {
      login(
        userData.userId,
        userData.token,
        userData.name,
        userData.email,
        userData.image,
        userData.isadmin,
        userData.isprojectmanager,
        userData.uid,
        userData.isAppInstalled,
        userData.workspaceId,
        userData.breakstatus,
        userData.isteammanager
      );
    }
  }, [login]);

  useEffect(() => {
    let logoutTimer;
    if (token && tokenExpirationDate) {
      const remainingTime =
        new Date(tokenExpirationDate).getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else clearTimeout(logoutTimer);
  }, [token, logout, tokenExpirationDate]);

  return {
    token,
    userId,
    name,
    image,
    email,
    login,
    logout,
    isadmin,
    uid,
    isprojectmanager,
    isAppInstalled,
    workspaceId,
    breakstatus,
    isteammanager,
  };
}
