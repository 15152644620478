import { Col, Row, Container } from "@themesberg/react-bootstrap";

const Footer = (props) => {
  return (
    <footer
      className="bg-light footer py-4"
      style={{
        width: "100%",
        bottom: "0px",
        color: "#888",
        ...props.style,
      }}
    >
      <Container>
        <Row>
          <Col className="mb-md-2">
            <div
              className="d-flex text-center justify-content-center align-items-center"
              role="contentinfo"
            >
              <p className="font-weight-normal font-small mb-0">
                Copyright © Axolotl 2022. All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
