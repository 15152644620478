import { Image } from "@themesberg/react-bootstrap";
import React from "react";

import AppLogoWithSlack from "../../../assets/img/logo/app_logo_slack.png";
import SlackButton from "../../../components/SlackButton";
import { CLIENT_ID } from "../../../config";

import "./style.css";

function Header() {
  return (
    <header>
      <div className="header-image">
        <Image src={AppLogoWithSlack} alt="Image" />
      </div>
      <div className="header-content">
        <h1>Time tracker Axolotl</h1>
        <p>
          Axolotl is for time tracking through Slack. Axolotl is connected to
          the Slack status so all team members in the company know what the
          person is working on.
        </p>
        <p className="mb-4">
          I have axolotl account. <br />
          <SlackButton
            href={`https://slack.com/openid/connect/authorize?client_id=${CLIENT_ID}&response_type=code&scope=openid,email,profile&redirect_uri=https://${window.location.hostname}/slack_oauth_redirect_login`}
            text="Log in with Slack"
          ></SlackButton>
        </p>

        <p className="mb-4">
          I don't have axolotl account. <br />
          <SlackButton
            href={`https://slack.com/oauth/v2/authorize?client_id=${CLIENT_ID}&scope=chat:write.public,chat:write,users:read,commands,channels:read,groups:read,im:history,team:read&user_scope=users.profile:write&redirect_uri=https://${window.location.hostname}/slack_oauth_redirect_install`}
            text="Sign up with Slack"
          ></SlackButton>
        </p>
      </div>
    </header>
  );
}

export default Header;
