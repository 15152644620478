import { Spin } from "antd";
import React from "react";
import { getHourMinuteStringsFromSeconds } from "../helpers/utils";
import EmptyComponent from "./EmptyComponent";
import Doughnut from "./charts/Doughnut";

const ActivityDoughnutChart = ({
  projectsData,
  isDoughnutLoading,
  title,
  percentages,
  labels,
  colors,
}) => {
  return (
    <div
      className=""
      style={{
        display: "flex",
        gap: "30px",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow:
          "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
        alignItems: "center",
        padding: "5px",
        width: "750px",
        justifyContent: "center",
      }}
    >
      <>
        {isDoughnutLoading || percentages?.length > 0 ? (
          <div
            className=""
            style={{ marginLeft: isDoughnutLoading ? "0px" : "20px" }}
          >
            {!isDoughnutLoading ? (
              <>
                <Doughnut
                  title={title}
                  percentages={percentages}
                  labels={labels}
                  colors={colors}
                  projects={projectsData}
                  height={100}
                  width={100}
                />

                <div
                  className=""
                  style={{
                    width: "350px",
                    padding: "10px",
                  }}
                >
                  <table
                    className=""
                    style={{
                      width: "100%",
                    }}
                  >
                    {projectsData.map((project) => {
                      return (
                        <tr
                          className=""
                          style={{
                            borderBottom: "1px solid #CBD2DC",
                            margin: "5px 0px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              alignItems: "stretch",
                              margin: "5px 0px",
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "flex-start",
                              }}
                            >
                              <span
                                style={{
                                  background: project.projectcolor,
                                  width: "40px",
                                  height: "15px",
                                }}
                              ></span>

                              <span
                                title={project?.projectname}
                                style={{
                                  color: "#6D737A",
                                  fontSize: "12px",
                                }}
                              >
                                {project?.projectname}
                              </span>
                            </span>

                            <span
                              style={{
                                color: "#17273A",
                                fontSize: "12px",
                              }}
                            >
                              {getHourMinuteStringsFromSeconds(
                                project?.trackedinseconds
                              )}
                            </span>
                            <span
                              style={{
                                color: "#17273A",
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              {project?.y
                                ? project?.y?.toLocaleString("fullwide", {
                                    maximumFractionDigits: 2,
                                  })
                                : 0}
                              %
                            </span>
                          </div>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </>
            ) : (
              <div style={{ alignContent: "center" }}>
                <Spin />
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              width: "300px",
              margin: "0 auto",
            }}
          >
            <EmptyComponent description="No data available" />
          </div>
        )}
      </>
    </div>
  );
};

export default ActivityDoughnutChart;
