import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";

import "bootstrap-daterangepicker/daterangepicker.css";
import "bootstrap/dist/css/bootstrap.css";

const RangePicker = ({ startDate, endDate, onChange }) => {
  return (
    <DateRangePicker
      onApply={onChange}
      initialSettings={{
        startDate: startDate,
        endDate: endDate,
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days"),
          ],
          "This Week": [moment().startOf("week"), moment().endOf("week")],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
          "All time": [moment("2020-01-01", "YYYY-MM-DD"), moment()],
        },
        showCustomRangeLabel: true,
        alwaysShowCalendars: true,
        locale: {
          format: "DD.MM.YYYY",
        },
      }}
    >
      <input
        type="text"
        style={{
          textAlign: "center",
          padding: "5px",
          minWidth: "200px",
          borderRadius: "10px",
          border: "2px solid #e6e6e6",
        }}
      />
    </DateRangePicker>
  );
};

export default RangePicker;
