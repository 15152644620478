export const PagesRoutes = {
  AxolotlOwnerSignIn: { path: "/axolotl-login" },
  HomePage: { path: "/" },
  Dashboard: { path: "/dashboard" },
  LiveDashboard: { path: "/live/dashboard" },
  Settings: { path: "/settings" },
  SlackAuthRedirectLogin: { path: "/slack_oauth_redirect_login" },
  SlackAuthRedirectInstall: { path: "/slack_oauth_redirect_install" },
  NotFound: { path: "/404" },
  Projects: { path: "/projects" },
  Users: { path: "/users" },
  UserDetails: { path: "/users/:id" },
  UserActivity: { path: "/activity" },
  Teams: { path: "/teams" },
  TeamDetails: { path: "/teams/:id" },
  Notifications: { path: "/user-notifications" },
  TimeEditingRequests: { path: "/editing-requests" },
  SlackUpdateStatus: { path: "/update-slack-status" },
  HrManagement: { path: "/hr-settings" },
  Terms: { path: "/terms" },
  Policy: { path: "/policy" },
  AxolotlOwnerDashboard: { path: "/axolotl-dashboard" },
};
