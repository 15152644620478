const querystring = require("querystring");
const { postAPI, downloadFile } = require("./utils");

const axolotlOwnerLogin = async (email, password) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/login/owner`,
      { email, password },
      {},
      "POST"
    );

    return { user: res?.user, token: res?.token, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const axolotlOwnerSignup = async (name, email, password) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/signup/owner`,
      { name, email, password },
      {},
      "POST"
    );

    return { user: res?.user, token: res?.token, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getProjects = async (
  { keyword, page, pageSize, order, shouldPopulate },
  token
) => {
  try {
    const paramsString = querystring.stringify({
      keyword,
      page,
      pageSize,
      order,
      shouldPopulate,
    });
    const res = await postAPI(
      `https://${window.location.hostname}/projects/all?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { projects: res?.projects, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getUsers = async ({ page, pageSize, keyword }, token) => {
  try {
    const paramsString = querystring.stringify({ page, pageSize, keyword });
    const res = await postAPI(
      `https://${window.location.hostname}/users/other?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return {
      users: res?.users,
      err: res?.err,
      canAllEditTime: res?.canAllEditTime,
      doesAllNeedApproval: res?.doesAllNeedApproval,
    };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const createProject = async ({
  name,
  manager,
  members = [],
  teams = [],
  token,
}) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/projects`,
      { name, manager, members, teams },
      { authorization: `Bearer ${token}` },
      "POST"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const editProject = async (id, values = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/projects/${id}`,
      { ...values },
      { authorization: `Bearer ${token}` },
      "PUT"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const deleteProject = async (id, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/projects/${id}`,
      {},
      { authorization: `Bearer ${token}` },
      "DELETE"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const deleteAllProjects = async (ids, token) => {
  try {
    const promises = ids.map(async (id) => {
      const res = await postAPI(
        `https://${window.location.hostname}/projects/${id}`,
        {},
        { authorization: `Bearer ${token}` },
        "DELETE"
      );
      return res?.err;
    });

    const results = await Promise.all(promises);
    return { err: results.join("\n") };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const editUserProfile = async (values = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/edit-profile`,
      values,
      { authorization: `Bearer ${token}` },
      "PUT"
    );

    return { err: res?.err, user: res?.user };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const loginUsingSlackCode = async (code) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/slack_oauth_redirect_login?code=${code}`,
      {},
      {},
      "POST"
    );
    return { user: res?.user, token: res?.token, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const installSlackApp = async (code) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/slack_oauth_redirect_install?code=${code}`,
      {},
      {},
      "POST"
    );
    return { user: res?.user, token: res?.token, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const createAdminUser = async ({ name, email, token }) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/create/admin`,
      { name, email },
      { authorization: `Bearer ${token}` },
      "POST"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const deleteAdminUser = async (id, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/${id}`,
      {},
      { authorization: `Bearer ${token}` },
      "DELETE"
    );
    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const deleteAllUsers = async (ids, token) => {
  try {
    const promises = ids.map(async (id) => {
      const res = await postAPI(
        `https://${window.location.hostname}/users/${id}`,
        {},
        { authorization: `Bearer ${token}` },
        "DELETE"
      );
      return res?.err;
    });

    const results = await Promise.all(promises);
    return { err: results.join("\n") };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getThisWeekHistory = async (params = {}, token) => {
  try {
    params.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const paramsString = querystring.stringify(params);
    const res = await postAPI(
      `https://${window.location.hostname}/tracks/this-week?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { tasks: res?.tasks, err: res?.err, total: res?.total };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getUserDetails = async (id, token) => {
  try {
    const paramsString = querystring.stringify({ id });
    const res = await postAPI(
      `https://${window.location.hostname}/users/details?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return {
      user: res?.user,
      err: res?.err,
      tasksCount: res?.tasksCount,
      projectsCount: res?.projectsCount,
    };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getUserActivity = async (params = {}, token) => {
  try {
    params.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const paramsString = querystring.stringify(params);
    const res = await postAPI(
      `https://${window.location.hostname}/users/activity?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { tasks: res?.tasks, total: res?.total, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getUserNotifications = async (params = {}, token) => {
  try {
    const paramsString = querystring.stringify(params);
    const res = await postAPI(
      `https://${window.location.hostname}/notifications?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return {
      notifications: res?.notifications,
      err: res?.err,
      count: res?.count,
    };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const markNotificationAsRead = async (id, token) => {
  try {
    const paramsString = querystring.stringify({ id });
    const res = await postAPI(
      `https://${window.location.hostname}/notifications/read?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "POST"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getTasksByProject = async ({ project, keyword }, token) => {
  try {
    const paramsString = querystring.stringify({ keyword });
    const res = await postAPI(
      `https://${window.location.hostname}/tasks/project/${project}?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { tasks: res?.tasks, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const createNewTask = async (params, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/tasks`,
      { ...params },
      { authorization: `Bearer ${token}` },
      "POST"
    );

    return { task: res?.task, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const startTracking = async (params, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/tracks/start`,
      { ...params },
      { authorization: `Bearer ${token}` },
      "POST"
    );

    return { message: res?.message, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getUserLatestTask = async ({ uid, workspace_id }, token) => {
  try {
    const paramsString = querystring.stringify({ uid, workspace_id });

    const res = await postAPI(
      `https://${window.location.hostname}/tracks/latest?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { task: res?.task, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const stopTracking = async (token, values = {}) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/tracks/stop`,
      { ...values },
      { authorization: `Bearer ${token}` },
      "POST"
    );

    return { message: res?.message, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const updateConfigs = async (params = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/configs`,
      { ...params },
      { authorization: `Bearer ${token}` },
      "PUT"
    );

    return { message: res?.message, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const updateKeywordConfigs = async (params = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/configs/trigger-keywords`,
      { ...params },
      { authorization: `Bearer ${token}` },
      "PUT"
    );
    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getConfigs = async (token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/configs`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );
    return { configs: res?.configs, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getKeywordConfigs = async (token, type) => {
  try {
    const params = { type };
    const res = await postAPI(
      `https://${
        window.location.hostname
      }/configs/trigger-keywords?${querystring.stringify(params)}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );
    return { triggers: res?.triggers, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const deleteTask = async (id, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/tasks/${id}`,
      {},
      { authorization: `Bearer ${token}` },
      "DELETE"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getProjectManagedByUser = async (token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/projects/managing-project`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { project: res?.project, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getUserMostRecentTaskReport = async (params = {}, token) => {
  try {
    params.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const paramsString = querystring.stringify(params);
    const res = await postAPI(
      `https://${window.location.hostname}/reports/user/most-tracked-tasks?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { mostTrackedTasks: res?.mostTrackedTasks, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getUserMostTrackedProjectsReport = async (params = {}, token) => {
  try {
    params.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const paramsString = querystring.stringify(params);
    const res = await postAPI(
      `https://${window.location.hostname}/reports/user/most-tracked-projects?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { mostTrackedProjects: res?.mostTrackedProjects, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getUserActivityReports = async (params = {}, token) => {
  try {
    params.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const paramsString = querystring.stringify(params);
    const res = await postAPI(
      `https://${window.location.hostname}/reports/user/activity-data?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { tasks: res?.tasks, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const changeFavoriteProject = async (project, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/set-favorite-project`,
      { project },
      { authorization: `Bearer ${token}` },
      "PUT"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const clearFavoriteProject = async (project, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/clear-favorite-project`,
      { project },
      { authorization: `Bearer ${token}` },
      "PUT"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getUserFavoriteProject = async (token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/favorite-project`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { project: res?.project, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const createTeam = async (params = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/teams`,
      { ...params },
      { authorization: `Bearer ${token}` },
      "POST"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getAllUsers = async (token, keyword, params = {}) => {
  try {
    const paramsString = querystring.stringify({ keyword, ...params });
    const res = await postAPI(
      `https://${window.location.hostname}/users/all?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { users: res?.users, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getTeams = async (params = {}, token) => {
  try {
    const paramsString = querystring.stringify(params);
    const res = await postAPI(
      `https://${window.location.hostname}/teams/all?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { teams: res?.teams, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const editTeam = async (id, values = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/teams/${id}`,
      { ...values },
      { authorization: `Bearer ${token}` },
      "PUT"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const deleteTeam = async (id, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/teams/${id}`,
      {},
      { authorization: `Bearer ${token}` },
      "DELETE"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const deleteAllTeams = async (ids, token) => {
  try {
    const promises = ids.map(async (id) => {
      const res = await postAPI(
        `https://${window.location.hostname}/teams/${id}`,
        {},
        { authorization: `Bearer ${token}` },
        "DELETE"
      );
      return res?.err;
    });

    const results = await Promise.all(promises);
    return { err: results.join("\n") };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const exportAllTaskHistories = async (token, params = {}, type) => {
  try {
    params.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const paramsString = querystring.stringify(params);
    const res = await postAPI(
      `https://${window.location.hostname}/tracks/all?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    if (res && res.err) return { err: res.err };
    downloadFile(res, "reports", type);
    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getTeamTaskHistory = async (params = {}, token) => {
  try {
    params.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const paramsString = querystring.stringify(params);
    const res = await postAPI(
      `https://${window.location.hostname}/teams/tracking?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { tasks: res?.tasks, err: res?.err, total: res?.total };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const updateUserConfigs = async (values = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/update-configs`,
      values,
      { authorization: `Bearer ${token}` },
      "PUT"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const toggleUserEditTaskTimeRights = async (values = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/toggle-user-edit-task-rights`,
      values,
      { authorization: `Bearer ${token}` },
      "PUT"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const toggleUserNeedApprovals = async (values = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/toggle-need-time-editing-approval`,
      values,
      { authorization: `Bearer ${token}` },
      "PUT"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const setUserBreakStatus = async (token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/set-break-status`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { projects: res?.projects, err: res?.err, count: res?.count };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getTotalTrackedTime = async (params = {}, token) => {
  try {
    params.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const paramsString = querystring.stringify(params);
    const res = await postAPI(
      `https://${window.location.hostname}/tracks/total-tracked?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { tracking: res?.tracking, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const updateTrackingTime = async (params = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/tracks/update-time`,
      params,
      { authorization: `Bearer ${token}` },
      "PUT"
    );

    return { err: res?.err, message: res?.message };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const editUserTimeEditingAccess = async (values = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/edit-time-editing-access`,
      values,
      { authorization: `Bearer ${token}` },
      "PUT"
    );

    return {
      err: res?.err,
      message: res?.message,
      canAllEditTime: res?.canAllEditTime,
      doesAllNeedApproval: res?.doesAllNeedApproval,
    };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getTimeEditRequests = async (params = {}, token) => {
  try {
    const paramsString = querystring.stringify(params);
    const res = await postAPI(
      `https://${window.location.hostname}/tracks/time-edit-requests?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { requests: res?.requests, err: res?.err, total: res?.total };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getTimeEditRequestsCount = async (token, params = {}) => {
  try {
    const paramsString = querystring.stringify(params);
    const res = await postAPI(
      `https://${window.location.hostname}/tracks/time-edit-requests-count?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { count: res?.count, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const approveExtraTime = async (params = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/tracks/approve-extra-time`,
      params,
      { authorization: `Bearer ${token}` },
      "POST"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const rejectExtraTime = async (params = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/tracks/reject-extra-time`,
      params,
      { authorization: `Bearer ${token}` },
      "POST"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const deleteExtraTrackedRequest = async (id, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/tracks/delete-extra-time-request/${id}`,
      {},
      { authorization: `Bearer ${token}` },
      "DELETE"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const deleteTrackedTime = async (id, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/tracks/delete-tracked-time/${id}`,
      {},
      { authorization: `Bearer ${token}` },
      "DELETE"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const deleteMultipleTrackingRequests = async (ids, token) => {
  try {
    const promises = ids.map((id) =>
      postAPI(
        `https://${window.location.hostname}/tracks/delete-extra-time-request/${id}`,
        {},
        { authorization: `Bearer ${token}` },
        "DELETE"
      )
    );
    const responses = await Promise.all(promises);
    const errors = responses.map((res) => res?.err).filter((err) => err);
    if (errors.length) return { err: errors.join("\n") };

    return {};
  } catch (err) {
    console.log(err);
    return [err.message || JSON.stringify(err)];
  }
};

const getUsersLatestActivity = async (token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/reports/user/latest-activity-data`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { users: res?.users, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const markAllNotificationsAsReadOfUser = async (token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/notifications/read-all`,
      {},
      { authorization: `Bearer ${token}` },
      "POST"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const updateUserAdminRights = async (values = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/update-role`,
      { ...values },
      { authorization: `Bearer ${token}` },
      "PUT"
    );

    return { err: res?.err, message: res?.message };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const editUserAdminRights = async (values = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/update-admin-rights`,
      { ...values },
      { authorization: `Bearer ${token}` },
      "PUT"
    );

    return { err: res?.err, message: res?.message };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getUserChannels = async (params = {}, token) => {
  try {
    const paramsString = querystring.stringify(params);
    const res = await postAPI(
      `https://${window.location.hostname}/channels?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { channels: res?.channels, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const createNewMessageSchedule = async (params, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/message-schedules`,
      { ...params },
      { authorization: `Bearer ${token}` },
      "POST"
    );

    return { schedule: res?.schedule, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getScheduledMessages = async (token, params = {}) => {
  try {
    params.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const paramsString = querystring.stringify(params);

    const res = await postAPI(
      `https://${window.location.hostname}/message-schedules?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { schedules: res?.schedules, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const deleteScheduledMessage = async (id, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/message-schedules/${id}`,
      {},
      { authorization: `Bearer ${token}` },
      "DELETE"
    );

    return { err: res?.err, message: res?.message };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const deleteAllScheduleMessage = async (ids, token) => {
  try {
    const promises = ids.map(async (id) => {
      const res = await postAPI(
        `https://${window.location.hostname}/message-schedules/${id}`,
        {},
        { authorization: `Bearer ${token}` },
        "DELETE"
      );
      return res?.err;
    });

    const results = await Promise.all(promises);
    return { err: results.join("\n") };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const updateScheduledMessage = async (id, values = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/message-schedules/${id}`,
      { ...values },
      { authorization: `Bearer ${token}` },
      "PUT"
    );

    return { err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getTimeEditRequestHistories = async (token, params = {}) => {
  try {
    const paramsString = querystring.stringify(params);
    const res = await postAPI(
      `https://${window.location.hostname}/tracks/time-edit-requests-histories?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { histories: res?.histories, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const toggleScheduledMessageDisability = async (token, id) => {
  try {
    const paramsString = querystring.stringify({ id });

    const res = await postAPI(
      `https://${window.location.hostname}/message-schedules/toggle-disability?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { message: res?.message, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const requestToDeleteMyData = async (token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/delete-my-data`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { message: res?.message, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const uninstallSlackApp = async (token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/uninstall-app`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { message: res?.message, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const updateUserDOB = async (values = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/set-birth-date`,
      values,
      { authorization: `Bearer ${token}` },
      "PUT"
    );

    return { err: res?.err, message: res?.message };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};
const getBirthdaySchedule = async (token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/message-schedules/birthday-schedules`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { schedule: res?.schedule, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const updateBirthdaySchedule = async (params = {}, token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/message-schedules/birthday-schedule`,
      { ...params },
      { authorization: `Bearer ${token}` },
      "PUT"
    );

    return { message: res?.message, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getMeDetails = async (token) => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/me`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { user: res?.user, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const getAxolotlCustomers = async ({ token, page, pageSize, keyword }) => {
  try {
    const paramsString = querystring.stringify({
      keyword,
      page,
      pageSize,
    });

    const res = await postAPI(
      `https://${window.location.hostname}/users/customers?${paramsString}`,
      {},
      { authorization: `Bearer ${token}` },
      "GET"
    );

    return { customers: res?.customers, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

const checkOwnerExist = async () => {
  try {
    const res = await postAPI(
      `https://${window.location.hostname}/users/check-axolotl-owner-exist`,
      {},
      {},
      "GET"
    );

    return { isExist: res?.isExist, err: res?.err };
  } catch (err) {
    console.log(err);
    return { err: err.message || JSON.stringify(err) };
  }
};

export {
  approveExtraTime,
  axolotlOwnerLogin,
  axolotlOwnerSignup,
  changeFavoriteProject,
  checkOwnerExist,
  clearFavoriteProject,
  createAdminUser,
  createNewMessageSchedule,
  createNewTask,
  createProject,
  createTeam,
  deleteAdminUser,
  deleteAllProjects,
  deleteAllScheduleMessage,
  deleteAllTeams,
  deleteAllUsers,
  deleteExtraTrackedRequest,
  deleteMultipleTrackingRequests,
  deleteProject,
  deleteScheduledMessage,
  deleteTask,
  deleteTeam,
  deleteTrackedTime,
  editProject,
  editTeam,
  editUserAdminRights,
  editUserProfile,
  editUserTimeEditingAccess,
  exportAllTaskHistories,
  getAllUsers,
  getAxolotlCustomers,
  getBirthdaySchedule,
  getConfigs,
  getKeywordConfigs,
  getMeDetails,
  getProjectManagedByUser,
  getProjects,
  getScheduledMessages,
  getTasksByProject,
  getTeamTaskHistory,
  getTeams,
  getThisWeekHistory,
  getTimeEditRequestHistories,
  getTimeEditRequests,
  getTimeEditRequestsCount,
  getTotalTrackedTime,
  getUserActivity,
  getUserActivityReports,
  getUserChannels,
  getUserDetails,
  getUserFavoriteProject,
  getUserLatestTask,
  getUserMostRecentTaskReport,
  getUserMostTrackedProjectsReport,
  getUserNotifications,
  getUsers,
  getUsersLatestActivity,
  installSlackApp,
  loginUsingSlackCode,
  markAllNotificationsAsReadOfUser,
  markNotificationAsRead,
  rejectExtraTime,
  requestToDeleteMyData,
  setUserBreakStatus,
  startTracking,
  stopTracking,
  toggleScheduledMessageDisability,
  toggleUserEditTaskTimeRights,
  toggleUserNeedApprovals,
  uninstallSlackApp,
  updateBirthdaySchedule,
  updateConfigs,
  updateKeywordConfigs,
  updateScheduledMessage,
  updateTrackingTime,
  updateUserAdminRights,
  updateUserConfigs,
  updateUserDOB,
};
