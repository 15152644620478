import { Modal } from "antd";
import moment from "moment";

import { getTimeFromSeconds } from "./utils";

const { info } = Modal;

export const showTaskDetailsModal = (task) => {
  const { hours, minutes, seconds } = getTimeFromSeconds(
    parseInt(task?.totalseconds)
  );

  const stopTimeDiff = moment(task?.notification_updated_stopped_at).diff(
    task?.notification_original_stopped_at,
    "seconds"
  );
  const startTimeDiff = moment(task?.notification_original_started_at).diff(
    task?.notification_updated_started_at,
    "seconds"
  );

  const totalDiff =
    stopTimeDiff && startTimeDiff
      ? stopTimeDiff + startTimeDiff
      : startTimeDiff
      ? startTimeDiff
      : stopTimeDiff;
  const { hours: h, minutes: m } = getTimeFromSeconds(
    totalDiff > 0 ? totalDiff : Math.abs(totalDiff)
  );
  info({
    content: (
      <table>
        <tr>
          <td>
            <b>User:</b>
          </td>
          <td>{task?.affectedusername}</td>
        </tr>
        <tr>
          <td>
            <b>Project: </b>
          </td>
          <td>{task?.projectname}</td>
        </tr>
        <tr>
          <td>
            <b>Task:</b>{" "}
          </td>
          <td>{task?.taskname}</td>
        </tr>
        {task?.type === "overtime_task" && (
          <>
            <tr>
              <td>
                <b>Started At:</b>
              </td>
              <td>{moment(task?.started_at).format("LLL")}</td>
            </tr>
            <tr>
              <td>
                <b>Stopped At:</b>
              </td>
              <td>
                {task?.stopped_at
                  ? moment(task?.stopped_at).format("LLL")
                  : "Still working"}
              </td>
            </tr>
          </>
        )}
        {task?.type === "time_updated" && (
          <>
            {task?.notification_updated_started_at && (
              <>
                <tr>
                  <td>
                    <b>Original Started At:</b>
                  </td>
                  <td>
                    {moment(task?.notification_original_started_at).format(
                      "LLL"
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Updated Started At:</b>
                  </td>
                  <td>
                    {moment(task?.notification_updated_started_at).format(
                      "LLL"
                    )}
                  </td>
                </tr>
              </>
            )}
            {task?.notification_updated_stopped_at && (
              <>
                <tr>
                  <td>
                    <b>Original Stopped At:</b>
                  </td>
                  <td>
                    {moment(task?.notification_original_stopped_at).format(
                      "LLL"
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Updated Stopped At:</b>
                  </td>
                  <td>
                    {moment(task?.notification_updated_stopped_at).format(
                      "LLL"
                    )}
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td>
                <b>Total time update:</b>
              </td>
              <td>
                <span>
                  {totalDiff === 0 ? "" : totalDiff > 0 ? "+" : "-"}
                  {h}:{m}
                </span>
              </td>
            </tr>
          </>
        )}
        {task?.stopped_at && task?.type === "overtime_task" && (
          <tr>
            <td>
              <b>Duration:</b>
            </td>
            <td>
              {hours}:{minutes}:{seconds}
            </td>
          </tr>
        )}
      </table>
    ),
    icon: null,
  });
};
