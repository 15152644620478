import React from "react";
import { Image } from "@themesberg/react-bootstrap";

import BarGraphExample from "../../../assets/img/logo/bar_graph.png";

import "./style.css";

function LargeImageSection() {
  return (
    <section className="image-section">
      <div className="image-content">
        <Image src={BarGraphExample} alt="Image" />
      </div>
    </section>
  );
}

export default LargeImageSection;
