import {
  Modal,
  Form,
  Select,
  Input,
  TimePicker,
  Spin,
  Switch,
  DatePicker,
  Row,
  Col,
} from "antd";
import dayjs from "dayjs";
import { Button } from "@themesberg/react-bootstrap";

import { days } from "../../data/days";
import { intervals } from "../../data/schedule_intervals";
import BootstrapLoader from "../../components/BootstrapLoader";
import { dates } from "../../data/dates";
import { months } from "../../data/months";

const { Option } = Select;
const format = "HH:mm";
const onlyDateFormat = "YYYY-MM-DD";

const getIntervalUnits = (interval) => {
  switch (interval) {
    case "weekly":
      return ["day"];
    case "monthly":
      return ["date"];
    case "yearly":
      return ["month", "date"];
    default:
      return [];
  }
};

const CreateScheduleModal = (props) => {
  const {
    recurring,
    selectedInterval,
    type,
    showModal,
    handleClose,
    selectedSchedule,
    auth,
    form,
    createScheduleLoading,
    onCreateSchedule,
    getUserChannels,
    channelsLoading,
    channels,
    err,
    isBirthdaySchedule,
  } = props;
  const units = getIntervalUnits(selectedInterval);

  return (
    <Modal
      title={type === "create" ? "Schedule New Message" : "Update Schedule"}
      open={showModal}
      className="select-none"
      onOk={form.submit}
      centered
      onCancel={handleClose}
      footer={[
        <div>
          <Button
            key="back"
            onClick={handleClose}
            style={{
              border: "2px solid #d1d5db",
              backgroundColor: "transparent",
              color: "black",
            }}
          >
            Cancel
          </Button>{" "}
          <Button
            key="submit"
            type="primary"
            onClick={form.submit}
            disabled={createScheduleLoading}
          >
            {type === "create" ? "Create" : "Update"}{" "}
            {createScheduleLoading && <BootstrapLoader />}
          </Button>
        </div>,
      ]}
    >
      <Form form={form} onFinish={onCreateSchedule} layout="vertical">
      
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Message"
              name="message"
              rules={[{ required: true, message: "Message can not be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            {selectedSchedule &&
              ((selectedSchedule.scheduled_by &&
                selectedSchedule.scheduled_by == auth?.userId) ||
                !selectedSchedule.scheduled_by) && (
                <Form.Item
                  label="Channel"
                  name="channel"
                  rules={[{ required: true, message: "Please select channel" }]}
                >
                  <Select
                    allowClear
                    onSearch={getUserChannels}
                    filterOption={false}
                    //showSearch
                    loading={channelsLoading}
                  >
                    {(channels || []).map((channel) => {
                      return (
                        <Option key={channel.id} value={channel.id}>
                          {channel.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            {!isBirthdaySchedule && (
              <Form.Item
                label="Recurring?"
                name="isrepeating"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            <Form.Item label="Enabled?" name="enabled" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>

        {recurring && (
          <>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label="Start Date"
                  name="start_date"
                  rules={[
                    { required: true, message: "Please select start date" },
                  ]}
                >
                  <DatePicker format={onlyDateFormat} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="End Date"
                  name="end_date"
                  rules={[
                    { required: true, message: "Please select end date" },
                  ]}
                >
                  <DatePicker format={onlyDateFormat} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Interval"
                  name="interval"
                  rules={[
                    { required: true, message: "Please select interval" },
                  ]}
                >
                  <Select>
                    {Object.keys(intervals).map((interval) => (
                      <Option key={interval} value={interval}>
                        {intervals[interval].label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              {units?.map((unit) => {
                if (unit)
                  return unit === "day" ? (
                    <Col span={8}>
                      <Form.Item
                        label="Day"
                        name="day"
                        rules={[
                          { required: true, message: "Please select day" },
                        ]}
                      >
                        <Select>
                          {days.map((day) => (
                            <Option key={day.value} value={day.value}>
                              {day.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : unit === "month" ? (
                    <Col span={8}>
                      <Form.Item
                        label="Month"
                        name="schedule_month"
                        rules={[
                          { required: true, message: "Please select month" },
                        ]}
                      >
                        <Select>
                          {months.map((month) => (
                            <Option key={month.value} value={month.value}>
                              {month.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : (
                    unit === "date" && (
                      <Col span={8}>
                        <Form.Item
                          label="Date"
                          name="schedule_date"
                          rules={[
                            { required: true, message: "Please select date" },
                          ]}
                        >
                          <Select>
                            {dates.map((date) => (
                              <Option key={date} value={date}>
                                {date}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )
                  );
              })}
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label="Time"
                  name="time"
                  rules={[{ required: true, message: "Please select time" }]}
                >
                  <TimePicker format={format} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        {isBirthdaySchedule && (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Month"
                  name="schedule_month"
                  rules={[{ required: true, message: "Please select month" }]}
                >
                  <Select>
                    {months.map((month) => (
                      <Option key={month.value} value={month.value}>
                        {month.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Date"
                  name="schedule_date"
                  rules={[{ required: true, message: "Please select date" }]}
                >
                  <Select>
                    {dates.map((date) => (
                      <Option key={date} value={date}>
                        {date}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        <Row gutter={16}>
          <Col span={12}>
            {!recurring && !isBirthdaySchedule && (
              <Form.Item
                label="Date"
                name="exact_date"
                rules={[{ required: true, message: "Please select date" }]}
              >
                <DatePicker format="YYYY-MM-DD HH:mm" showTime={true} />
              </Form.Item>
            )}
          </Col>
          <Col span={12}></Col>
        </Row>

        <p style={{ color: "red" }}>{err}</p>

      </Form>
    </Modal>
  );
};

export default CreateScheduleModal;
