import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  StarFilled,
  StarOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Button } from "@themesberg/react-bootstrap";
import {
  Button as AntButton,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  Select,
  Tooltip,
} from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";

import BootstrapLoader from "../components/BootstrapLoader";
import { AuthContext } from "../context/auth-context";
import * as Data from "../helpers/server";
import { removeDuplicates } from "../helpers/utils";

const { Search } = Input;

const Projects = () => {
  const auth = useContext(AuthContext);
  const [form] = Form.useForm();
  const pageSize = 10;

  const [projects, setProjects] = useState([]);
  const [managerUsers, setManagerUsers] = useState([]);
  const [memberUsers, setMemberUsers] = useState([]);
  const [managerUsersLoading, setManagerUsersLoading] = useState(false);
  const [membersUserLoading, setMembersUserLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState(null);
  const [page, setPage] = useState(1);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [favoriteProject, setFavoriteProject] = useState(null);
  const [teams, setTeams] = useState([]);
  const [order, setOrder] = useState("desc");
  const [createProjectsLoading, setCreateProjectsLoading] = useState(false);
  const [deleteAllLoading, setDeleteAllLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const changeOrder = async () => {
    const newOrder = order === "desc" ? "asc" : "desc";
    setOrder((order) => (order === "desc" ? "asc" : "desc"));

    setPage(1);
    setProjects([]);
    await getProjects({ page: 1, pageSize, order: newOrder, keyword });
  };

  const getManagerUsers = async (keyword) => {
    setManagerUsersLoading(true);
    const { users } = await Data.getAllUsers(auth?.token, keyword);
    setManagerUsers(users);
    setManagerUsersLoading(false);
  };

  const getMemberUsers = async (keyword) => {
    setMembersUserLoading(true);
    const { users } = await Data.getAllUsers(auth?.token, keyword);
    setMemberUsers(users);
    setMembersUserLoading(false);
  };

  const getTeams = async () => {
    const { teams } = await Data.getTeams({ shouldSendAll: true }, auth?.token);
    setTeams(teams);
  };

  const getFavoriteProject = async () => {
    try {
      setLoading(true);
      const { project } = await Data.getUserFavoriteProject(auth?.token);
      setLoading(false);
      setFavoriteProject(project);
    } catch (err) {
      console.log(err);
    }
  };

  const getProjects = async ({ page, pageSize, keyword, order }) => {
    setLoading(true);
    const { projects } = await Data.getProjects(
      { page, pageSize, keyword, order, shouldPopulate: true },
      auth?.token
    );

    if (projects.length < pageSize) setShouldLoadMore(false);
    else setShouldLoadMore(true);

    setProjects((prev) =>
      removeDuplicates(prev.concat(projects), "project_id")
    );
    setLoading(false);
  };

  useEffect(() => {
    if (auth?.token) {
      getProjects({ page, pageSize, order, keyword });
      getFavoriteProject();
      getManagerUsers();
      getMemberUsers();
      getTeams();
    }
  }, [auth?.token]);

  const handleClose = () => {
    setShowModal(false);
    setType(null);
    setSelectedProject({});
    setErr(null);
    form.resetFields();
    getManagerUsers();
    getMemberUsers();
  };

  const onLoadMore = async () => {
    const newPage = page + 1;
    setPage(newPage);
    await getProjects({ page: newPage, pageSize, order, keyword });
  };

  const loadMore =
    shouldLoadMore && !loading ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore} hidden={!shouldLoadMore && loading}>
          Load next 10 projects
        </Button>
      </div>
    ) : null;

  const openProjectModal = async (type) => {
    if (type === "create") setSelectedProject({});

    setShowModal(true);
    setType(type);
  };

  const changeFavoriteProject = async (project) => {
    try {
      await Data.changeFavoriteProject(project, auth?.token);
      const favoriteProject = projects?.find((p) => p.project_id == project);

      setFavoriteProject({
        ...favoriteProject,
        id: favoriteProject?.project_id,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const clearFavoriteProject = async (project) => {
    try {
      await Data.clearFavoriteProject(project, auth?.token);

      setFavoriteProject(null);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteProject = async (e) => {
    try {
      const newPageSize =
        projects.length < pageSize ? pageSize : projects.length;
      setProjects([]);

      setCreateProjectsLoading(true);
      const { err } = await Data.deleteProject(e.target.value, auth?.token);
      setCreateProjectsLoading(false);
      if (err) return setErr(err);

      setErr(null);
      setType(null);
      setShowModal(false);
      getProjects({ page: 1, pageSize: newPageSize, order, keyword });
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  const selectProject = (e) => {
    const selectedProject = projects.filter(
      (p) => p.project_id === parseInt(e.target.value)
    )[0];

    const members = [];
    selectedProject?.members?.forEach((member) => {
      if (members.indexOf({ value: member?.id, label: member?.name }) < 0)
        members.push({ value: member?.id, label: member?.name });
    });

    form.setFieldsValue({
      name: selectedProject?.name,
      manager: selectedProject?.manager?.id
        ? {
            value: parseInt(selectedProject?.manager?.id),
            label: selectedProject?.manager?.name,
          }
        : null,
      members,
      teams: selectedProject?.teamsarr || [],
    });

    const flags = {};
    const allMembers = [];

    for (let i = 0; i < selectedProject?.allmembers?.length; i++) {
      if (!flags[selectedProject?.allmembers[i].id]) {
        flags[selectedProject?.allmembers[i].id] = true;
        allMembers.push(selectedProject?.allmembers[i]);
      }
    }

    selectedProject.allmembers = allMembers;
    setSelectedProject(selectedProject);
  };

  const createProject = async (values) => {
    const { name, teams = [] } = values;
    let { members = [], manager } = values;

    let res;

    setCreateProjectsLoading(true);
    const newPageSize = projects.length < pageSize ? pageSize : projects.length;

    if (type === "create") {
      res = await Data.createProject({
        name,
        manager,
        members,
        teams,
        token: auth?.token,
      });
    } else {
      manager = manager?.value ? manager.value : manager;
      members = members
        ? members[0]?.value
          ? members.map((m) => {
              return m.value;
            })
          : members
        : [];
      const oldMembersList = [];
      selectedProject?.members?.forEach((member) =>
        oldMembersList.push(member?.value || member)
      );

      const removedMembers =
        oldMembersList.filter((m) => !members?.includes(m)) || [];
      const addedMembers =
        members?.filter((m) => !oldMembersList?.includes(m)) || [];

      const oldTeamsList = selectedProject?.teamsarr || [];
      const removedTeams = oldTeamsList.filter((t) => !teams.includes(t)) || [];
      const addedTeams = teams?.filter((t) => !oldTeamsList.includes(t)) || [];

      res = await Data.editProject(
        selectedProject?.project_id,
        {
          name,
          manager,
          members,
          teams,
          removedMembers,
          addedMembers,
          removedTeams,
          addedTeams,
        },
        auth?.token
      );
    }
    setCreateProjectsLoading(false);

    if (res?.err) return setErr(res?.err);
    setProjects([]);
    form.resetFields();
    setErr(null);
    setType(null);
    setShowModal(false);
    getProjects({ page: 1, pageSize: newPageSize, order, keyword });
  };

  const onSearch = (value) => {
    setProjects([]);
    setPage(1);
    if (value) getProjects({ page: 1, pageSize, keyword: value, order });
    else getProjects({ page: 1, pageSize, order });
  };

  const onKeywordChange = (e) => {
    setKeyword(e.target.value);

    if (!e.target.value) {
      setPage(1);
      setProjects([]);
      getProjects({ page: 1, pageSize, order });
    }
  };

  const handleItemClick = (item) => {
    const index = selectedIds.indexOf(item.project_id);
    if (index === -1) {
      setSelectedIds([...selectedIds, item.project_id]);
    } else {
      setSelectedIds([
        ...selectedIds.slice(0, index),
        ...selectedIds.slice(index + 1),
      ]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(projects.map((user) => user.project_id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleDeleteAll = async () => {
    setDeleteAllLoading(true);
    const { err } = await Data.deleteAllProjects(selectedIds, auth?.token);
    setDeleteAllLoading(false);
    if (err) {
      console.error(err);
    } else {
      console.log("Teams deleted successfully!");
    }

    setProjects((projects) =>
      projects.filter((project) => !selectedIds.includes(project.project_id))
    );
    setSelectedIds([]);
  };

  return (
    <>
      <main className="content container-new Projects">
        {showModal &&
          (type !== "delete" ? (
            <Modal
              title={type === "create" ? "Create New Project" : "Edit Project"}
              visible={showModal}
              className="select-none"
              onOk={form.submit}
              centered
              onCancel={handleClose}
              footer={[
                <div>
                  <Button
                    key="back"
                    onClick={handleClose}
                    style={{
                      border: "2px solid #d1d5db",
                      backgroundColor: "transparent",
                      color: "black",
                    }}
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    key="submit"
                    type="primary"
                    onClick={form.submit}
                    disabled={createProjectsLoading}
                  >
                    {type === "create" ? "Create" : "Update"}
                    {createProjectsLoading && <BootstrapLoader />}
                  </Button>
                </div>,
              ]}
            >
              <Form
                name="inviteUser"
                form={form}
                onFinish={createProject}
                layout="vertical"
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input Project name!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {auth?.isadmin && (
                      <Form.Item label="Project Manager" name="manager">
                        <Select
                          loading={managerUsersLoading}
                          allowClear
                          onSearch={getManagerUsers}
                          filterOption={false}
                          showSearch
                        >
                          {(managerUsers || []).map((user) => {
                            return (
                              <Select.Option key={user.id} value={user.id}>
                                {user.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    )}
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Members" name="members">
                      <Select
                        mode="multiple"
                        allowClear
                        onSearch={getMemberUsers}
                        filterOption={false}
                        showSearch
                        loading={membersUserLoading}
                      >
                        {(memberUsers || []).map((user) => {
                          return (
                            <Select.Option key={user.id} value={user.id}>
                              {user.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Teams" name="teams">
                      <Select mode="multiple" allowClear>
                        {(teams || []).map((team) => {
                          return (
                            <Select.Option
                              key={team?.teamid}
                              value={team?.teamid}
                            >
                              {team?.teamname}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item>
                  <p style={{ color: "red" }}>{err}</p>
                </Form.Item>
              </Form>
            </Modal>
          ) : (
            <Modal
              title="Delete Confirmation!"
              visible={showModal}
              className="select-none"
              onOk={deleteProject}
              centered
              onCancel={handleClose}
              footer={[
                <div>
                  <Button
                    key="back"
                    onClick={handleClose}
                    style={{
                      border: "2px solid #d1d5db",
                      backgroundColor: "transparent",
                      color: "black",
                    }}
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    value={selectedProject?.project_id}
                    key="submit"
                    type="primary"
                    onClick={deleteProject}
                    disabled={createProjectsLoading}
                    style={{
                      backgroundColor: "#f05251",
                      borderColor: "#f05251",
                    }}
                  >
                    Delete
                    {createProjectsLoading && <BootstrapLoader />}
                  </Button>
                </div>,
              ]}
            >
              {`Are you sure to delete project ${selectedProject?.name}?`}
              {err ? err : ""}
            </Modal>
          ))}

        <div style={{ marginBottom: "10px" }}>
          <Search
            placeholder="Search"
            onSearch={onSearch}
            onChange={onKeywordChange}
            style={{ width: 200 }}
          />
          <Button
            variant="primary"
            style={{ float: "right" }}
            onClick={() => openProjectModal("create")}
            hidden={!auth?.isadmin}
          >
            Create Project
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            gap: "5px",
          }}
        >
          <div>
            <p
              style={{
                color: "#17273A",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              My Projects
            </p>
          </div>
          <div>
            <button
              type="button"
              onClick={changeOrder}
              style={{
                border: "none",
                color: "#2664f2",
                background: "none",
              }}
            >
              Sort by date
              {order === "desc" && (
                <DownOutlined
                  style={{
                    verticalAlign: "middle",
                    fontSize: "15px",
                    padding: "0px 2px",
                  }}
                />
              )}
              {order === "asc" && (
                <UpOutlined
                  style={{
                    verticalAlign: "middle",
                    fontSize: "15px",
                    padding: "0px 2px",
                  }}
                />
              )}
            </button>
          </div>
        </div>

        <div className="projectList" style={{ padding: "10px" }}>
          {auth?.isadmin && (
            <div style={{ display: "flex", gap: "20px" }}>
              <div className="div">
                {projects.length > 0 && (
                  <Checkbox
                    checked={selectedIds.length === projects.length}
                    onChange={handleSelectAll}
                  />
                )}
              </div>
              <div className="div">
                {selectedIds?.length > 0 && projects?.length > 0 && (
                  <AntButton
                    hidden={!auth?.isadmin}
                    type="text"
                    onClick={handleDeleteAll}
                    style={{
                      background: "#f6cccc",
                      borderRadius: "13px",
                      color: "#b34c4c",
                    }}
                    loading={deleteAllLoading}
                    icon={
                      <DeleteOutlined
                        style={{
                          verticalAlign: "top",
                          color: "#b34c4c",
                        }}
                      />
                    }
                  />
                )}
              </div>
            </div>
          )}
          <List
            className="demo-loadmore-list"
            loading={loading}
            loadMore={loadMore}
            itemLayout="horizontal"
            dataSource={projects}
            renderItem={(item) => {
              return (
                <List.Item key={item?.project_id} onFocus={selectProject}>
                  {auth?.isadmin && (
                    <Checkbox
                      style={{ marginRight: "10px" }}
                      checked={selectedIds.includes(item.project_id)}
                      onChange={() => handleItemClick(item)}
                    />
                  )}
                  <table
                    style={{
                      width: "100%",
                      boxShadow: "rgba(0, 0, 0, 0.08) 2px 2px 20px",
                      backgroundColor: "transparent",
                      borderRadius: "15px",
                    }}
                  >
                    <tr>
                      <td
                        style={{
                          padding: "8px",
                          backgroundColor: "#f9fafb",
                          borderTopLeftRadius: "15px",
                          borderTopRightRadius: "15px",
                          borderBottom: "2px solid #e9ebef",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            fontSize: "16px",
                            color: "#9198a2",
                          }}
                        >
                          <div>
                            <b>
                              {moment(item.created_at).format("dddd, D MMMM")}
                            </b>
                          </div>

                          <div
                            style={{
                              marginLeft: "auto",
                              marginRight: "2px",
                              cursor: "pointer",
                            }}
                          >
                            <Tooltip
                              autoAdjustOverflow={true}
                              title={
                                item.totaltracked ? (
                                  <div
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  >
                                    {item.userwisetracking?.map((tracking) => {
                                      return (
                                        <>
                                          <div style={{ minWidth: "200px" }}>
                                            <span>
                                              {tracking.user || "User"}
                                            </span>
                                            <span style={{ float: "right" }}>
                                              {tracking.tracked}
                                            </span>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                ) : undefined
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <b>Total : </b>
                              <span>
                                <b>{item.totaltracked || "00:00:00"}</b>
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: "8px",
                          backgroundColor: "#FDFDFD",
                          borderBottomLeftRadius: "15px",
                          borderBottomRightRadius: "15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                              gap: "5px",
                              color: "#17273A",
                              fontSize: "14px",
                              width: "30%",
                            }}
                          >
                            {favoriteProject?.id === item?.project_id ? (
                              <StarFilled
                                style={{
                                  fontSize: "20px",
                                  verticalAlign: "middle",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  clearFavoriteProject(item?.project_id);
                                }}
                              />
                            ) : (
                              <StarOutlined
                                style={{
                                  fontSize: "20px",
                                  verticalAlign: "middle",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  changeFavoriteProject(item?.project_id);
                                }}
                              />
                            )}
                            {item.name}
                          </div>

                          <div
                            style={{
                              fontSize: "15px",
                              margin: "0 auto",
                              color: "#8e939e",
                            }}
                          >
                            {`Team Manager: ${
                              item?.manager?.name || "Not assigned"
                            }`}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <AntButton
                              type="text"
                              primary
                              value={item.project_id}
                              hidden={
                                !auth?.isadmin &&
                                auth?.userId !== item?.manager?.id
                              }
                              onClick={() => openProjectModal("edit")}
                              icon={
                                <EditOutlined
                                  style={{ verticalAlign: "top" }}
                                />
                              }
                              style={{
                                color: "#25805b",
                                background: "#def7ec",
                                borderRadius: "13px",
                              }}
                            />
                            <AntButton
                              hidden={!auth?.isadmin}
                              type="text"
                              value={item.project_id}
                              onClick={() => openProjectModal("delete")}
                              style={{
                                background: "#f6cccc",
                                borderRadius: "13px",
                                color: "#b34c4c",
                              }}
                              icon={
                                <DeleteOutlined
                                  style={{
                                    verticalAlign: "top",
                                    color: "#b34c4c",
                                  }}
                                />
                              }
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </List.Item>
              );
            }}
          />
        </div>
      </main>
    </>
  );
};

export default Projects;
