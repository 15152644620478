import { PlusSquareOutlined } from "@ant-design/icons";
import { Button } from "@themesberg/react-bootstrap";
import { Col, Form, Input, Modal, Row, Select } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";

import { AuthContext } from "../context/auth-context";
import * as Data from "../helpers/server";
import BootstrapLoader from "./BootstrapLoader";

const CreateTask = () => {
  const [form] = Form.useForm();
  const auth = useContext(AuthContext);

  const [showModal, setShowModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [createTaskLoading, setCreateTaskLoading] = useState(false);
  const [err, setErr] = useState("");

  const showCreateTaskModal = () => {
    setShowModal(true);
  };

  const closeCreateTaskModal = () => {
    if (createTaskLoading) return;
    setShowModal(false);
    form.resetFields();
    setDisabled(true);
  };

  const createNewTask = async (values) => {
    const { name, project } = values;

    setCreateTaskLoading(true);
    const { task, err } = await Data.createNewTask(
      { name, project },
      auth?.token
    );
    setCreateTaskLoading(false);
    if (err) return setErr(err);
    else setErr("");

    closeCreateTaskModal();
  };

  const updateProjects = async (keyword) => {
    try {
      const { projects, err } = await Data.getProjects(
        { keyword, page: 1, pageSize: 10, shouldPopulate: false },
        auth?.token
      );
      if (err) return setErr(err);

      setProjects(projects);
    } catch (err) {
      console.log(err);
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    const { name, project } = allValues;

    if (!name || !name?.trim() || !project) setDisabled(true);
    else setDisabled(false);
  };

  useEffect(() => {
    if (auth?.token) {
      updateProjects();
    }
  }, [auth?.token]);

  return (
    <div className="createTask">
      {showModal && (
        <Modal
          title="Create New Task"
          visible={showModal}
          className="select-none"
          onOk={createNewTask}
          centered
          onCancel={closeCreateTaskModal}
          footer={[
            <div>
              <Button
                key="back"
                onClick={closeCreateTaskModal}
                style={{
                  border: "2px solid #d1d5db",
                  backgroundColor: "transparent",
                  color: "black",
                }}
                disabled={createTaskLoading}
              >
                Cancel
              </Button>{" "}
              <Button
                disabled={disabled || createTaskLoading}
                value={selectedProject?.project_id}
                key="submit"
                type="primary"
                onClick={form.submit}
              >
                Create
                {createTaskLoading && <BootstrapLoader />}
              </Button>
            </div>,
          ]}
        >
          <Form
            form={form}
            onFinish={createNewTask}
            onValuesChange={onValuesChange}
            layout="vertical"
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Project"
                  name="project"
                  rules={[
                    { required: true, message: "Please select project!" },
                  ]}
                >
                  <Select
                    onSearch={updateProjects}
                    filterOption={false}
                    showSearch
                  >
                    {(projects || []).map((project) => {
                      return (
                        <Select.Option
                          key={project.project_id}
                          value={project.project_id}
                        >
                          {project.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Please input task name!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <p style={{ color: "red" }}>{err}</p>
          </Form>
        </Modal>
      )}

      <table
        style={{
          width: "100%",
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 4px 10px rgba(207, 214, 222, 0.25)",
          borderCollapse: "collapse",
          borderRadius: "10px",
          padding: "5px",
        }}
      >
        <thead style={{ backgroundColor: "#f9fafb" }}>
          <tr>
            <th
              style={{
                padding: "8px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                borderBottom: "2px solid #e9ebef",
                color: "#9198a2",
              }}
            >
              {moment().format("dddd, D MMMM")}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ padding: "15px 5px" }}>
              <button
                type="button"
                onClick={showCreateTaskModal}
                style={{
                  border: "none",
                  color: "#2664f2",
                  background: "none",
                }}
              >
                <PlusSquareOutlined
                  style={{
                    verticalAlign: "middle",
                    fontSize: "25px",
                    padding: "0px 5px",
                  }}
                />
                Create new task
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default CreateTask;
