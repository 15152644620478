import {
  faEnvelope,
  faUnlockAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "@themesberg/react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import BgImage from "../assets/img/illustrations/signin.svg";
import { AuthContext } from "../context/auth-context";
import { chromeExtensionId } from "../data/constants";
import * as Data from "../helpers/server";
import { PagesRoutes } from "../routes";

/* global chrome */

const SignIn = () => {
  const auth = useContext(AuthContext);

  const [isExist, setIsExist] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const checkOwnerExist = async () => {
    try {
      const { isExist } = await Data.checkOwnerExist(email, password);
      setIsExist(isExist);
    } catch (err) {
      console.log(err);
    }
  };

  const login = (user, token) => {
    if (window.navigator.userAgent.toLowerCase().includes("chrome")) {
      chrome.runtime?.sendMessage(chromeExtensionId, {
        userId: user?.id,
        token,
        ...user,
      });
    }

    auth.login(
      user?.id,
      token,
      user?.name,
      user?.email,
      user?.image,
      user?.isadmin,
      user?.isprojectmanager,
      user?.uid,
      user?.isAppInstalled,
      user?.workspace_id,
      user?.breakstatus,
      user?.isteammanager
    );

    localStorage.setItem("isAxolotlOwner", "true");
    navigate(PagesRoutes.AxolotlOwnerDashboard.path);
  };

  const signin = async () => {
    try {
      setIsLoading(true);
      const { user, token, err } = await Data.axolotlOwnerLogin(
        email,
        password
      );

      setIsLoading(false);
      if (err) {
        setError(err);
        return;
      } else login(user, token);
    } catch (err) {
      console.log(err);
    }
  };

  const signup = async () => {
    try {
      setIsLoading(true);
      const { user, token, err } = await Data.axolotlOwnerSignup(
        name,
        email,
        password
      );
      setIsLoading(false);

      if (err) {
        setError(err);
        return;
      } else login(user, token);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkOwnerExist();

    const token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : undefined;
    const isAxolotlOwner = localStorage.getItem("isAxolotlOwner") === "true";

    if (token) {
      if (isAxolotlOwner) navigate(PagesRoutes.AxolotlOwnerDashboard.path);
      else navigate(PagesRoutes.Dashboard.path);
    }
  }, []);

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">
                    {isExist ? "Sign in" : "Sign up"} to Axolotl
                  </h3>
                </div>
                <Form className="mt-4">
                  <Form.Group>
                    {!isExist && (
                      <Form.Group id="name" className="mb-4">
                        <Form.Label>Your Name</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUser} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.currentTarget.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    )}
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        placeholder="admin"
                        value={email}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.currentTarget.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Form.Group>
                  {error && <p style={{ color: "red" }}> {error}</p>}
                  <Button
                    variant="primary"
                    className="w-100"
                    onClick={isExist ? signin : signup}
                  >
                    {isExist ? "Sign in" : "Sign up"}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
export default SignIn;
