import { Modal, Tag, Table } from "antd";
import moment from "moment";

const TrackingRequestsHistoryModal = (props) => {
  const { histories, loading, showModal, onCancel } = props;

  const columns = [
    {
      title: "Original Start Time",
      dataIndex: "original_started_at",
      key: "original_started_at",
      render: (original_started_at) => {
        return moment(original_started_at).format("HH:mm A");
      },
    },
    {
      title: "Requested Start Time",
      dataIndex: "updated_started_at",
      key: "updated_started_at",
      render: (updated_started_at, obj) => {
        const originalTime = moment(obj.original_started_at).format("HH:mm A");
        const updatedTime =
          updated_started_at != null
            ? moment(updated_started_at).format("HH:mm A")
            : "N/A";
        return originalTime === updatedTime ? "N/A" : updatedTime;
      },
    },
    {
      title: "Original Stop Time",
      dataIndex: "taskhistory_original_stopped_at",
      key: "taskhistory_original_stopped_at",
      render: (taskhistory_original_stopped_at) => {
        return moment(taskhistory_original_stopped_at).format("HH:mm A");
      },
    },
    {
      title: "Requested Stop Time",
      dataIndex: "updated_stopped_at",
      key: "updated_stopped_at",
      render: (updated_stopped_at, obj) => {
        const originalTime = moment(obj.taskhistory_original_stopped_at).format(
          "HH:mm A"
        );
        const updatedTime =
          updated_stopped_at != null
            ? moment(updated_stopped_at).format("HH:mm A")
            : "N/A";

        return originalTime === updatedTime ? "N/A" : updatedTime;
      },
    },
    {
      title: "Original Date",
      dataIndex: "original_started_at",
      key: "original_started_at",
      render: (original_started_at, obj) => {
        return obj.taskhistory_original_stopped_at != null
          ? moment(obj.taskhistory_original_stopped_at).format("YYYY-MM-DD")
          : obj.th_original_started_at != null
          ? moment(obj.th_original_started_at).format("YYYY-MM-DD")
          : "N/A";
      },
    },
    {
      title: "Updated Date",
      dataIndex: "updated_started_at",
      key: "updated_started_at",
      render: (updated_started_at, obj) => {
        const originalDate =
          obj.taskhistory_original_stopped_at != null
            ? moment(obj.taskhistory_original_stopped_at).format("YYYY-MM-DD")
            : obj.th_original_started_at != null
            ? moment(obj.th_original_started_at).format("YYYY-MM-DD")
            : "N/A";

        const updatedDate =
          obj.updated_stopped_at !== null
            ? updated_started_at != null
              ? moment(updated_started_at).format("YYYY-MM-DD")
              : obj.updated_stopped_at != null &&
                obj.updated_stopped_at !== obj.taskhistory_original_stopped_at
              ? moment(obj.updated_stopped_at).format("YYYY-MM-DD")
              : "N/A"
            : "N/A";

        return updatedDate === originalDate ? "N/A" : updatedDate;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const color =
          status === "pending"
            ? "geekblue"
            : status === "rejected"
            ? "volcano"
            : status === "N/A"
            ? "grey"
            : "green";
        return (
          status && (
            <Tag
              key={status}
              color={color}
              style={{ borderRadius: "15px", padding: "0px 10px" }}
            >
              {status}
            </Tag>
          )
        );
      },
    },
  ];

  return (
    <Modal
      title="Updated Tracking Time History"
      open={showModal}
      className="select-none"
      centered
      onCancel={onCancel}
      footer={null}
      width={800}
      style={{ width: "800px" }}
    >
      {loading && <span>Loading...</span>}
      <Table dataSource={histories} columns={columns} />
    </Modal>
  );
};

export default TrackingRequestsHistoryModal;
