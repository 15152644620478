import { getTimeFromSeconds } from "./utils";

export const structureDataForDoughnutChart = (projects) => {
  try {
    const colors = [];
    const percentages = [];
    const labels = [];
    for (let i = 0; i < projects.length; i++) {
      const y =
        (100 * Math.floor(projects[i].trackedinseconds)) /
        Math.floor(projects[i].totaltrackedinseconds);

      projects[i].y = y;

      colors.push(projects[i].projectcolor);
      labels.push(projects[i].projectname);
      percentages.push(y);
    }

    let hours = "00";
    let minutes = "00";
    let seconds = "00";

    if (projects[0]) {
      const time = getTimeFromSeconds(projects[0].totaltrackedinseconds);
      hours = time.hours;
      minutes = time.minutes;
      seconds = time.seconds;
    }

    return {
      labels,
      colors,
      percentages,
      projects,
      title: `${hours || "00"}.${minutes || "00"}.${seconds || "00"}`,
    };
  } catch (err) {
    console.log(err);
    return { colors: [], percentages: [], projects: [], labels: [], title: "" };
  }
};

export const structureDataForBarChart = (
  tasks = [],
  labelsForBarChart = []
) => {
  const data = {};
  let totalSeconds = 0;

  for (let i = 0; i < labelsForBarChart.length; i++) {
    data[labelsForBarChart[i]] = [];
  }

  for (let i = 0; i < tasks.length; i++) {
    totalSeconds += parseInt(tasks[i].trackedinseconds);
    for (let j = 0; j < tasks[i].tasks?.length; j++) {
      data[tasks[i].formatted_started_at].push({ ...tasks[i].tasks[j] });
    }
  }

  const mappedTasksKeys = Object.keys(data);
  const mappedTasksValue = Object.values(data);
  const allProjects = {};

  for (let i = 0; i < mappedTasksKeys.length; i++) {
    mappedTasksValue[i]?.forEach((project) => {
      allProjects[project.projectid] = project;
    });
  }

  const allProjectsKeys = Object.keys(allProjects);
  const allProjectsArr = Object.values(allProjects);

  const dataSets = [];
  for (let i = 0; i < allProjectsArr.length; i++) {
    const dataForChart = [];
    const timeTracked = [];

    for (let j = 0; j < mappedTasksKeys.length; j++) {
      mappedTasksValue[j].forEach((project) => {
        if (project.projectid == allProjectsKeys[i]) {
          dataForChart.push(
            parseFloat(
              project.totalhours?.toLocaleString("fullwide", {
                maximumFractionDigits: 3,
              })
            )
          );
          const { hours, minutes, seconds } = getTimeFromSeconds(
            project.totalseconds
          );
          timeTracked.push(`${hours}:${minutes}:${seconds}`);
        }
      });
      if (!dataForChart[j]) dataForChart[j] = 0;
      if (!timeTracked[j]) timeTracked[j] = "";
    }

    dataSets.push({
      label: allProjectsArr[i]?.projectname,
      data: dataForChart,
      backgroundColor: allProjectsArr[i]?.projectcolor,
      time: timeTracked,
    });
  }

  let hours = "00";
  let minutes = "00";
  let seconds = "00";

  if (totalSeconds) {
    const time = getTimeFromSeconds(totalSeconds);
    hours = time.hours;
    minutes = time.minutes;
    seconds = time.seconds;
  }

  return { dataSets, title: `${hours}.${minutes}.${seconds}` };
};
