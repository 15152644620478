import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Avatar, Button, List, Select, Skeleton, Space, Table } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ProfileLogo from "../assets/img/team/profile-icon.png";
import BootstrapLoader from "../components/BootstrapLoader";
import RangePicker from "../components/DateRangePicker";
import Loader from "../components/Loader";
import { AuthContext } from "../context/auth-context";
import * as Data from "../helpers/server";
import { getTimeFromSeconds } from "../helpers/utils";

const options = [
  { value: "", label: "All" },
  { value: "pending", label: "Pending" },
  { value: "approved", label: "Approved" },
  { value: "rejected", label: "Rejected" },
  { value: "N/A", label: "N/A" },
];

const TimeEditRequests = ({ setPendingRequestsCount }) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState("pending");
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [deleteAllLoading, setDeleteAllLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [startDate, setStartDate] = useState(moment().startOf("week"));
  const [endDate, setEndDate] = useState(moment().endOf("day"));

  const initialColumns = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      width: 250,
      render: (field, obj) => {
        return (
          <Skeleton avatar title={false} loading={false}>
            <List.Item.Meta
              onClick={() => {
                navigate(`/users/${obj?.userid}`);
              }}
              avatar={<Avatar src={obj?.image || ProfileLogo} />}
              title={obj?.username}
              description={`Email: ${obj?.email || "N/A"}`}
            />
          </Skeleton>
        );
      },
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      render: (field, obj) => {
        return obj?.projectname;
      },
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
      render: (field, obj) => {
        return obj?.taskname;
      },
    },
    {
      title: "Original Start Time",
      dataIndex: "started_at",
      key: "started_at",
      render: (field, obj, index) => {
        return moment(obj.original_started_at).format("LLL");
      },
    },
    {
      title: "Updated Start Time",
      dataIndex: "updated_started_at",
      key: "updated_started_at",
      render: (field) => {
        return field ? moment(field).format("LLL") : "N/A";
      },
    },
    {
      title: "Original Stop Time",
      dataIndex: "taskhistory_original_stopped_at",
      key: "taskhistory_original_stopped_at",
      render: (taskhistory_original_stopped_at) => {
        return moment(taskhistory_original_stopped_at).format("LLL");
      },
    },
    {
      title: "Updated Stop Time",
      dataIndex: "updated_stopped_at",
      key: "updated_stopped_at",
      render: (field) => {
        return field ? moment(field).format("LLL") : "N/A";
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (field, obj, index) => {
        return auth?.isadmin ? (
          obj.status === "pending" ? (
            <>
              <Space style={{ alignItems: "end" }}>
                <CheckOutlined
                  style={{ color: "green", fontSize: 25 }}
                  onClick={async () => {
                    const err = await approveExtraTime(obj.taskhistoryid);

                    if (!err) {
                      setRequests((requests) =>
                        requests.map((row, i) => {
                          return i === index
                            ? { ...obj, status: "approved" }
                            : row;
                        })
                      );
                    } else console.log(err);
                  }}
                />
                <CloseOutlined
                  style={{ color: "#d03801", fontSize: 25 }}
                  onClick={async () => {
                    const err = await rejectExtraTime(obj.taskhistoryid);

                    if (!err) {
                      setRequests((requests) =>
                        requests.map((row, i) => {
                          return i === index
                            ? { ...obj, status: "rejected" }
                            : row;
                        })
                      );
                    }
                  }}
                />
                {auth?.userId == obj.user && (
                  <DeleteOutlined
                    style={{ color: "red", fontSize: 25 }}
                    onClick={async () => {
                      const err = await deleteExtraTrackedRequest(
                        obj.taskhistoryid
                      );

                      if (!err) {
                        setRequests((requests) =>
                          requests.map((row, i) => {
                            return i === index
                              ? { ...obj, status: "deleted" }
                              : row;
                          })
                        );
                      }
                    }}
                  />
                )}
              </Space>
            </>
          ) : (
            <span>{obj.status}</span>
          )
        ) : obj.status === "pending" ? (
          <DeleteOutlined
            style={{ color: "red", fontSize: 25 }}
            onClick={async () => {
              const err = await deleteExtraTrackedRequest(obj.taskhistoryid);

              if (!err) {
                setRequests((requests) =>
                  requests.map((row, i) => {
                    return i === index ? { ...obj, status: "deleted" } : row;
                  })
                );
              }
            }}
          />
        ) : (
          <span>{obj.status} </span>
        );
      },
    },
    {
      title: " ",
      dataIndex: "timeGap",
      key: "timeGap",
      render: (field, obj, index) => {
        const stopTimeDiff = moment(obj.updated_stopped_at).diff(
          obj.taskhistory_original_stopped_at,
          "seconds"
        );
        const startTimeDiff = moment(obj.original_started_at).diff(
          obj.updated_started_at,
          "seconds"
        );

        const totalDiff =
          stopTimeDiff && startTimeDiff
            ? stopTimeDiff + startTimeDiff
            : startTimeDiff
            ? startTimeDiff
            : stopTimeDiff;

        const { hours, minutes } = getTimeFromSeconds(
          totalDiff > 0 ? totalDiff : Math.abs(totalDiff)
        );
        return (
          <>
            <span>
              {totalDiff > 0 ? "+" : "-"}
              {hours}:{minutes}
            </span>
          </>
        );
      },
    },
  ];

  const [columns, setColumns] = useState(initialColumns);

  const getTimeEditRequests = async ({
    page,
    pageSize,
    status,
    user,
    startDate,
    endDate,
  }) => {
    try {
      if (auth?.token) {
        setIsLoading(true);
        const { requests, err, total } = await Data.getTimeEditRequests(
          {
            page,
            pageSize,
            status,
            otherUser: user,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
          auth?.token
        );
        setIsLoading(false);

        if (err) return console.log(err);

        setTotal(total);
        setRequests(requests);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onPaginationStateChange = async (pageNumber, pageSizeNumber) => {
    setPage(pageNumber);
    setPageSize(pageSizeNumber);
    getTimeEditRequests({
      page: pageNumber,
      pageSize: pageSizeNumber,
      status,
      user: selectedUser,
      startDate,
      endDate,
    });
  };

  const onStatusChanged = (value) => {
    setStatus(value);
    setPage(1);
    getTimeEditRequests({
      page: 1,
      pageSize,
      status: value,
      user: selectedUser,
      startDate,
      endDate,
    });

    setColumns((columns) =>
      columns.filter((c) => c.key !== "approved_at" && c.key !== "rejected_at")
    );

    if (value === "approved") {
      setColumns((columns) => [
        ...columns,
        {
          title: "Approved At",
          dataIndex: "approved_at",
          key: "approved_at",
          render: (field, obj, index) => {
            return obj.approved_at
              ? moment(obj.approved_at).format("LLL")
              : "N/A";
          },
        },
      ]);
    }

    if (value === "rejected") {
      setColumns((columns) => [
        ...columns,
        {
          title: "Rejected At",
          dataIndex: "rejected_at",
          key: "rejected_at",
          render: (field, obj, index) => {
            return obj.rejected_at
              ? moment(obj.rejected_at).format("LLL")
              : "N/A";
          },
        },
      ]);
    }
  };

  const approveExtraTime = async (taskhistory) => {
    try {
      const { err } = await Data.approveExtraTime({ taskhistory }, auth?.token);
      if (err) {
        alert(err);
        return err;
      } else setPendingRequestsCount((count) => (count > 0 ? count - 1 : 0));
    } catch (err) {
      console.log(err);
    }
  };

  const rejectExtraTime = async (taskhistory) => {
    try {
      const { err } = await Data.rejectExtraTime({ taskhistory }, auth?.token);
      if (err) {
        alert(err);
        return err;
      } else setPendingRequestsCount((count) => (count > 0 ? count - 1 : 0));
    } catch (err) {
      console.log(err);
    }
  };

  const deleteExtraTrackedRequest = async (taskhistory) => {
    try {
      const { err } = await Data.deleteExtraTrackedRequest(
        taskhistory,
        auth?.token
      );
      if (err) {
        alert(err);
        return err;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteAll = async (selectedRecords) => {
    try {
      setDeleteAllLoading(true);
      const { err } = await Data.deleteMultipleTrackingRequests(
        selectedRecords,
        auth?.token
      );
      setDeleteAllLoading(false);
      if (err) return console.log(err);
      setSelectedRecords([]);
      setRequests((requests) =>
        requests.map((row, i) => {
          return selectedRecords.indexOf(row.id) >= 0
            ? { ...row, status: "deleted" }
            : row;
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      getTimeEditRequests({
        page,
        pageSize,
        status,
        user: selectedUser,
        startDate,
        endDate,
      });
      getUsers();
    }
  }, [auth?.token]);

  const getUsers = async (keyword) => {
    setUsersLoading(true);
    const { users } = await Data.getAllUsers(auth?.token, keyword);
    setUsers(
      users?.map((user) => ({ ...user, value: user.id, label: user.name }))
    );
    setUsersLoading(false);
  };

  const selectUser = (id) => {
    setPage(1);
    setSelectedUser(id);
    getTimeEditRequests({
      page,
      pageSize,
      status,
      user: id,
      startDate,
      endDate,
    });
  };

  const clearUser = () => {
    setSelectedUser(null);
    setPage(1);

    getTimeEditRequests({
      page: 1,
      pageSize,
      status,
      user: null,
      startDate,
      endDate,
    });
  };

  const rowSelection = {
    type: "checkbox",
    selectedRowKeys: selectedRecords,
    onChange: (selectedRows) => {
      setSelectedRecords(selectedRows);
    },
    getCheckboxProps: (record) => {
      return {
        disabled:
          auth?.userId != record.user ||
          record.status === "deleted" ||
          record.status === "approved",
      };
    },
  };

  const onDateRangeChange = async (e) => {
    const range = e.currentTarget.value.split(" - ");
    const startDate = moment(range[0], "DD.MM.YYYY").startOf("day");
    const endDate = moment(range[1], "DD.MM.YYYY").endOf("day");

    setStartDate(startDate);
    setEndDate(endDate);
    setPage(1);

    await getTimeEditRequests({
      page: 1,
      pageSize,
      status,
      user: selectedUser,
      startDate,
      endDate,
    });
  };

  return (
    <main className="content container-new">
      <div style={{ direction: "row", spacing: 2 }}>
        <Select
          value={status}
          options={options}
          onChange={onStatusChanged}
          style={{
            width: "200px",
            backgroundColor: "white",
            border: "2px solid #e6e6e6",
            borderRadius: "13px",
            margin: "10px 0px",
          }}
          dropdownMatchSelectWidth={false}
          defaultValue="pending"
        />
        <Select
          value={selectedUser}
          loading={usersLoading}
          allowClear
          onSearch={getUsers}
          filterOption={false}
          placeholder="Select User"
          showSearch
          dropdownMatchSelectWidth={false}
          onSelect={selectUser}
          onClear={clearUser}
          style={{
            width: "200px",
            backgroundColor: "white",
            border: "2px solid #e6e6e6",
            borderRadius: "13px",
            marginLeft: "10px",
            marginRight: "10px",
          }}
        >
          {(users || []).map((user) => {
            return (
              <Select.Option key={user.id} value={user.id}>
                {user.name}
              </Select.Option>
            );
          })}
        </Select>
        <RangePicker
          onChange={onDateRangeChange}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <div
        className="TimeEditRequests"
        style={{
          padding: "10px",
          margin: "10px 0px",
          position: "relative",
        }}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {selectedRecords?.length > 0 && (
              <>
                <Button
                  hidden={!auth?.isadmin}
                  type="text"
                  danger
                  onClick={() => deleteAll(selectedRecords)}
                  icon={<DeleteOutlined style={{ verticalAlign: "super" }} />}
                  style={{
                    background: "#f6cccc",
                    borderRadius: "13px",
                    fontSize: "20px",
                    color: "#b34c4c",
                  }}
                />
                {deleteAllLoading && <BootstrapLoader />}
              </>
            )}
            <Table
              rowKey="id"
              rowSelection={status === "pending" ? rowSelection : null}
              dataSource={requests}
              columns={columns}
              pagination={{
                pageSize,
                current: page,
                defaultCurrent: 1,
                pageSizeOptions: [10, 20, 50, 100],
                total,
                onChange: onPaginationStateChange,
              }}
            />
          </>
        )}
      </div>
    </main>
  );
};

export default TimeEditRequests;
