import {
  faRestroom,
  faShoppingBag,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Card, Skeleton } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";

import ProfileLogo from "../assets/img/team/profile-icon.png";
import Loader from "../components/Loader";
import { AuthContext } from "../context/auth-context";
import * as Data from "../helpers/server";
import { getIndexOfOccurance, getTimeFromSeconds } from "../helpers/utils";

const { Meta } = Card;

const green = "#07da63";
const red = "#eb5a46";
const orange = "#ffab00";
const breakKeywords = ["lunch", "breakfast", "food", "eat", "pizza", "salad"];

const LiveDashboard = () => {
  const auth = useContext(AuthContext);

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getUsersLatestActivity = async () => {
    try {
      if (auth?.token) {
        setIsLoading(true);
        const { users, err } = await Data.getUsersLatestActivity(auth?.token);
        setIsLoading(false);

        if (err) return console.log(err);
        setUsers(users);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (auth?.token) getUsersLatestActivity();
  }, [auth?.token]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (users)
        setUsers((users) =>
          users.map((row) => {
            return { ...row, time: Date.now() };
          })
        );
    }, 1000 * 60);

    return () => {
      window.clearInterval(interval);
    };
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main className="content container-new">
      <div style={{ textAlign: "center" }}>
        <h1>Dashboard</h1>
        <p class="subtitle"> Get an overview of team's workload</p>
      </div>
      <div className="cardDiv">
        {users?.map((user) => {
          const endTime = user.latesttask?.stopped_at
            ? moment(user.latesttask.stopped_at)
            : moment();
          const startTime = moment(user.latesttask?.started_at);
          const diff = endTime.diff(startTime, "seconds");
          const { hours, minutes, seconds } = getTimeFromSeconds(diff);
          const text = user.breakstatus
            ? user.breakstatus.includes(":")
              ? user.breakstatus
                  .substring(getIndexOfOccurance(user.breakstatus, ":", 2) + 1)
                  .trim()
              : "On Break"
            : user.workspace_breakstatus
            ? user.workspace_breakstatus.includes(":")
              ? user.workspace_breakstatus
                  .substring(
                    getIndexOfOccurance(user.workspace_breakstatus, ":", 2) + 1
                  )
                  .trim()
              : "On Break"
            : "On Break";
          const color = user.latesttask
            ? !user.latesttask.stopped_at
              ? green
              : red
            : red;

          const userStatuses = user.breakstatus
            ? user.breakstatus.toLowerCase().split(" ")
            : user.workspace_breakstatus?.toLowerCase().split(" ");

          let breakReason = "other";
          userStatuses?.forEach((status) => {
            if (breakKeywords.includes(status)) breakReason = "food";
          });
          const icon = user?.onbreak
            ? breakReason === "food"
              ? faUtensils
              : faRestroom
            : faShoppingBag;

          return (
            <Card
              style={{
                width: 200,
                height: 250,
                backgroundColor: color,
                color: "white",
                position: "relative",
              }}
            >
              <Skeleton loading={false} avatar active>
                <Meta
                  avatar={
                    <div className="pro_img">
                      <Avatar
                        src={user?.image || ProfileLogo}
                        style={{ border: "2px solid white", zoom: 1.5 }}
                      />
                      <div className="pro_img2">
                        <FontAwesomeIcon icon={icon} />
                      </div>
                    </div>
                  }
                  title={
                    <div
                      style={{
                        color: color === "white" ? "black" : "white",
                      }}
                    >
                      <p style={{ margin: 0 }}>{user?.name}</p>
                      <p>@{user?.name?.split(" ")[0]?.toLowerCase()}</p>
                    </div>
                  }
                />
              </Skeleton>
              <div>
                {!user.onbreak ? (
                  user.latesttask && (
                    <>
                      <p style={{ margin: 0 }}>
                        #{user.latesttask?.projectname}
                      </p>
                      <span
                        className="entry__project__dot"
                        style={{ background: "white" }}
                      ></span>
                      <span style={{ whiteSpace: "initial" }}>
                        {user.latesttask?.taskname}
                      </span>

                      <h4
                        style={{
                          textAlign: "center",
                          color: "white",
                          position: "absolute",
                          bottom: 20,
                          margin: "0 auto",
                          left: 0,
                          right: 0,
                        }}
                      >
                        <b>
                          {hours > 0 ? `${hours}h` : ""}{" "}
                          {minutes > 0 ? `${minutes}m` : ""}{" "}
                          {hours === "00" && minutes === "00" && seconds > 0
                            ? `${seconds}s`
                            : ""}
                        </b>
                      </h4>
                    </>
                  )
                ) : (
                  <p style={{ textAlign: "center" }}>{text}</p>
                )}
              </div>
            </Card>
          );
        })}
      </div>
    </main>
  );
};

export default LiveDashboard;
