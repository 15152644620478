import {
  Avatar,
  Divider,
  List,
  Modal,
  Popover,
  Radio,
  Select,
  Skeleton,
  Space,
  Table,
} from "antd";
import moment from "moment";
import randomColor from "randomcolor";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ProfileLogo from "../assets/img/team/profile-icon.png";
import RangePicker from "../components/DateRangePicker";
import Loader from "../components/Loader";
import { AuthContext } from "../context/auth-context";
import * as Data from "../helpers/server";
import { getHourFromSeconds, getTimeFromSeconds } from "../helpers/utils";
import useProjectsTasks from "../hooks/useProjectsTasks";

const TeamDetails = () => {
  const { id: team } = useParams();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [startDate, setStartDate] = useState(moment().startOf("week"));
  const [endDate, setEndDate] = useState(moment().endOf("week"));

  const onSelectProject = (id) => {
    setPage(1);

    updateData({
      page: 1,
      pageSize,
      startDate,
      endDate,
      project: id,
      task: null,
    });
  };

  const onSelectTask = (id) => {
    setPage(1);

    updateData({
      page: 1,
      pageSize,
      startDate,
      endDate,
      project: id,
      task: id,
    });
  };

  const onClearProject = () => {
    setPage(1);

    updateData({
      page: 1,
      pageSize,
      startDate,
      endDate,
      project: null,
      task: null,
    });
  };

  const onClearTask = () => {
    setPage(1);

    updateData({
      page: 1,
      pageSize,
      startDate,
      endDate,
      project: selectedProject,
      task: null,
    });
  };

  const {
    projects,
    projectsLoading,
    selectProject,
    selectedProject,
    clearProject,
    updateProjects,
    tasks,
    selectedTask,
    tasksLoading,
    clearTask,
    updateTasks,
    selectTask,
  } = useProjectsTasks({
    onSelectProject,
    onClearProject,
    onClearTask,
    onSelectTask,
  });

  const columns = [
    {
      title: "Team Member",
      dataIndex: "teamMember",
      key: "teamMember",
      width: 300,
      render: (field, obj) => {
        return (
          <Skeleton avatar title={false} loading={false}>
            <List.Item.Meta
              onClick={() => {
                navigate(`/users/${obj?.id}`);
              }}
              avatar={<Avatar src={obj?.image || ProfileLogo} />}
              title={obj?.name}
              description={`Email: ${obj?.email || "N/A"}`}
            />
          </Skeleton>
        );
      },
    },
    {
      title: "Latest Activity",
      dataIndex: "task",
      key: "task",
      render: (field, obj) => {
        return obj.tasks?.length > 0 ? (
          <div>
            <ul>
              <div>
                <p> {obj.latesttask?.projectname}</p>
                <span
                  className="cl-entry__project__dot ng-star-inserted"
                  style={{ background: obj.tasks[obj.tasks?.length - 1].color }}
                ></span>
                <span>{obj.latesttask?.taskname}</span>
              </div>
            </ul>
          </div>
        ) : (
          <div style={{ lineHeight: "0px" }}>
            <ul>
              <div></div>
              <p>No Activity</p>
            </ul>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "duration",
      key: "duration",
      render: (field, obj) => {
        if (obj?.tasks?.length > 0) {
          const endTime = obj?.latesttask?.stopped_at
            ? moment(obj?.latesttask?.stopped_at)
            : moment();
          const diff = endTime.diff(
            moment(obj?.latesttask?.started_at),
            "seconds"
          );
          const { hours, minutes, seconds } = getTimeFromSeconds(diff);
          return obj?.tasks?.length > 0 ? (
            <>
              <p style={{ display: "inline" }}>
                {hours}:{minutes}:{seconds}{" "}
              </p>
              {!obj?.latesttask?.stopped_at && (
                <Radio value={1} checked={true} style={{ color: "red" }} />
              )}
            </>
          ) : (
            ""
          );
        }
      },
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (field, obj) => {
        return obj?.tasks?.length > 0
          ? !obj?.latesttask?.stopped_at
            ? "In Progress"
            : "Completed"
          : "";
      },
    },
    {
      title: `Total Tracked ${
        startDate && endDate
          ? `${moment(startDate).format("DD-MM-yyyy")} - ${moment(
              endDate
            ).format("DD-MM-yyyy")}`
          : "(This Week)"
      }`,
      dataIndex: "time",
      key: "time",
      render: (field, obj) => {
        return `${obj?.hours}:${obj?.minutes}:${obj?.seconds}`;
      },
    },
    {
      title: `Total Tracked ${
        startDate && endDate
          ? `${moment(startDate).format("DD-MM-yyyy")} - ${moment(
              endDate
            ).format("DD-MM-yyyy")}`
          : "(This Week)"
      }`,
      dataIndex: "graph",
      key: "graph",
      render: (field, obj) => {
        const time = `${obj?.hours}:${obj?.minutes}:${obj?.seconds}`;
        return (
          <Popover
            title={
              obj?.tasks?.length > 0 ? (
                <div>
                  <span>{obj?.name}</span>{" "}
                  <span style={{ float: "right" }}>{time}</span>
                </div>
              ) : (
                "No Tracking History"
              )
            }
            content={
              obj.tasks?.length > 0 ? (
                <>
                  {obj.tasks?.length > 0 ? (
                    <>
                      {obj.tasks.map((task, index) => {
                        const percentage =
                          (100 * task?.totalSeconds) / obj?.totalSeconds;
                        if (index < 10)
                          return (
                            <>
                              <div style={{ width: "350px" }}>
                                <table className="cl-main-horizontal-chart-container cl-w-100">
                                  <tr className="ng-star-inserted">
                                    <td className="cl-text-truncate cl-main-horizontal-chart-container--label cl-text-left">
                                      <span
                                        className="cl-entry__project__dot ng-star-inserted"
                                        style={{ background: task?.color }}
                                      ></span>
                                      <span title={task?.taskname}>
                                        {task?.taskname}
                                      </span>
                                    </td>
                                    <td className="cl-w-10 cl-text-right">
                                      <span>
                                        {task?.hours}:{task?.minutes}:
                                        {task?.seconds}
                                      </span>
                                    </td>
                                    <td className="cl-w-50 cl-main-horizontal-chart-container--progress ng-star-inserted"></td>
                                    <td className="cl-w-10 cl-text-nowrap cl-text-gray-9 cl-text-right ng-star-inserted">
                                      <span>
                                        {" "}
                                        {percentage.toLocaleString("fullwide", {
                                          maximumFractionDigits: 2,
                                        })}
                                        %{" "}
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </>
                          );
                      })}
                      <Divider dashed />
                      <span>Click on chart to see all</span>
                    </>
                  ) : (
                    <p />
                  )}
                </>
              ) : undefined
            }
            trigger="hover"
            placement="left"
          >
            <div
              className="progress mb-3"
              style={{ height: "50%", background: "grey" }}
              onClick={() => {
                navigate(`/users/${obj?.id}`);
              }}
            >
              {obj.tasks?.length > 0 ? (
                obj.tasks?.map((task) => {
                  return (
                    <div
                      className="progress-bar"
                      style={{
                        width: `${
                          (100 * task?.totalSeconds) / obj?.totalSeconds
                        }%`,
                        background: task?.color,
                      }}
                    >
                      <p />
                    </div>
                  );
                })
              ) : (
                <p />
              )}
            </div>
          </Popover>
        );
      },
    },
  ];

  const updateData = async ({
    page,
    pageSize,
    startDate,
    endDate,
    project,
    task,
  }) => {
    if (auth?.token && team) {
      setIsLoading(true);
      const {
        tasks: users,
        err,
        total,
      } = await Data.getTeamTaskHistory(
        {
          page,
          pageSize,
          startDate: startDate.startOf("day").format("yyyy-MM-DDTHH:mm:ss"),
          endDate: endDate.endOf("day").format("yyyy-MM-DDTHH:mm:ss"),
          project,
          task,
          team,
        },
        auth?.token
      );

      setIsLoading(false);
      if (err) return setErr(err);

      for (let i = 0; i < users.length; i++) {
        let totalSeconds = 0;

        for (let j = 0; j < users[i].tasks?.length; j++) {
          const end_time = users[i].tasks[j].stopped_at
            ? moment(users[i].tasks[j].stopped_at)
            : moment();
          const diff = end_time.diff(
            moment(users[i].tasks[j]?.started_at),
            "seconds"
          );

          const onlyHours = getHourFromSeconds(diff);
          const { hours, minutes, seconds } = getTimeFromSeconds(diff);
          const percentage =
            (onlyHours * 100) / 40 >= 100 ? 100 : (onlyHours * 100) / 40;

          users[i].tasks[j] = {
            ...users[i].tasks[j],
            onlyHours,
            percentage,
            color: randomColor(),
            hours,
            minutes,
            seconds,
            totalSeconds: diff,
          };

          totalSeconds += diff;
        }
        const { hours, minutes, seconds } = getTimeFromSeconds(totalSeconds);
        users[i].hours = hours;
        users[i].minutes = minutes;
        users[i].seconds = seconds;
        users[i].totalSeconds = totalSeconds;
      }

      setData(users);
      setTotal(total);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      updateData({ page, pageSize, startDate, endDate });
      updateProjects();
    }
  }, [auth?.token]);

  const onPaginationStateChange = async (pageNumber, pageSizeNumber) => {
    setPage(pageNumber);
    setPageSize(pageSizeNumber);
    updateData({
      page: pageNumber,
      pageSize: pageSizeNumber,
      startDate,
      endDate,
      project: selectedProject,
      task: selectedTask,
    });
  };

  const showError = () => {
    const secondsToGo = 5;

    const modal = Modal.error({
      title: err,
      onOk: () => setErr(null),
      onCancel: () => setErr(null),
    });

    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  };

  const onDateRangeChange = (e) => {
    const range = e.currentTarget.value.split(" - ");
    const startDate = moment(range[0], "DD.MM.YYYY").startOf("day");
    const endDate = moment(range[1], "DD.MM.YYYY").endOf("day");

    setStartDate(startDate);
    setEndDate(endDate);

    setPage(1);

    updateData({
      page: 1,
      pageSize,
      startDate,
      endDate,
      project: selectedProject,
      task: selectedTask,
    });
  };

  if (isLoading) {
    return (
      <main className="content container-new">
        <Loader />
      </main>
    );
  }

  return (
    <>
      <main className="content container-new">
        {err && showError()}

        <div style={{ marginTop: "4px", marginBottom: "4px" }}>
          <Space style={{ float: "right" }}>
            <Select
              allowClear
              value={selectedProject}
              showSearch
              dropdownMatchSelectWidth={false}
              placeholder="Select Project"
              onSearch={updateProjects}
              onSelect={selectProject}
              filterOption={false}
              options={projects}
              style={{ minWidth: "100px" }}
              onClear={clearProject}
              loading={projectsLoading}
            ></Select>
            <Select
              value={selectedTask}
              showSearch
              optionLabelProp="label"
              dropdownMatchSelectWidth={false}
              placeholder="Select Task"
              onSearch={(keyword) => updateTasks(selectedProject, keyword)}
              onSelect={selectTask}
              filterOption={false}
              loading={tasksLoading}
              disabled={!selectedProject}
              style={{ minWidth: "100px" }}
              options={tasks}
              allowClear
              onClear={clearTask}
            ></Select>
            <RangePicker
              onChange={onDateRangeChange}
              startDate={startDate}
              endDate={endDate}
            />
          </Space>
        </div>

        <div
          className="TeamDetails"
          style={{
            padding: "10px",
            borderRadius: "15px",
            margin: "10px 0px",
            position: "relative",
          }}
        >
          <Table
            dataSource={data}
            columns={columns}
            pagination={{
              pageSize,
              current: page,
              defaultCurrent: 1,
              pageSizeOptions: [10, 20, 50, 100],
              total,
              onChange: onPaginationStateChange,
            }}
          />
        </div>
      </main>
    </>
  );
};

export default TeamDetails;
