import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button } from "@themesberg/react-bootstrap";
import {
  Button as AntButton,
  Avatar,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  Select,
  Skeleton,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ProfileLogo from "../assets/img/team/profile-icon.png";
import BootstrapLoader from "../components/BootstrapLoader";
import { AuthContext } from "../context/auth-context";
import * as Data from "../helpers/server";
import { removeDuplicates } from "../helpers/utils";

const Teams = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const pageSize = 15;

  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [err, setErr] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [managerUsers, setManagerUsers] = useState([]);
  const [memberUsers, setMemberUsers] = useState([]);
  const [managerUsersLoading, setManagerUsersLoading] = useState(false);
  const [membersUserLoading, setMembersUserLoading] = useState(false);
  const [createTeamsLoading, setCreateTeamsLoading] = useState(false);
  const [deleteAllLoading, setDeleteAllLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [removableTeams, setRemovableTeams] = useState([]);
  
  const auth = useContext(AuthContext);

  const getManagerUsers = async (keyword) => {
    setManagerUsersLoading(true);
    const { users } = await Data.getAllUsers(auth?.token, keyword);
    setManagerUsers(users);
    setManagerUsersLoading(false);
  };

  const getMemberUsers = async (keyword) => {
    setMembersUserLoading(true);
    const { users } = await Data.getAllUsers(auth?.token, keyword);
    setMemberUsers(users);
    setMembersUserLoading(false);
  };

  const openTeamModal = async (type) => {
    if (type === "create") setSelectedTeam({});

    setShowModal(true);
    setType(type);
  };

  const handleClose = () => {
    setShowModal(false);
    setType(null);
    setSelectedTeam({});
    form.resetFields();
    getManagerUsers();
    getMemberUsers();
  };

  const createTeam = async (values) => {
    const newPageSize = teams.length < pageSize ? pageSize : teams.length;

    let res;

    setCreateTeamsLoading(true);
    if (type === "create") {
      res = await Data.createTeam(values, auth?.token);
    } else {
      const manager = values?.manager?.value
        ? values.manager.value
        : values.manager;

      const members = [];
      values?.members?.forEach((member) => members.push(member?.id || member));

      const oldMembersList = [];
      selectedTeam?.members?.forEach((m) => {
        oldMembersList.push(m.id);
      });

      const removedMembers =
        oldMembersList.filter((m) => !members?.includes(m)) || [];
      const addedMembers =
        members?.filter((m) => !oldMembersList.includes(m)) || [];

      await Data.editTeam(
        selectedTeam?.teamid,
        {
          ...values,
          removedMembers,
          addedMembers,
          members,
          manager,
        },
        auth?.token
      );
    }
    setCreateTeamsLoading(false);

    if (res?.err) return setErr(res?.err);

    form.resetFields();
    setTeams([]);
    setErr(null);
    setType(null);
    setShowModal(false);
    getTeams({ page: 1, pageSize: newPageSize });
  };

  const getTeams = async ({ page, pageSize }) => {
    setLoading(true);
    const { teams } = await Data.getTeams({ page, pageSize }, auth?.token);

    if (teams.length < pageSize) setShouldLoadMore(false);
    else setShouldLoadMore(true);

    setTeams((prev) => removeDuplicates(prev.concat(teams), "teamid"));
    setLoading(false);
  };

  useEffect(() => {
    setRemovableTeams(
      teams.filter(
        (team) =>
          auth?.isadmin ||
          team.manager?.id == auth?.userId ||
          team.created_by == auth?.userId
      )
    );
  }, [teams, auth?.isadmin, auth?.userId]);

  useEffect(() => {
    if (auth?.token) {
      getManagerUsers();
      getMemberUsers();
      getTeams({ page, pageSize });
    }
  }, [auth?.token]);

  const deleteTeam = async (e) => {
    try {
      setCreateTeamsLoading(true);
      const { err } = await Data.deleteTeam(e.target.value, auth?.token);
      const newPageSize = teams.length < pageSize ? pageSize : teams.length;
      setCreateTeamsLoading(false);
      if (err) return setErr(err);
      setTeams([]);
      setErr(null);
      setType(null);
      setShowModal(false);
      getTeams({ page: 1, pageSize: newPageSize });
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  const selectTeam = (e) => {
    const selectedTeam = teams.filter(
      (t) => t.teamid === parseInt(e.target.value)
    )[0];

    const members = [];
    selectedTeam?.members?.forEach((member) => members.push(member));

    form.setFieldsValue({
      name: selectedTeam?.teamname,
      manager: {
        value: selectedTeam?.manager?.id,
        label: selectedTeam?.manager?.name,
      },
      members,
    });
    setSelectedTeam(selectedTeam);
  };

  const onLoadMore = async () => {
    const newPage = page + 1;
    setPage(newPage);
    await getTeams({ page: newPage, pageSize });
  };

  const handleItemClick = (item) => {
    const index = selectedIds.indexOf(item.teamid);
    if (index === -1) {
      setSelectedIds([...selectedIds, item.teamid]);
    } else {
      setSelectedIds([
        ...selectedIds.slice(0, index),
        ...selectedIds.slice(index + 1),
      ]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(teams.map((user) => user.teamid));
    } else {
      setSelectedIds([]);
    }
  };

  const handleDeleteAll = async () => {
    setDeleteAllLoading(true);
    const { err } = await Data.deleteAllTeams(selectedIds, auth?.token);
    setDeleteAllLoading(false);
    if (err) {
      console.error(err);
    } else {
      console.log("Teams deleted successfully!");
    }
    setTeams((teams) =>
      teams.filter((team) => !selectedIds.includes(team.teamid))
    );
    setSelectedIds([]);
  };

  const loadMore =
    shouldLoadMore && !loading ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore} hidden={!shouldLoadMore && loading}>
          Load More
        </Button>
      </div>
    ) : null;

  return (
    <>
      <main className="content container-new">
        {showModal &&
          (type !== "delete" ? (
            <Modal
              title={type === "create" ? "Create New Team" : "Edit Team"}
              visible={showModal}
              className="select-none"
              onOk={form.submit}
              centered
              onCancel={handleClose}
              footer={[
                <div>
                  <Button
                    key="back"
                    onClick={handleClose}
                    style={{
                      border: "2px solid #d1d5db",
                      backgroundColor: "transparent",
                      color: "black",
                    }}
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    key="submit"
                    type="primary"
                    onClick={form.submit}
                    disabled={createTeamsLoading}
                  >
                    {type === "create" ? "Create" : "Update"}
                    {createTeamsLoading && <BootstrapLoader />}
                  </Button>
                </div>,
              ]}
            >
              <Form
                name="createTeam"
                form={form}
                onFinish={createTeam}
                layout="vertical"
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input team name.",
                          min: 3,
                          message: "Must be 3 letters long.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Team Manager" name="manager">
                      <Select
                        loading={managerUsersLoading}
                        allowClear
                        onSearch={getManagerUsers}
                        filterOption={false}
                        showSearch
                      >
                        {(managerUsers || []).map((user) => {
                          return (
                            <Select.Option key={user.id} value={user.id}>
                              {user.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Members" name="members">
                      <Select
                        mode="multiple"
                        allowClear
                        onSearch={getMemberUsers}
                        filterOption={false}
                        showSearch
                        loading={membersUserLoading}
                      >
                        {(memberUsers || []).map((user) => {
                          return (
                            <Select.Option key={user.id} value={user.id}>
                              {user.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}></Col>
                </Row>

                <Form.Item>
                  <p style={{ color: "red" }}>{err}</p>
                </Form.Item>
              </Form>
            </Modal>
          ) : (
            <Modal
              title="Delete Confirmation!"
              visible={showModal}
              className="select-none"
              onOk={deleteTeam}
              centered
              onCancel={handleClose}
              footer={[
                <div>
                  <Button
                    key="back"
                    onClick={handleClose}
                    style={{
                      border: "2px solid #d1d5db",
                      backgroundColor: "transparent",
                      color: "black",
                    }}
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    value={selectedTeam?.teamid}
                    key="submit"
                    type="primary"
                    onClick={deleteTeam}
                    disabled={createTeamsLoading}
                    style={{
                      backgroundColor: "#E02424",
                      color: "white",
                      borderColor: "#E02424",
                    }}
                  >
                    Delete
                    {createTeamsLoading && <BootstrapLoader />}
                  </Button>
                </div>,
              ]}
            >
              {`Are you sure to delete team ${selectedTeam?.teamname}?`}
              {err ? err : ""}
            </Modal>
          ))}
        <div
          style={{ display: "flex", marginBottom: "10px" }}
          onClick={() => openTeamModal("create")}
        >
          <Button variant="primary" hidden={!auth?.isadmin}>
            Create Team
          </Button>
        </div>
        <div className="TeamsList">
          <div style={{ display: "flex", gap: "20px", marginLeft: "10px" }}>
            <div className="div">
              {removableTeams.length > 0 && (
                <Checkbox
                  checked={selectedIds.length === removableTeams.length}
                  onChange={handleSelectAll}
                />
              )}
            </div>
            <div className="div">
              {selectedIds?.length > 0 && teams?.length > 0 && (
                <AntButton
                  type="text"
                  onClick={handleDeleteAll}
                  style={{
                    background: "#f6cccc",
                    borderRadius: "13px",
                    color: "#b34c4c",
                  }}
                  loading={deleteAllLoading}
                  icon={
                    <DeleteOutlined
                      style={{
                        verticalAlign: "top",
                        color: "#b34c4c",
                      }}
                    />
                  }
                />
              )}
            </div>
          </div>

          <List
            className="demo-loadmore-list"
            loading={loading}
            loadMore={loadMore}
            itemLayout="horizontal"
            dataSource={teams}
            renderItem={(item) => {
              const image = item?.manager?.image || ProfileLogo;
              const manager = item?.manager
                ? `${item?.manager?.name || ""}, ${item?.manager?.email || ""}`
                : "Not Assigned";
              return (
                <List.Item
                  key={item?.teamid}
                  onFocus={selectTeam}
                  actions={[
                    <AntButton
                      type="text"
                      hidden={
                        !auth?.isadmin &&
                        item.manager?.id != auth?.userId &&
                        item.created_by != auth?.userId
                      }
                      value={item.teamid}
                      onClick={() => openTeamModal("edit")}
                      icon={<EditOutlined style={{ verticalAlign: "top" }} />}
                      style={{
                        color: "#25805b",
                        background: "#def7ec",
                        borderRadius: "13px",
                      }}
                    />,
                    <AntButton
                      hidden={
                        !auth?.isadmin &&
                        item.manager?.id != auth?.userId &&
                        item.created_by != auth?.userId
                      }
                      type="text"
                      danger
                      value={item.teamid}
                      onClick={() => openTeamModal("delete")}
                      icon={
                        <DeleteOutlined style={{ verticalAlign: "super" }} />
                      }
                      style={{
                        color: "#b34c4c",
                        background: "#f6cccc",
                        borderRadius: "13px",
                      }}
                    />,
                  ]}
                >
                  <div style={{ width: "24px" }}>
                    {(auth?.isadmin ||
                      item.manager?.id == auth?.userId ||
                      item.created_by == auth?.userId) && (
                      <Checkbox
                        style={{ marginRight: "10px" }}
                        checked={selectedIds.includes(item.teamid)}
                        onChange={() => handleItemClick(item)}
                      />
                    )}
                  </div>
                  <Skeleton avatar title={false} loading={item.loading} active>
                    <List.Item.Meta
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        if (item.manager?.id == auth?.userId || auth?.isadmin)
                          navigate(`/teams/${item?.teamid}`);
                      }}
                      avatar={<Avatar src={image} />}
                      title={item?.teamname}
                      description={`Team Manager: ${manager}`}
                    />
                  </Skeleton>
                </List.Item>
              );
            }}
          />
        </div>
      </main>
    </>
  );
};

export default Teams;
