import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Footer from "../components/Footer";
import Loader from "../components/Loader";
import { AuthContext } from "../context/auth-context";
import { chromeExtensionId } from "../data/constants";
import * as Data from "../helpers/server";
import { PagesRoutes } from "../routes";
import ServerError from "./ServerError";
/* global chrome*/

const SlackAuthRedirectLogin = () => {
  const auth = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [code] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const postRedirectRequest = async () => {
      setIsLoading(true);

      if (sessionStorage.getItem("slack-state") !== code.get("state")) {
        setErr(true);
        setIsLoading(false);
        return;
      }

      const { user, token, err } = await Data.loginUsingSlackCode(
        code.get("code")
      );
      if (window.navigator.userAgent.toLowerCase().includes("chrome")) {
        chrome.runtime?.sendMessage(chromeExtensionId, {
          userId: user?.id,
          token,
          ...user,
        });
      }

      if (err) {
        setErr(err);
        setIsLoading(false);
        return;
      }

      auth.login(
        user?.id,
        token,
        user?.name,
        user?.email,
        user?.image,
        user?.isadmin,
        user?.isprojectmanager,
        user?.uid,
        user?.isAppInstalled,
        user?.workspace_id,
        user?.breakstatus,
        user?.isteammanager
      );
      setIsLoading(false);
      navigate(PagesRoutes.Dashboard.path);
    };

    postRedirectRequest();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (err)
    return (
      <ServerError
        title={err?.includes("account_inactive") ? "Token expired" : ""}
        description={
          err?.includes("account_inactive")
            ? "It looks like the app was uninstalled from your workspace. Please sign up again to refresh your token and continue using the service."
            : ""
        }
      />
    );

  return (
    <>
      <main className="content container-new"></main>
      <Footer />
    </>
  );
};

export default SlackAuthRedirectLogin;
