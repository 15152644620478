import { Modal } from "antd";
import React from "react";
import BootstrapLoader from "./BootstrapLoader";
import { Button } from "@themesberg/react-bootstrap";

const DeleteTrackingTimeModal = ({
  showModal,
  onOk,
  onCancel,
  err,
  loading,
  selectedTrackingHistory,
}) => {
  return (
    <Modal
      title="Delete Confirmation!"
      open={showModal}
      className="select-none"
      onOk={onOk}
      centered
      onCancel={onCancel}
      footer={[
        <div>
          {err && <p style={{ color: "red", textAlign: "center" }}>{err}</p>}
          <div>
            <Button
              key="back"
              onClick={onCancel}
              style={{
                border: "2px solid #d1d5db",
                backgroundColor: "transparent",
                color: "black",
              }}
            >
              Cancel
            </Button>{" "}
            <Button
              value={selectedTrackingHistory.taskhistoryid}
              key="submit"
              type="primary"
              disabled={loading || err}
              style={{
                backgroundColor: "#f05251",
                borderColor: "#f05251",
              }}
              onClick={onOk}
            >
              Delete
              {loading && <BootstrapLoader />}
            </Button>
          </div>
        </div>,
      ]}
    >
      {`Are you sure to delete tracking record with ${selectedTrackingHistory?.projectname} for ${selectedTrackingHistory.taskname}`}
    </Modal>
  );
};

export default DeleteTrackingTimeModal;
