import { EyeOutlined } from "@ant-design/icons";
import { Button } from "@themesberg/react-bootstrap";
import { Button as AntButton, Avatar, List, Segmented, Skeleton } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ProfileLogo from "../assets/img/team/profile-icon.png";
import { AuthContext } from "../context/auth-context";
import { showTaskDetailsModal } from "../helpers/notification";
import * as Data from "../helpers/server";
import { removeDuplicates } from "../helpers/utils";

const Notifications = ({ setUnreadNotificationsCount }) => {
  const pageSize = 15;
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [type, setType] = useState("unread");
  const [page, setPage] = useState(1);

  const updateUserNotifications = async ({ page, pageSize, type }) => {
    if (auth?.token) {
      setIsLoading(true);
      const { notifications, err, count } = await Data.getUserNotifications(
        { page, pageSize, type },
        auth?.token
      );
      setIsLoading(false);

      if (err) return console.log(err);

      if (notifications.length < pageSize) setShouldLoadMore(false);
      else setShouldLoadMore(true);

      setNotifications((prev) =>
        removeDuplicates(prev.concat(notifications), "notificationid")
      );

      if (type !== "read") setUnreadNotificationsCount(count);
    }
  };

  useEffect(() => {
    if (auth?.token) updateUserNotifications({ pageSize, pageSize, type });
  }, [auth?.token, type]);

  const onLoadMore = async () => {
    const newPage = page + 1;
    setPage(newPage);
    await updateUserNotifications({ page: newPage, pageSize, type });
  };

  const changeNotificationType = (value) => {
    setType(value);
    setNotifications([]);
    setPage(1);
  };

  const loadMore =
    shouldLoadMore && !isLoading ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore} hidden={!shouldLoadMore && isLoading}>
          Load More
        </Button>
      </div>
    ) : null;

  const markNotificationAsRead = async (e) => {
    e.stopPropagation();
    const targetId = e.currentTarget.id.split("-");
    const id = targetId[0];
    const isRead = targetId[1];

    if (isRead !== "true") {
      const { err } = await Data.markNotificationAsRead(id, auth?.token);
      setNotifications([]);
      await updateUserNotifications({
        page: 1,
        pageSize: notifications?.length,
        type,
      });
    }
  };

  const markAllNotificationsAsReadOfUser = async () => {
    await Data.markAllNotificationsAsReadOfUser(auth?.token);
    setNotifications([]);
  };

  return (
    <main className="content container-new">
      <div>
        <Segmented
          style={{ width: "130px", borderRadius: "12px" }}
          onChange={changeNotificationType}
          options={[
            {
              label: "Unread",
              value: "unread",
            },
            {
              label: "Read",
              value: "read",
            },
          ]}
        />
        <Button
          style={{ float: "right" }}
          hidden={type !== "unread" || notifications.length <= 0}
          onClick={markAllNotificationsAsReadOfUser}
        >
          Read All?
        </Button>
      </div>
      <List
        className="demo-loadmore-list"
        loading={isLoading}
        loadMore={loadMore}
        itemLayout="horizontal"
        dataSource={notifications}
        renderItem={(item) => {
          const image = item?.image || ProfileLogo;
          const user =
            item?.affected_user == auth?.userId
              ? "You"
              : `${item?.affectedusername}`;
          const notificationHours = item.notificationhours || 4;
          const status = !item?.stopped_at ? "working" : "worked";
          const verb = !item?.stopped_at
            ? item?.affected_user == auth?.userId
              ? "are"
              : "is"
            : "";

          return (
            <List.Item
              key={item?.notificationid}
              id={item?.notificationid}
              actions={[
                !item?.isread && (
                  <AntButton
                    type="text"
                    id={`${item.notificationid}-${item.isread}`}
                    value={`${item.notificationid}-${item.isread}`}
                    onClick={markNotificationAsRead}
                    icon={<EyeOutlined />}
                  />
                ),
              ]}
              onClick={() => showTaskDetailsModal(item)}
            >
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={image}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          (auth?.isadmin ||
                            auth?.isprojectmanager ||
                            auth?.userId == item?.affected_user) &&
                          item?.affected_user
                        )
                          item?.affected_user &&
                            navigate(`/users/${item?.affected_user}`);
                      }}
                    />
                  }
                  description={`${user} ${verb} ${status} on ${item?.projectname} with ${item?.taskname} for more than ${notificationHours} hours!`}
                />
              </Skeleton>
            </List.Item>
          );
        }}
      />
    </main>
  );
};

export default Notifications;
