import { Button } from "@themesberg/react-bootstrap";
import { Modal } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router";

import * as Data from "../../helpers/server";
import BootstrapLoader from "../../components/BootstrapLoader";
import { PagesRoutes } from "../../routes";

const DeleteUserAccountModal = ({ showModal, onModalClose, auth }) => {
  const [deleteRequestLoading, setDeleteRequestLoading] = useState(false);
  const navigate = useNavigate();

  const requestToDeleteMyData = async () => {
    setDeleteRequestLoading(true);
    await Data.requestToDeleteMyData(auth?.token);
    setDeleteRequestLoading(false);
    onModalClose();
    auth?.logout();
    navigate(PagesRoutes.HomePage.path);
  };

  return (
    <Modal
      title={"Delete Confirmation"}
      visible={showModal}
      className="select-none"
      onOk={requestToDeleteMyData}
      centered
      onCancel={onModalClose}
      footer={[
        <div>
          <Button key="back" onClick={onModalClose}>
            Cancel
          </Button>{" "}
          <Button
            key="submit"
            variant="danger"
            onClick={requestToDeleteMyData}
            disabled={deleteRequestLoading}
          >
            Delete
            {deleteRequestLoading && <BootstrapLoader />}
          </Button>
        </div>,
      ]}
    >
      Are you sure to delete your account?
    </Modal>
  );
};

export default DeleteUserAccountModal;
