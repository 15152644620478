import { Button } from "@themesberg/react-bootstrap";
import { Avatar, List } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Search from "antd/lib/input/Search";
import ProfileLogo from "../assets/img/team/profile-icon.png";
import { AuthContext } from "../context/auth-context";
import * as Data from "../helpers/server";
import { removeDuplicates } from "../helpers/utils";
import { PagesRoutes } from "../routes";

const pageSize = 10;

const AxolotlDashboard = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(1);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : undefined;
    const isAxolotlOwner = localStorage.getItem("isAxolotlOwner") === "true";

    if (!token) {
      return navigate(PagesRoutes.HomePage.path);
    }

    if (!isAxolotlOwner) {
      navigate(PagesRoutes.Dashboard.path);
    }
  }, [navigate]);

  const getCustomers = async ({ keyword, page, pageSize }) => {
    try {
      if (auth?.token) {
        setLoading(true);
        const { customers } = await Data.getAxolotlCustomers({
          keyword,
          page,
          pageSize,
          token: auth?.token,
        });

        if (customers.length < pageSize) setShouldLoadMore(false);
        else setShouldLoadMore(true);

        setCustomers((prev) =>
          removeDuplicates(prev.concat(customers), "workspace_id")
        );
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      getCustomers({ page, pageSize });
    }
  }, [auth?.token]);

  const onLoadMore = async () => {
    const newPage = page + 1;
    setPage(newPage);
    await getCustomers({ page: newPage, pageSize, keyword });
  };

  const onSearch = (value) => {
    setCustomers([]);
    setPage(1);
    if (value) getCustomers({ page: 1, pageSize, keyword: value });
    else getCustomers({ page: 1, pageSize });
  };

  const onKeywordChange = (e) => {
    setKeyword(e.target.value);

    if (!e.target.value) {
      setPage(1);
      setCustomers([]);
      getCustomers({ page: 1, pageSize });
    }
  };

  const loadMore =
    shouldLoadMore && !loading ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore} hidden={!shouldLoadMore && loading}>
          Load More
        </Button>
      </div>
    ) : null;

  return (
    <main className="content container-new">
      <div style={{ marginBottom: "10px" }}>
        <Search
          placeholder="Search"
          onSearch={onSearch}
          onChange={onKeywordChange}
          style={{ width: 200 }}
        />
      </div>
      <div className="usersList" style={{ padding: "10px" }}>
        <List
          className="demo-loadmore-list"
          loading={loading}
          loadMore={loadMore}
          itemLayout="horizontal"
          dataSource={customers}
          renderItem={(item) => {
            const image = item.image || ProfileLogo;

            return (
              <List.Item key={item?.id}>
                <table
                  style={{
                    width: "100%",
                    boxShadow: "rgba(0, 0, 0, 0.08) 2px 2px 20px",
                    backgroundColor: "transparent",
                    borderRadius: "15px",
                  }}
                >
                  <tr>
                    <td
                      style={{
                        padding: "8px",
                        backgroundColor: "#f9fafb",
                        borderTopLeftRadius: "15px",
                        borderTopRightRadius: "15px",
                        borderBottom: "2px solid #e9ebef",
                        color: "#9198a2",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        {item.workspace_name && item.workspace_url ? (
                          <a
                            href={item.workspace_url}
                            target="_blank"
                            style={{ marginLeft: "10px" }}
                            rel="noreferrer"
                          >
                            {item.workspace_name}
                          </a>
                        ) : (
                          <p style={{ marginLeft: "10px" }}>N/A</p>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "8px",
                        backgroundColor: "#FDFDFD",
                        borderBottomLeftRadius: "15px",
                        borderBottomRightRadius: "15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "5px",
                          gap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            color: "#17273A",
                            fontSize: "14px",
                          }}
                        >
                          <List.Item.Meta
                            avatar={<Avatar src={image} />}
                            title={item?.name}
                            description={item.email}
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          Users: {item?.workspace_users}
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </List.Item>
            );
          }}
        />
      </div>
    </main>
  );
};

export default AxolotlDashboard;
