import { List, Form, Checkbox, Button as AntButton } from "antd";
import { Button } from "@themesberg/react-bootstrap";
import { useCallback, useContext, useEffect, useState } from "react";
import { DownOutlined, UpOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";

import { AuthContext } from "../../context/auth-context";
import * as Data from "../../helpers/server";
import useSchedules from "../../hooks/useSchedules";
import ScheduleItem from "./ScheduleItem";
import CreateScheduleModal from "./CreateScheduleModal";
import DeleteScheduleModal from "./DeleteScheduleModal";
import { capitalizeFirstLetter } from "../../helpers/utils";
import { Button as BootStrapButton } from "@themesberg/react-bootstrap";
import BootstrapLoader from "../../components/BootstrapLoader";

const format = "HH:mm";
const dateTimeFormat = "dddd, HH:mm";

const Reminders = () => {
  const auth = useContext(AuthContext);
  const [form] = Form.useForm();
  const selectedInterval = Form.useWatch("interval", form);
  const recurring = Form.useWatch("isrepeating", form);
  const pageSize = 10;
  const isBirthdaySchedule = false;

  const {
    createSchedule,
    getSchedules,
    schedules,
    loading,
    createScheduleLoading,
    selectedSchedule,
    changeSelectedSchedule,
    deleteSchedule,
    changeOrder,
    order,
    page: schedulesPage,
    setPage: setSchedulesPage,
    err,
    setErr,
    shouldLoadMore,
    deleteMultipleSchedules,
  } = useSchedules();

  const [channels, setChannels] = useState([]);
  const [channelsKeyword, setChannelsKeyword] = useState(null);
  const [channelsLoading, setChannelsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState(null);
  const [deleteAllLoading, setDeleteAllLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const getUserChannels = useCallback(
    async (params = { page: 1, pageSize: 5 }) => {
      setChannelsLoading(true);
      const { channels } = await Data.getUserChannels(params, auth?.token);
      setChannels(channels);
      setChannelsLoading(false);
    },
    [auth?.token]
  );

  useEffect(() => {
    if (auth?.token) {
      getUserChannels();
      getSchedules({ pageSize, order, isBirthdaySchedule: false });
    }
  }, [auth?.token, getUserChannels]);

  const handleClose = () => {
    setShowModal(false);
    setType(null);
    changeSelectedSchedule("", form);
    setErr(null);
    form.resetFields();
  };

  const onLoadMore = async () => {
    const newPage = schedulesPage + 1;
    setSchedulesPage(newPage);
    await getSchedules({
      page: newPage,
      pageSize,
      order,
      isBirthdaySchedule,
    });
  };

  const loadMore =
    shouldLoadMore && !loading ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore} hidden={!shouldLoadMore && loading}>
          Load next 10 Scheduled Messages
        </Button>
      </div>
    ) : null;

  const openScheduleModal = async (type) => {
    if (type === "create") changeSelectedSchedule("", form);

    setShowModal(true);
    setType(type);
  };

  const onCreateSchedule = async (values) => {
    const { channel: formChannel } = values;
    const selectedChannel = channels.filter(
      (channel) => channel.id === formChannel
    )[0];
    values.channel_id = selectedChannel?.id;
    values.id = selectedSchedule?.id;
    values.channel_name = selectedChannel?.name;
    const time = values.time;

    if (values.isrepeating) {
      const day = capitalizeFirstLetter(values.day);
      const ISODate = moment(
        `${day}, ${values.time.format(format)}`,
        dateTimeFormat
      ).utc();

      values.local_time = values.time.format("HH:mm");
      values.day = ISODate.format("dddd").toLowerCase();
      values.time = ISODate.format("HH:mm");
      values.start_date = values.start_date.startOf("day").toISOString();
      values.end_date = values.end_date.endOf("day").toISOString();
    } else values.exact_date = values.exact_date.toISOString();

    if (values.schedule_date && values.schedule_month) {
      const ISODate = moment(
        `${values.schedule_date} ${values.schedule_month}, ${time.format(
          format
        )}`,
        "D M, HH:mm"
      ).utc();
      values.schedule_date = ISODate.format("D");
      values.schedule_month = ISODate.format("M");
    } else if (values.schedule_date) {
      const ISODate = moment(
        `${values.schedule_date}, ${time.format(format)}`,
        "D, HH:mm"
      ).utc();
      values.schedule_date = ISODate.format("D");
    } else if (values.schedule_month) {
      const ISODate = moment(
        `${values.schedule_month}, ${time.format(format)}`,
        "M, HH:mm"
      ).utc();
      values.schedule_month = ISODate.format("M");
    }

    const { err } = await createSchedule(values, type, {
      isBirthdaySchedule: false,
    });

    if (err) return setErr(err);

    form.resetFields();
    setShowModal(false);
  };

  const onEditSchedule = async () => {
    openScheduleModal("edit");
  };

  const onDeleteSchedule = async () => {
    openScheduleModal("delete");
  };

  const handleDeleteScheduleSubmit = async (e) => {
    const id = e.target.value;
    const { err } = await deleteSchedule(id, { isBirthdaySchedule: false });

    if (err) return setErr(err);

    setType(null);
    setShowModal(false);
    form.resetFields();
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = schedules.map((item) => item.id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const handleItemSelect = (itemId) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(itemId)) {
        return prevSelectedIds.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedIds, itemId];
      }
    });
  };

  const handleDeleteAll = async () => {
    setDeleteAllLoading(true);
    deleteMultipleSchedules(selectedIds);
    setDeleteAllLoading(false);
    setSelectedIds([]);
  };

  return (
    <>
      {showModal &&
        (type !== "delete" ? (
          <CreateScheduleModal
            recurring={recurring}
            selectedInterval={selectedInterval}
            isBirthdaySchedule={false}
            type={type}
            showModal={showModal}
            handleClose={handleClose}
            form={form}
            createScheduleLoading={createScheduleLoading}
            onCreateSchedule={onCreateSchedule}
            getUserChannels={getUserChannels}
            channelsLoading={channelsLoading}
            channels={channels}
            auth={auth}
            selectedSchedule={selectedSchedule}
            err={err}
          />
        ) : (
          <DeleteScheduleModal
            showModal={showModal}
            handleDeleteScheduleSubmit={handleDeleteScheduleSubmit}
            handleClose={handleClose}
            selectedSchedule={selectedSchedule}
            err={err}
            loading={createScheduleLoading}
          />
        ))}

      <div>
        {/* <Search
            placeholder="Search"
            onSearch={onSearch}
            onChange={onKeywordChange}
            style={{ width: 200 }}
          /> */}
        <Button
          variant="primary"
          style={{ float: "right", fontSize: "14px" }}
          onClick={() => openScheduleModal("create")}
          hidden={!auth?.isadmin}
        >
          Schedule Message
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          gap: "5px",
        }}
      >
        <div>
          <p
            style={{
              color: "#17273A",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            Scheduled Messages
          </p>
        </div>
        <div>
          <button
            type="button"
            onClick={(value) =>
              changeOrder(value, { isBirthdaySchedule: false })
            }
            style={{
              border: "none",
              color: "#08c",
              background: "none",
            }}
          >
            Sort by date
            {order === "desc" && (
              <DownOutlined
                style={{
                  verticalAlign: "middle",
                  fontSize: "15px",
                  padding: "0px 2px",
                }}
              />
            )}
            {order === "asc" && (
              <UpOutlined
                style={{
                  verticalAlign: "middle",
                  fontSize: "15px",
                  padding: "0px 2px",
                }}
              />
            )}
          </button>
        </div>
      </div>

      <div className="projectList">
        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <div className="div">
            {schedules.length > 0 && (
              <Checkbox
                checked={selectedIds.length === schedules.length}
                onChange={handleSelectAll}
              />
            )}{" "}
          </div>
          <div className="div">
            {selectedIds?.length > 0 && (
              <AntButton
                hidden={!auth?.isadmin}
                type="text"
                onClick={handleDeleteAll}
                style={{
                  background: "#f6cccc",
                  borderRadius: "13px",
                  color: "#b34c4c",
                }}
                loading={deleteAllLoading}
                icon={
                  <DeleteOutlined
                    style={{
                      verticalAlign: "top",
                      color: "#b34c4c",
                    }}
                  />
                }
              />
            )}
          </div>
        </div>
        <List
          className="demo-loadmore-list"
          loading={loading}
          loadMore={loadMore}
          itemLayout="horizontal"
          dataSource={schedules}
          renderItem={(item) => {
            const isSelected = selectedIds.includes(item.id);
            return (
              <ScheduleItem
                {...item}
                selected={isSelected}
                selectSchedule={changeSelectedSchedule}
                auth={auth}
                editSchedule={onEditSchedule}
                deleteSchedule={onDeleteSchedule}
                form={form}
                onItemSelect={handleItemSelect}
                deleteAll={handleDeleteAll}
              />
            );
          }}
        />
      </div>
    </>
  );
};

export default Reminders;
