import { EyeOutlined, LeftOutlined } from "@ant-design/icons";
import { faBell, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button as BootStrapButton,
  Container,
  Dropdown,
  Image,
  Nav,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Avatar, Badge, Button, Empty, List, Modal, Space, Spin } from "antd";
import moment from "moment";
import VirtualList from "rc-virtual-list";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ProfileLogo from "../assets/img/team/profile-icon.png";
import { AuthContext } from "../context/auth-context";
import { chromeExtensionId } from "../data/constants";
import { showTaskDetailsModal } from "../helpers/notification";
import * as Data from "../helpers/server";
import { removeDuplicates } from "../helpers/utils";
import { PagesRoutes } from "../routes";
import Tracking from "./Tracking";

/* global chrome */

const Navbars = ({
  shouldShowBackButton,
  unreadNotificationCount,
  setUnreadNotificationCount,
  label,
  startTimerLoading,
  setStartTimerLoading,
}) => {
  const pageSize = 10;
  const containerHeight = 300;

  const isAxolotlOwner = localStorage.getItem("isAxolotlOwner") === "true";

  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [type, setType] = useState("unread");
  const [page, setPage] = useState(1);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const updateNotifications = async ({ page, pageSize, type, order }) => {
    try {
      setIsLoading(true);
      const { notifications, err, count } = await Data.getUserNotifications(
        { page, pageSize, type },
        auth?.token
      );
      setIsLoading(false);

      if (err) return console.log(err);
      if (order === "first") return setUnreadNotificationCount(count);

      if (notifications.length < pageSize) setShouldLoadMore(false);
      else setShouldLoadMore(true);

      setNotifications((prev) =>
        removeDuplicates(prev.concat(notifications), "notificationid")
      );
      if (type !== "read") setUnreadNotificationCount(count);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (showNotifications && auth?.token)
      updateNotifications({ page, pageSize, type });
  }, [auth?.token, showNotifications, type]);

  const toggleNotificationModal = async () => {
    setShowNotifications((n) => !n);
  };

  const closeNotifications = () => {
    setShowNotifications(false);
    setPage(1);
    setNotifications([]);
    setShouldLoadMore(true);
    setType("unread");
  };

  const markNotificationAsRead = async (e) => {
    e.stopPropagation();
    const targetId = e.currentTarget.id.split("-");
    const id = targetId[0];
    const isRead = targetId[1];

    if (isRead !== "true") {
      const { err } = await Data.markNotificationAsRead(id, auth?.token);
      setNotifications([]);
      await updateNotifications({
        page: 1,
        pageSize: notifications?.length,
        type,
      });
    }
  };

  const onScroll = async (e) => {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
        containerHeight &&
      shouldLoadMore
    ) {
      const newPage = page + 1;
      setPage(newPage);
      await updateNotifications({ page: newPage, pageSize, type });
    }
  };

  const markAllNotificationsAsReadOfUser = () => {
    setNotifications([]);
    Data.markAllNotificationsAsReadOfUser(auth?.token);
  };

  const changeNotificationType = () => {
    setType((type) => (type === "unread" ? "read" : "unread"));
    setNotifications([]);
    setPage(1);
  };

  return (
    <Navbar
      variant="light"
      expanded
      style={{ padding: "2px 0px", marginBottom: "15px" }}
    >
      <Container fluid>
        {showNotifications && (
          <Modal
            title={"Notifications"}
            open={showNotifications}
            footer={null}
            style={{ top: 20, right: 2 }}
            onCancel={closeNotifications}
          >
            <BootStrapButton
              hidden={
                isLoading || type !== "unread" || notifications.length <= 0
              }
              onClick={markAllNotificationsAsReadOfUser}
              style={{ position: "relative", float: "right", zIndex: 99 }}
            >
              Read All?
            </BootStrapButton>
            {notifications?.length <= 0 && !isLoading && (
              <>
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{ height: 60, width: 100, margin: "2px auto" }}
                  description={false}
                >
                  {" "}
                  <BootStrapButton onClick={changeNotificationType}>
                    View {type === "unread" ? "Read" : "Unread"} Notifications?
                  </BootStrapButton>
                </Empty>
              </>
            )}
            {(notifications?.length > 0 || isLoading) && (
              <Spin spinning={isLoading}>
                <List>
                  <h3>{type === "unread" ? "Unread" : "Read"}</h3>

                  <VirtualList
                    data={notifications}
                    height={containerHeight}
                    itemHeight={100}
                    itemKey="_id"
                    onScroll={onScroll}
                  >
                    {(item) => {
                      const user =
                        item?.affected_user == auth?.userId
                          ? "You"
                          : `${item?.affectedusername}`;
                      const notificationHours = item.notificationhours || 4;
                      const verb = !item?.stopped_at
                        ? item?.affected_user == auth?.userId
                          ? "are"
                          : "is"
                        : "";
                      const status = !item?.stopped_at ? "working" : "worked";

                      const title =
                        item?.type === "time_updated"
                          ? `${item?.affectedusername} updated the tracking time on ${item?.projectname} with ${item?.taskname}`
                          : `${user} ${verb} ${status} on ${item?.projectname} with ${item?.taskname} for more than ${notificationHours} hours!`;

                      return (
                        <List.Item
                          onClick={() => showTaskDetailsModal(item)}
                          id={`${item?.notificationid}-${item?.isread}`}
                        >
                          <List.Item.Meta
                            avatar={<Avatar src={item?.image || ProfileLogo} />}
                            title={
                              <span>
                                {!item?.isread && <b>{title}</b>}
                                {item?.isread ? title : ""}
                              </span>
                            }
                          />
                          {!item?.isread && (
                            <div>
                              <Button
                                type="text"
                                id={`${item.notificationid}-${item.isread}`}
                                value={`${item.notificationid}-${item.isread}`}
                                onClick={markNotificationAsRead}
                                icon={<EyeOutlined />}
                              />
                            </div>
                          )}
                        </List.Item>
                      );
                    }}
                  </VirtualList>
                </List>
              </Spin>
            )}

            {notifications?.length > 0 && (
              <Button
                onClick={changeNotificationType}
                type="link"
                style={{ color: "#262B40" }}
              >
                View {type === "unread" ? "Read" : "Unread"} Notifications?
              </Button>
            )}
          </Modal>
        )}
        <div className="d-flex justify-content-between w-100 align-items-end ">
          <Space style={{ alignItems: "baseline" }}>
            {shouldShowBackButton && (
              <Button
                onClick={() => navigate(-1)}
                style={{ borderRadius: "8px" }}
                icon={<LeftOutlined style={{}} />}
              ></Button>
            )}
            <h5 style={{ color: "#17273A", fontSize: "24px" }}>
              <b>{label}</b>
            </h5>
            <p
              style={{
                color: "#6D737A",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {moment().format("dddd, D MMMM")}
            </p>
          </Space>
          <div className="d-flex align-items-center"></div>
          <Nav className="align-items-center">
            <div style={{ marginRight: "10px" }}>
              {!isAxolotlOwner && (
                <Tracking
                  startTimerLoading={startTimerLoading}
                  setStartTimerLoading={setStartTimerLoading}
                />
              )}
            </div>

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Badge count={unreadNotificationCount} overflowCount={9}>
                    <Image
                      src={auth.image || ProfileLogo}
                      className="user-avatar md-avatar rounded-circle"
                    />
                  </Badge>
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{auth.name}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                {!isAxolotlOwner && (
                  <Dropdown.Item
                    onClick={() => {
                      toggleNotificationModal();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faBell}
                      className="text-danger me-2"
                    />{" "}
                    Notifications
                    <Badge
                      count={unreadNotificationCount}
                      size="small"
                      overflowCount={9}
                    />
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  className="fw-bold"
                  onClick={() => {
                    if (
                      window.navigator.userAgent
                        .toLowerCase()
                        .includes("chrome")
                    ) {
                      chrome.runtime?.sendMessage(chromeExtensionId, "logout");
                    }

                    auth.logout();
                    navigate(PagesRoutes.HomePage.path);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className="text-danger me-2"
                  />{" "}
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
export default Navbars;
