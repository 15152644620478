import { Button } from "@themesberg/react-bootstrap";
import { Modal } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BootstrapLoader from "../../components/BootstrapLoader";
import * as Data from "../../helpers/server";
import { PagesRoutes } from "../../routes";

const UninstallAppModal = ({ showModal, onModalClose, auth }) => {
  const [uninstallRequestLoading, setUninstallRequestLoading] = useState(false);
  const navigate = useNavigate();

  const requestToDeleteMyData = async () => {
    setUninstallRequestLoading(true);
    await Data.uninstallSlackApp(auth?.token);
    setUninstallRequestLoading(false);
    onModalClose();
    auth?.logout();
    navigate(PagesRoutes.HomePage.path);
  };

  return (
    <Modal
      title={"Disconnect Confirmation"}
      visible={showModal}
      className="select-none"
      onOk={requestToDeleteMyData}
      centered
      onCancel={onModalClose}
      footer={[
        <div>
          <Button key="back" onClick={onModalClose}>
            Cancel
          </Button>{" "}
          <Button
            key="submit"
            variant="danger"
            onClick={requestToDeleteMyData}
            disabled={uninstallRequestLoading}
          >
            Disconnect
            {uninstallRequestLoading && <BootstrapLoader />}
          </Button>
        </div>,
      ]}
    >
      Are you sure to disconnect axolotl from your Slack workspace?
    </Modal>
  );
};

export default UninstallAppModal;
