import { useSetState } from "ahooks";
import { Button } from "@themesberg/react-bootstrap";
import { Card, Form, Input } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { AuthContext } from "../context/auth-context";
import * as Data from "../helpers/server";

const UpdatePassword = () => {
  const auth = useContext(AuthContext);

  const [state, setState] = useSetState({
    status: "pending",
    currentPassword: null,
    newPassword: null,
    error: null,
  });

  const [form] = Form.useForm();
  const { status, error, currentPassword, newPassword } = state;
  useEffect(() => {
    if (auth && status === "pending") {
      setState({
        status: "idle",
      });
    }
  }, [auth]);

  const changePassword = useCallback(
    async (currentPassword, newPassword) => {
      setState({ status: "pending", currentPassword, newPassword });
      const { err } = await Data.editUserProfile(
        { currentPassword, newPassword },
        auth?.token
      );

      if (err) return setState({ status: "error", error: err });
      setState({
        status: "complete",
        error: null,
        currentPassword: "",
        newPassword: "",
      });
    },
    [setState]
  );

  return (
    <div className="userSettingList">
      <Card title="Update Password" className="" loading={status === "pending"}>
        <Form
          style={{}}
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          name="basic"
          initialValues={{
            currentPassword,
            newPassword,
          }}
          onFinish={({ currentPassword, newPassword }) => {
            if (currentPassword === newPassword) {
              return setState({
                status: "error",
                error: "Current password and new password can not be same",
              });
            }

            changePassword(currentPassword, newPassword);
          }}
        >
          <Form.Item
            label="Current Password"
            name="currentPassword"
            rules={[
              {
                required: true,
                message: "Please input your current password.",
              },
            ]}
            hasFeedback={!!error}
            validateStatus={error && "error"}
            help={error}
          >
            <Input.Password maxLength={50} />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Please input your new password.",
              },
            ]}
            hasFeedback={!!error}
            validateStatus={error && "error"}
          >
            <Input.Password maxLength={50} />
          </Form.Item>

          <Form.Item
            label=" "
            colon={false}
            help={(() => {
              if (error) {
                return error.message;
              }

              if (status === "complete") {
                form.resetFields();
                return "Password updated successfully!";
              }
            })()}
          >
            <Button
              disabled={status === "pending"}
              type="primary"
              htmlType="submit"
              className="mb-3"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default UpdatePassword;
