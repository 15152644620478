import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Container,
  Image,
  Row,
} from "@themesberg/react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";

import ErrorImage from "../assets/img/illustrations/500.svg";
import { PagesRoutes } from "../routes";

const ServerError = ({ title, description }) => {
  return (
    <main>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row className="align-items-center">
            <Col
              xs={12}
              lg={5}
              className="order-2 order-lg-1 text-center text-lg-left"
            >
              <h1 className="text-primary mt-5">
                {title || (
                  <>
                    Something has gone{" "}
                    <span className="fw-bolder">seriously</span> wrong
                  </>
                )}
              </h1>
              <p className="lead my-4">
                {description || (
                  <>
                    If you have not signed up previously, please Sign up first
                    or else contact our support team.
                  </>
                )}
              </p>
              <Button
                as={Link}
                variant="primary"
                className="animate-hover"
                to={PagesRoutes.HomePage.path}
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="animate-left-3 me-3 ms-2"
                />
                Go back home
              </Button>
            </Col>
            <Col
              xs={12}
              lg={7}
              className="order-1 order-lg-2 text-center d-flex align-items-center justify-content-center"
            >
              <Image src={ErrorImage} className="img-fluid w-75" />
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ServerError;
