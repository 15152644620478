import React from "react";

import { Collapse } from "antd";
import { getTimeFromSeconds } from "../helpers/utils";
import UserTaskHeader from "./UserTaskHeader";
import UserTaskRowDetails from "./UserTaskRowDetails";
const { Panel } = Collapse;
const UserTaskRow = ({
  visibleTasks,
  mergedTasks,
  formatted_started_at,
  stopTaskTimer,
  openDeleteModal,
  startTaskTimer,
  auth,
  showEditTimeEntryModal,
  showEditRequestsHistoryModal,
  style,
}) => {
  return visibleTasks?.map((task, index) => {
    const tasks = mergedTasks?.[task?.key] || [];

    const {
      totalSeconds,
      taskname,
      taskid,
      projectname,
      projectid,
      stopped_at,
      taskhistoryid,
      isusereditaccessed,
      status,
      is_consecutive,
      user,
    } = task;

    const { hours, minutes, seconds } = getTimeFromSeconds(totalSeconds);

    const color =
      status === "pending"
        ? "geekblue"
        : status === "rejected"
        ? "volcano"
        : "green";

    return (
      <>
        {tasks.length <= 1 ? (
          <UserTaskRowDetails
            auth={auth}
            color={color}
            formatted_started_at={formatted_started_at}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            is_consecutive={is_consecutive}
            isusereditaccessed={isusereditaccessed}
            openDeleteModal={openDeleteModal}
            projectid={projectid}
            projectname={projectname}
            showEditRequestsHistoryModal={showEditRequestsHistoryModal}
            showEditTimeEntryModal={showEditTimeEntryModal}
            startTaskTimer={startTaskTimer}
            status={status}
            stopTaskTimer={stopTaskTimer}
            stopped_at={stopped_at}
            taskid={taskid}
            taskhistoryid={taskhistoryid}
            taskname={taskname}
            tasks={tasks}
            user={user}
            style={style}
          />
        ) : (
          <div style={{ marginBottom: "10px" }}>
            <Collapse
              expandIconPosition="end"
              style={{ borderRadius: "10px", padding: "2px" }}
            >
              <Panel
                header={
                  <div style={{ width: "75%", marginLeft: "-8px" }}>
                    <UserTaskHeader
                      taskname={taskname}
                      user={user}
                      hours={hours}
                      minutes={minutes}
                      seconds={seconds}
                      projectname={projectname}
                    />
                  </div>
                }
                key={index}
              >
                <div style={{ backgroundColor: "white" }}>
                  <UserTaskRow
                    auth={auth}
                    formatted_started_at={formatted_started_at}
                    mergedTasks={{}}
                    visibleTasks={tasks}
                    openDeleteModal={openDeleteModal}
                    showEditRequestsHistoryModal={showEditRequestsHistoryModal}
                    showEditTimeEntryModal={showEditTimeEntryModal}
                    startTaskTimer={startTaskTimer}
                    stopTaskTimer={stopTaskTimer}
                    style={{ marginLeft: "-4px", marginBottom: "10px" }}
                  />
                </div>
              </Panel>
            </Collapse>
          </div>
        )}
      </>
    );
  });
};

export default UserTaskRow;
