const BootstrapLoader = () => {
  return (
    <>
      {" "}
      <div
        class="spinner-border spinner-border-sm text-light"
        role="status"
        aria-hidden="true"
      ></div>
    </>
  );
};

export default BootstrapLoader;
