import {
  faAslInterpreting,
  faBell,
  faChartPie,
  faCog,
  faLineChart,
  faMoneyCheck,
  faPeopleGroup,
  faPoll,
  faProjectDiagram,
  faUsers,
  faWorm,
} from "@fortawesome/free-solid-svg-icons";

import AppLogo from "../assets/img/logo/app_nav_logo.png";
import ActivityLogo from "../assets/img/sidebar/Activity.png";
import DashboardLogo from "../assets/img/sidebar/Dashboard.png";
import LiveDashboardLogo from "../assets/img/sidebar/Live.png";
import TrackingRequestsLogo from "../assets/img/sidebar/Request.png";
import TeamsLogo from "../assets/img/sidebar/Teams.png";
import UsersLogo from "../assets/img/sidebar/Users.png";
import { PagesRoutes } from "../routes";

const sidebar = [
  {
    image: AppLogo,
    isAppLogo: true,
    link: PagesRoutes.HomePage.path,
    roles: ["all", "admin", "tm", "pm", "axolotl_owner"],
  },
  {
    title: "Dashboard",
    link: PagesRoutes.AxolotlOwnerDashboard.path,
    image: DashboardLogo,
    icon: faChartPie,
    roles: ["axolotl_owner"],
  },
  {
    title: "Dashboard",
    link: PagesRoutes.Dashboard.path,
    image: DashboardLogo,
    icon: faChartPie,
    roles: ["tm", "pm", "admin", "all"],
  },
  {
    title: "Live View",
    link: PagesRoutes.LiveDashboard.path,
    image: LiveDashboardLogo,
    icon: faWorm,
    roles: ["admin"],
  },
  {
    title: "HR Management",
    link: PagesRoutes.HrManagement.path,
    icon: faAslInterpreting,
    roles: ["admin"],
  },
  {
    title: "Team Activity",
    link: PagesRoutes.UserActivity.path,
    image: ActivityLogo,
    icon: faLineChart,
    roles: ["tm", "pm", "all", "admin"],
  },
  {
    title: "Projects",
    link: PagesRoutes.Projects.path,
    icon: faProjectDiagram,
    roles: ["tm", "pm", "admin", "all"],
  },
  {
    title: "Slack Status",
    link: PagesRoutes.SlackUpdateStatus.path,
    icon: faPoll,
    roles: ["admin"],
  },
  {
    title: "Users",
    link: PagesRoutes.Users.path,
    image: UsersLogo,
    icon: faUsers,
    roles: ["admin"],
  },
  {
    title: "Teams",
    link: PagesRoutes.Teams.path,
    image: TeamsLogo,
    icon: faPeopleGroup,
    roles: ["admin", "tm", "pm", "all"],
  },
  {
    title: "Tracking Requests",
    link: PagesRoutes.TimeEditingRequests.path,
    image: TrackingRequestsLogo,
    icon: faMoneyCheck,
    roles: ["admin", "tm", "pm", "all"],
  },
  {
    title: "Notifications",
    link: PagesRoutes.Notifications.path,
    icon: faBell,
    roles: ["tm", "pm", "admin", "all"],
  },
  {
    title: "Settings",
    icon: faCog,
    link: PagesRoutes.Settings.path,
    roles: ["tm", "pm", "admin", "all", "axolotl_owner"],
  },
];
export default sidebar;
