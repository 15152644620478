import { Tag } from "antd";
import React from "react";
import UserTaskHeader from "./UserTaskHeader";

import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  HistoryOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import { Button as AntButton, Modal } from "antd";

const UserTaskRowDetails = ({
  taskname,
  hours,
  minutes,
  seconds,
  projectname,
  user,
  tasks,
  status,
  color,
  startTaskTimer,
  stopTaskTimer,
  showEditRequestsHistoryModal,
  formatted_started_at,
  openDeleteModal,
  auth,
  stopped_at,
  taskhistoryid,
  isusereditaccessed,
  showEditTimeEntryModal,
  is_consecutive,
  taskid,
  projectid,
  style,
}) => {
  return (
    <div style={{ padding: "5px", ...style }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px",
        }}
      >
        <UserTaskHeader
          taskname={taskname}
          user={user}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
          projectname={projectname}
        />
        {tasks.length <= 1 && (
          <div style={{ width: "10%" }}>
            {status && status !== "N/A" && (
              <Tag
                key={status}
                color={color}
                style={{
                  borderRadius: "15px",
                  padding: "0px 10px",
                }}
              >
                {status}
              </Tag>
            )}
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
            gap: "10px",
          }}
        >
          {tasks?.length <= 1 ? (
            <>
              {stopped_at && (
                <AntButton
                  type="text"
                  style={{
                    background: "#f6cccc",
                    borderRadius: "13px",
                    color: "#b34c4c",
                  }}
                  id={`${formatted_started_at}__${taskhistoryid}`}
                  danger
                  icon={
                    <DeleteOutlined
                      style={{
                        verticalAlign: "top",
                        color: "#b34c4c",
                      }}
                    />
                  }
                  onClick={openDeleteModal}
                ></AntButton>
              )}
              <AntButton
                type="button"
                style={{
                  border: "none",
                  backgroundColor: "#FCD9BD",
                  borderRadius: "13px",
                  fontSize: "20px",
                  color: "#F65A20",
                }}
              >
                {!stopped_at ? (
                  <PauseOutlined
                    onClick={stopTaskTimer}
                    style={{ verticalAlign: "top" }}
                  />
                ) : (
                  <CaretRightOutlined
                    onClick={(e) => {
                      if (auth?.latestTask && !auth?.latestTask.stopped_at) {
                        const { confirm } = Modal;
                        const showConfirm = () => {
                          confirm({
                            title: "Attention!",
                            icon: <ExclamationCircleFilled />,
                            content: `Do you want to stop timer for ${auth?.latestTask?.projectname} with ${auth?.latestTask.taskname} and start timer for ${projectname} with ${taskname}`,
                            onOk: () => {
                              startTaskTimer(taskid, projectid);
                            },
                            onCancel() {},
                          });
                        };
                        const showSameTaskModal = () => {
                          confirm({
                            title: "Attention!",
                            icon: <ExclamationCircleFilled />,
                            content: `You are already working on ${auth?.latestTask?.projectname} with ${auth?.latestTask.taskname}`,
                            onOk: () => {},
                            onCancel() {},
                          });
                        };
                        if (
                          taskid == auth?.latestTask?.task &&
                          projectid == auth?.latestTask?.project
                        )
                          showSameTaskModal();
                        else showConfirm();
                      } else startTaskTimer(taskid, projectid);
                    }}
                    style={{ verticalAlign: "top" }}
                  />
                )}
              </AntButton>
              <AntButton
                type="button"
                disabled={
                  auth?.isadmin
                    ? false
                    : !isusereditaccessed || !stopped_at || is_consecutive
                }
                onClick={showEditTimeEntryModal}
                style={{
                  border: "none",
                  background: "#DEF7EC",
                  borderRadius: "13px",
                  fontSize: "20px",
                  color: "#25805B",
                }}
                id={`${formatted_started_at}__${taskhistoryid}`}
                icon={<EditOutlined style={{ verticalAlign: "top" }} />}
                title={
                  auth?.isadmin
                    ? undefined
                    : !isusereditaccessed
                    ? "Not authorized for adjusting time entry"
                    : !stopped_at
                    ? "Timer has to be paused for adjusting time entry"
                    : undefined
                }
              ></AntButton>
              <AntButton
                disabled={!status}
                type="button"
                style={{
                  border: "none",
                  background: "#EDEBFE",
                  borderRadius: "13px",
                  fontSize: "18px",
                  color: "#7E3BF2",
                  marginRight: "2px",
                }}
                onClick={showEditRequestsHistoryModal}
                id={`${formatted_started_at}__${taskhistoryid}`}
                icon={<HistoryOutlined style={{ verticalAlign: "top" }} />}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserTaskRowDetails;
