import { useContext, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { AuthContext } from "../context/auth-context";
import { getTimeFromSeconds } from "../helpers/utils";

import UserTaskRow from "./UserTaskRow";

const UserTask = ({
  index,
  formatted_started_at,
  tracked,
  startTaskTimer,
  stopTaskTimer,
  showEditTimeEntryModal,
  showEditRequestsHistoryModal,
  openDeleteModal,
  tasks,
  mergedTasks,
}) => {
  const auth = useContext(AuthContext);
  let totalSeconds = 0;
  let hours = "00",
    minutes = "00",
    seconds = "00";
  let isTaskRunning = false;
  let sortTasks = tasks.sort((a, b) => b.started_at - a.started_at);

  if (sortTasks) {
    for (let i = 0; i < sortTasks.length; i++) {
      const stopped_at = sortTasks[i].stopped_at;
      if (!stopped_at) isTaskRunning = true;

      totalSeconds += sortTasks[i].totalSeconds;
    }

    const time = getTimeFromSeconds(totalSeconds);
    hours = time.hours;
    minutes = time.minutes;
    seconds = time.seconds;
  }
  const totalTime = isTaskRunning ? `${hours}:${minutes}:${seconds}` : tracked;
  const [showAllTasks, setShowAllTasks] = useState(false);
  const visibleTasks = showAllTasks ? sortTasks : sortTasks.slice(0, 3);
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  let defaultIndex = index === 0 || index === 1 || index === 2;

  if (tasks?.length <= 0) return null;

  return (
    <div className="userTasks" style={{ margin: "20px 0px" }}>
      <Accordion
        expanded={defaultIndex ? !expanded : expanded}
        onChange={handleChange}
        key={index}
        sx={{ boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.04)" }}
      >
        <AccordionSummary
          sx={{
            backgroundColor: "#F9FAFB",
            borderBottom: "2px solid rgb(233, 235, 239)",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
            color: "#9198a2",
            padding: "0px 10px",
          }}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div style={{}}>
              <b>{formatted_started_at}</b>
            </div>
            <div style={{ marginLeft: "auto", marginRight: "10px" }}>
              {" "}
              <b>Total: {totalTime}</b>{" "}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#FFFF",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
            padding: "3px",
          }}
        >
          <button
            className="show-task"
            onClick={() => setShowAllTasks(!showAllTasks)}
            style={{ padding: "0px 10px" }}
          >
            <u
              style={{
                visibility:
                  sortTasks?.length <= 3
                    ? "hidden"
                    : visibleTasks?.length < 3
                    ? "hidden"
                    : "visible",
              }}
            >
              {" "}
              {showAllTasks ? "Show only last 3 tasks" : "Show all tasks"}
            </u>
          </button>

          <UserTaskRow
            auth={auth}
            formatted_started_at={formatted_started_at}
            mergedTasks={mergedTasks}
            openDeleteModal={openDeleteModal}
            showEditRequestsHistoryModal={showEditRequestsHistoryModal}
            showEditTimeEntryModal={showEditTimeEntryModal}
            startTaskTimer={startTaskTimer}
            stopTaskTimer={stopTaskTimer}
            visibleTasks={visibleTasks}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default UserTask;
