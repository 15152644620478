import React from "react";

import "./style.css";

function About() {
  return (
    <section className="about">
      <div className="about-heading" id="about-us">
        <h2>About Us</h2>
      </div>
      <div className="about-content">
        <div className="about-left">
          <p>
            Axolotl is a time tracking system like your HR Manager in Slack,
            that takes care of your team and improves the communication
            within the team through Slack status.
          </p>
        </div>
        <div className="about-right">
          <p>
            Axolotl records time spent on the implementation of a particular
            task. This time tracker connects with the Slack status so all team
            members in the company know what the person is working on.
            <br></br>
            For the workspace owner, it will be easier to know what people are
            working on at the moment.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
