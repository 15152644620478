import { useState } from "react";
import { Segmented } from "antd";

import Reminders from "./Reminders";
import BirthdayReminders from "./BirthdayReminders";

const SegmentBlocks = {
  REMINDERS: { label: "Reminders", value: "reminders" },
  BIRTHDAY_REMINDERS: {
    label: <span style={{ width: "300px" }}>Birthday Reminders</span>,
    value: "birthday_reminders",
  },
};

const HrPage = () => {
  const [selectedSegment, setSelectedSegment] = useState(
    SegmentBlocks.REMINDERS.value
  );

  const onSegmentChange = (value) => {
    setSelectedSegment(value);
  };

  return (
    <>
      <main className="content container-new">
        <div style={{ marginTop: "15px", width: "500px" }}>
          <Segmented
            block
            defaultValue={SegmentBlocks.REMINDERS.value}
            options={[
              SegmentBlocks.REMINDERS,
              SegmentBlocks.BIRTHDAY_REMINDERS,
            ]}
            onChange={onSegmentChange}
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          {selectedSegment === SegmentBlocks.REMINDERS.value && <Reminders />}
          {selectedSegment === SegmentBlocks.BIRTHDAY_REMINDERS.value && (
            <BirthdayReminders />
          )}
        </div>
      </main>
    </>
  );
};

export default HrPage;
