import { Image } from "@themesberg/react-bootstrap";
import React from "react";

import { Link, useNavigate } from "react-router-dom";
import AppLogoWithSlack from "../../../assets/img/logo/app_logo_slack.png";
import SlackButton from "../../../components/SlackButton";
import { CLIENT_ID } from "../../../config";

import "./style.css";

function LandingPageFooter() {
  const navigate = useNavigate();
  return (
    <footer className="footer-section">
      <div className="footer-content">
        <div className="footer-left">
          <Image src={AppLogoWithSlack} alt="Image" />
        </div>
        <div className="footer-center">
          <h2>Ready to try Axolotl?</h2>
          <p className="mb-4 footer-color">
            I have axolotl account. <br />
            <SlackButton
              href={`https://slack.com/openid/connect/authorize?client_id=${CLIENT_ID}&response_type=code&scope=openid,email,profile&redirect_uri=https://${window.location.hostname}/slack_oauth_redirect_login`}
              text="Log in with Slack"
            ></SlackButton>
          </p>

          <p className="mb-4 footer-color">
            I don't have axolotl account. <br />
            <SlackButton
              href={`https://slack.com/oauth/v2/authorize?client_id=${CLIENT_ID}&scope=chat:write.public,chat:write,users:read,commands,channels:read,groups:read,im:history,team:read&user_scope=users.profile:write&redirect_uri=https://${window.location.hostname}/slack_oauth_redirect_install`}
              text="Sign up with Slack"
            ></SlackButton>
          </p>
        </div>
      </div>

      <div className="terms container">
        <div className="content-container">
          <span className="condition">
            {/* <h4><a href="../Terms/Terms.js"></a>Terms and Condition</h4> */}
            <Link to="/terms">
              <h4>Terms and Conditions</h4>
            </Link>
          </span>
          <div className="vertical"></div>
          <span className="policy">
            {/* <h4>Privacy and Policy</h4> */}

            <h4
              onClick={() => {
                navigate("/policy");
                window.scrollTo(0, 0);
              }}
            >
              Privacy and Policy
            </h4>
          </span>
        </div>
      </div>

      <div className="contact-us">
        <div className="content-container">
          <span className="condition">
            <h4>
              Contact Us:{" "}
              <a href="mailto: axolotl@appsly.in" style={{ color: "white" }}>
                axolotl@appsly.in
              </a>
            </h4>
          </span>
        </div>
      </div>
    </footer>
  );
}

export default LandingPageFooter;
