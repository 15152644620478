import React from "react";
import { Image } from "@themesberg/react-bootstrap";

import DashboardExample from "../../../assets/img/logo/dashboard.png";

import "./style.css";

function ImageSection() {
  return (
    <section className="section">
      <div className="section-content">
        <div className="section-left">
          <p className="section-text">
            With the help of a user-friendly interface, you can easily and
            simply record the time you spend on a task
          </p>
        </div>
        <div className="section-right">
          <Image src={DashboardExample} alt="Image" />
        </div>
      </div>
    </section>
  );
}

export default ImageSection;
