import {
  DeleteOutlined,
  EditOutlined,
  SettingOutlined,
  SmileTwoTone,
} from "@ant-design/icons";
import { Button } from "@themesberg/react-bootstrap";
import {
  Button as AntButton,
  Avatar,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  Modal,
  Row,
  Space,
  Switch,
} from "antd";
import { useContext } from "react";

import moment from "moment";
import ProfileLogo from "../assets/img/team/profile-icon.png";
import BootstrapLoader from "../components/BootstrapLoader";
import useUsersPage from "../containerHooks/useUsersPage";
import { AuthContext } from "../context/auth-context";
import { PagesRoutes } from "../routes";

const { Search } = Input;

const Users = () => {
  const {
    showDeleteAllModal,
    selectedIds,
    form,
    contextHolder,
    err,
    showModal,
    canAllEditTime,
    deleteAllLoading,
    doesAllNeedApproval,
    createUsersLoading,
    navigate,
    loadMore,
    selectedUser,
    loading,
    type,
    users,
    setUsers,
    handleClose,
    openUserModal,
    deleteUser,
    selectUser,
    createUser,
    onSearch,
    onKeywordChange,
    updateUserTimeEditingAccess,
    toggleUserEditTaskTimeRights,
    toggleUserNeedApprovals,
    updateUserAdminRights,
    updateUserDOB,
    handleDeleteAll,
    handleItemClick,
    handleSelectAll,
    openDeleteAllModal,
    closeDeleteAllModal,
  } = useUsersPage();

  const auth = useContext(AuthContext);

  return (
    <>
      <main className="content container-new">
        {contextHolder}
        {(showDeleteAllModal || showModal) && type && type !== "delete" ? (
          <Modal
            title={type === "create" ? "Create New User" : "Edit User"}
            visible={showModal}
            className="select-none"
            onOk={form.submit}
            centered
            onCancel={handleClose}
            footer={[
              <div>
                <Button
                  key="back"
                  onClick={handleClose}
                  style={{
                    border: "2px solid #d1d5db",
                    backgroundColor: "transparent",
                    color: "black",
                  }}
                >
                  Cancel
                </Button>{" "}
                <Button
                  key="submit"
                  type="primary"
                  onClick={form.submit}
                  disabled={createUsersLoading}
                >
                  {type === "create" ? "Create" : "Update"}
                  {createUsersLoading && <BootstrapLoader />}
                </Button>
              </div>,
            ]}
          >
            <Form
              name="inviteUser"
              form={form}
              onFinish={createUser}
              layout="vertical"
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Name" name="name">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input User email!",
                        type: "email",
                      },
                    ]}
                  >
                    <Input disabled={type !== "create"} />
                  </Form.Item>
                </Col>
              </Row>
              <p style={{ color: "red" }}>{err}</p>
            </Form>
          </Modal>
        ) : (
          <Modal
            title="Delete Confirmation!"
            open={showDeleteAllModal || showModal}
            visible={showDeleteAllModal || showModal}
            className="select-none"
            onOk={showDeleteAllModal ? handleDeleteAll : deleteUser}
            centered
            onCancel={showDeleteAllModal ? closeDeleteAllModal : handleClose}
            footer={[
              <div>
                <Button
                  key="back"
                  onClick={
                    showDeleteAllModal ? closeDeleteAllModal : handleClose
                  }
                  style={{
                    border: "2px solid #d1d5db",
                    backgroundColor: "transparent",
                    color: "black",
                  }}
                >
                  Cancel
                </Button>{" "}
                <Button
                  value={selectedUser?.id}
                  key="submit"
                  type="primary"
                  onClick={showDeleteAllModal ? handleDeleteAll : deleteUser}
                  disabled={deleteAllLoading || createUsersLoading}
                  style={{
                    backgroundColor: "#f05251",
                    borderColor: "#f05251",
                  }}
                >
                  Delete
                  {(createUsersLoading || deleteAllLoading) && (
                    <BootstrapLoader />
                  )}
                </Button>
              </div>,
            ]}
          >
            {showDeleteAllModal
              ? "Are you sure to delete selected users?"
              : `Are you sure to delete user ${selectedUser?.name}?`}
            {err ? err : ""}
          </Modal>
        )}

        <div style={{ marginBottom: "10px" }}>
          <Search
            placeholder="Search"
            onSearch={onSearch}
            onChange={onKeywordChange}
            style={{ width: 200 }}
          />
          <Button
            variant="primary"
            hidden={!auth?.isadmin || true}
            onClick={() => openUserModal("create")}
          >
            Create User
          </Button>
          <Button
            variant="outline-primary"
            style={{ float: "right" }}
            onClick={() => {
              navigate(PagesRoutes.Settings.path);
            }}
          >
            <SettingOutlined style={{ verticalAlign: "middle", zoom: 1.4 }} />
            <span style={{ verticalAlign: "middle" }}> User Settings</span>
          </Button>
        </div>
        <div className="usersList" style={{ padding: "10px" }}>
          <div style={{ display: "flex" }}>
            <Space size="large">
              {users.length > 0 && (
                <Checkbox
                  checked={selectedIds.length === users.length}
                  onChange={handleSelectAll}
                />
              )}

              {selectedIds?.length > 0 && users?.length > 0 && (
                <>
                  <AntButton
                    hidden={!auth?.isadmin}
                    type="text"
                    onClick={openDeleteAllModal}
                    style={{
                      background: "#f6cccc",
                      borderRadius: "13px",
                      color: "#b34c4c",
                    }}
                    loading={deleteAllLoading}
                    icon={
                      <DeleteOutlined
                        style={{
                          verticalAlign: "top",
                          color: "#b34c4c",
                        }}
                      />
                    }
                  />
                </>
              )}
            </Space>

            <div style={{ marginLeft: "auto", marginRight: "5px" }}>
              {!loading && (
                <Space size="large">
                  <Space size="small">
                    Can edit time?
                    <Switch
                      defaultChecked={canAllEditTime}
                      checked={canAllEditTime}
                      onChange={toggleUserEditTaskTimeRights}
                    />
                  </Space>

                  <Space size="small">
                    Need editing approval?
                    <Switch
                      defaultChecked={doesAllNeedApproval}
                      checked={doesAllNeedApproval}
                      onChange={toggleUserNeedApprovals}
                    />
                  </Space>
                </Space>
              )}
            </div>
          </div>

          <List
            className="demo-loadmore-list"
            loading={loading}
            loadMore={loadMore}
            itemLayout="horizontal"
            dataSource={users}
            renderItem={(item) => {
              const image = item.image || ProfileLogo;

              return (
                <List.Item key={item?.id} onFocus={selectUser}>
                  <Checkbox
                    style={{ marginRight: "10px" }}
                    checked={selectedIds.includes(item.id)}
                    onChange={() => handleItemClick(item)}
                  />
                  <table
                    style={{
                      width: "100%",
                      boxShadow: "rgba(0, 0, 0, 0.08) 2px 2px 20px",
                      backgroundColor: "transparent",
                      borderRadius: "15px",
                    }}
                  >
                    <tr>
                      <td
                        style={{
                          padding: "8px",
                          backgroundColor: "#f9fafb",
                          borderTopLeftRadius: "15px",
                          borderTopRightRadius: "15px",
                          borderBottom: "2px solid #e9ebef",
                          color: "#9198a2",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <b>Email: {item.email || "N/A"}</b>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: "8px",
                          backgroundColor: "#FDFDFD",
                          borderBottomLeftRadius: "15px",
                          borderBottomRightRadius: "15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "5px",
                            gap: "20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              color: "#17273A",
                              fontSize: "14px",
                              width: "20%",
                            }}
                          >
                            <span>
                              <Avatar
                                src={image}
                                onClick={() => {
                                  if (
                                    item.id &&
                                    (auth?.isadmin ||
                                      auth?.isprojectmanager ||
                                      auth?.userId == item?.id)
                                  )
                                    navigate(`/users/${item?.id}`);
                                }}
                              />
                            </span>
                            <span>
                              <Space>
                                {" "}
                                {item.name}
                                {item?.isadmin && <SmileTwoTone />}
                              </Space>
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "unset",
                              gap: "15px",
                              color: "#17273A",
                              fontSize: "14px",
                            }}
                          >
                            Can edit time?{" "}
                            <Switch
                              defaultChecked={item.is_time_editing_allowed}
                              checked={item.is_time_editing_allowed}
                              onChange={(value) => {
                                updateUserTimeEditingAccess({
                                  is_time_editing_allowed: value,
                                  id: item.id,
                                });
                                setUsers((users) =>
                                  users?.map((user) =>
                                    user.id === item.id
                                      ? {
                                          ...user,
                                          is_time_editing_allowed: value,
                                        }
                                      : user
                                  )
                                );
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "unset",
                              gap: "15px",
                              color: "#17273A",
                              fontSize: "14px",
                            }}
                          >
                            Need editing approval?{" "}
                            <Switch
                              defaultChecked={item.need_time_editing_approval}
                              checked={item.need_time_editing_approval}
                              onChange={(value) => {
                                updateUserTimeEditingAccess({
                                  need_time_editing_approval: value,
                                  id: item.id,
                                });
                                setUsers((users) =>
                                  users?.map((user) =>
                                    user.id === item.id
                                      ? {
                                          ...user,
                                          need_time_editing_approval: value,
                                        }
                                      : user
                                  )
                                );
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "unset",
                              gap: "15px",
                              color: "#17273A",
                              fontSize: "14px",
                            }}
                          >
                            Admin?
                            <Switch
                              defaultChecked={item.isadmin}
                              checked={item.isadmin}
                              onChange={(value) => {
                                updateUserAdminRights({
                                  isAdmin: value,
                                  userToBeEdited: item.id,
                                });
                                setUsers((users) =>
                                  users?.map((user) =>
                                    user.id === item.id
                                      ? {
                                          ...user,
                                          isadmin: value,
                                        }
                                      : user
                                  )
                                );
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "unset",
                              gap: "15px",
                              color: "#17273A",
                              fontSize: "14px",
                            }}
                          >
                            <DatePicker
                              defaultValue={
                                item.date_of_birth &&
                                moment(item.date_of_birth, "DD-MM-YYYY")
                              }
                              onChange={(value) => {
                                updateUserDOB({
                                  date_of_birth: value
                                    ? value.format("DD-MM-YYYY")
                                    : value,
                                  id: item.id,
                                });
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <AntButton
                              type="text"
                              hidden={!auth?.isadmin}
                              value={item.id}
                              onClick={() => openUserModal("edit")}
                              icon={
                                <EditOutlined
                                  style={{ verticalAlign: "top" }}
                                />
                              }
                              style={{
                                color: "#25805b",
                                background: "#def7ec",
                                borderRadius: "13px",
                              }}
                            />
                            <AntButton
                              hidden={!auth?.isadmin}
                              type="text"
                              danger
                              value={item.id}
                              onClick={() => openUserModal("delete")}
                              icon={
                                <DeleteOutlined
                                  style={{ verticalAlign: "super" }}
                                />
                              }
                              style={{
                                background: "#f6cccc",
                                borderRadius: "13px",
                                fontSize: "20px",
                                color: "#b34c4c",
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </List.Item>
              );
            }}
          />
        </div>
      </main>
    </>
  );
};

export default Users;
