import React from "react";
import "./policy.css";
import Navbar from "../Navbar";
import LandingPageFooter from "../Footer";

const Policy = () => {
  return (
    <div className="privacy-policy">
      <Navbar />
      <div className="policy-card-container">
        <div className="policy-card">
          <strong>Privacy Policy</strong>

          <p>
            Axolotl built the Axolotl app as a Commercial app. This SERVICE is
            provided by Axolotl and is intended for use as is.
          </p>
          <p>
            This page is used to inform visitors regarding our policies
            regarding the collection, use, and disclosure of Personal
            Information if anyone decides to use our Service.
          </p>
          <p>
            If you choose to use our Service, then you agree to the collection
            and use of information about this policy. The Personal Information
            that we collect is used for providing and improving the Service. We
            will not use or share your information with anyone except as
            described in this Privacy Policy.
          </p>
          <p>
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which are accessible at Axolotl unless
            otherwise defined in this Privacy Policy.
          </p>

          <br></br>

          <strong>Information Collection and Use</strong>
          <p>
            For a better experience, while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to Slack username, Slack public
            information, email and date of birth. The information that we
            request will be retained by us and used as described in this privacy
            policy.
          </p>
          <p>
            The app does use third-party services that may collect information
            used to identify you.
          </p>
          <p>
            Link to the privacy policy of third-party service providers used by
            the app
          </p>

          <br></br>

          <strong>Data retention</strong>
          <p>
            When you use our app, Axolotl collects and stores certain types of
            data to provide you with the best possible experience. This includes
            your user profile information, such as your name, email and profile
            image.
          </p>
          <p>
            We may use trusted third-party services, such as cloud hosting
            providers, to store and process your data. However, we ensure that
            these service providers adhere to strict data protection standards.
          </p>
          <p>
            We will retain your data for as long as you continue to use our app,
            or until you explicitly request us to remove your data from our
            database.
          </p>
          <p>
            If you have any questions or concerns regarding our Data Retention
            Policy, please don't hesitate to contact our support team. We are
            here to assist you and provide further clarification on any aspect
            of our policy.
          </p>
          <p>
            We want to thank you for entrusting us with your data and assure you
            that we are committed to handling it responsibly, securely, and by
            applicable privacy regulations.
          </p>

          <br></br>

          <strong>Data archival/removal</strong>
          <p>
            Your data privacy is important to us, and we offer multiple
            convenient options to ensure you have full control over your
            information.
          </p>
          <p>
            1. If you prefer a quick and efficient way to request data removal,
            simply use the Slack command 'axolotl_delete_data.' This command
            empowers you to initiate the data removal process with ease,
            ensuring your data privacy is in your hands.
          </p>
          <p>
            2. In our settings page, you will discover a convenient 'Delete my
            account' button, which allows you to request the removal of your
            data from our platform. We've made it easy for you to maintain
            control over your data privacy and account management.
          </p>
          <p>
            3. Additionally, upon uninstalling our app, we will initiate the
            data removal process within 14 business days. This time frame allows
            us to perform the necessary checks and procedures to ensure the
            secure and complete removal of your data from our systems.
          </p>
          <p>
            We will make reasonable efforts to remove all your data from our
            active databases, backups, and any other relevant storage systems.
            This includes any information that can identify you personally, such
            as your name, contact details, account information, and any other
            data associated with your profile.
          </p>
          <p>
            While personal data will be removed, it's important to note that
            non-personal or aggregated data that does not identify you
            individually may be retained for analytical, statistical, or
            research purposes. This data is used in a way that respects your
            privacy and does not compromise your personal information.
          </p>
          <p>
            If you have any questions, or concerns, or would like further
            information regarding our Data Removal Policy, please contact our
            customer support team. We are committed to protecting your privacy
            and ensuring the proper handling of your data by applicable
            regulations and best practices.
          </p>

          <br></br>

          <strong>Data storage</strong>
          <p>
            At Axolotl, we prioritize the security and confidentiality of
            customer data. This Data Storage Policy outlines our procedures for
            the storage and protection of customer data to ensure its integrity
            and privacy.
          </p>
          <p>
            We collect and store customer data that is necessary to provide our
            services and improve user experience. The data collected may include
            personal information such as name and contact details. We only
            collect data that is relevant and required for the purpose specified
            during the data collection process.
          </p>
          <p>
            Customer data is stored securely using industry-standard encryption
            and security measures. We employ technical and administrative
            safeguards to protect against unauthorized access, disclosure,
            alteration, or destruction of customer data. These measures include
            secure server infrastructure, firewalls, access controls, and
            regular security audits.
          </p>

          <br></br>

          <strong>Log Data</strong>

          <p>
            We want to inform you that whenever you use our Service, in the case
            of an error in the app we collect data and information (through
            third-party products) on your phone called Log Data. This Log Data
            may include information such as your device Internet Protocol (“IP”)
            address, device name, operating system version, the configuration of
            the app when utilizing our Service, the time and date of your use of
            the Service, and other statistics.
          </p>

          <strong>Cookies</strong>
          <p>
            Cookies are files with a small amount of data that are commonly used
            as anonymous unique identifiers. These are sent to your browser from
            the websites that you visit and are stored on your device's internal
            memory.
          </p>
          <p>
            This Service does not use these “cookies” explicitly. However, the
            app may use third-party code and libraries that use “cookies” to
            collect information and improve their services. You have the option
            to either accept or refuse these cookies and know when a cookie is
            being sent to your device. If you choose to refuse our cookies, you
            may not be able to use some portions of this Service.
          </p>

          <strong>Service Providers</strong>

          <p>
            We may employ third-party companies and individuals due to the
            following reasons:
          </p>

          <ul>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>

          <p>
            We want to inform users of this Service that these third parties
            have access to their Personal Information. The reason is to perform
            the tasks assigned to them on our behalf. However, they are
            obligated not to disclose or use the information for any other
            purpose.
          </p>
          <br></br>
          <strong>Security</strong>
          <p>
            We value your trust in providing us with Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </p>

          <br></br>
          <strong>Links to Other Sites</strong>
          <p>
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </p>
          <br></br>

          <strong>Children’s Privacy</strong>
          <p>
            These Services do not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from children
            under 13 years of age. In the case we discover that a child under 13
            has provided us with personal information, we immediately delete
            this from our servers. If you are a parent or guardian and you are
            aware that your child has provided us with personal information,
            please contact us so that we will be able to take the necessary
            actions.
          </p>

          <br></br>

          <strong>Changes to This Privacy Policy</strong>
          <p>
            We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page.
          </p>

          <p>This policy is effective as of 2023-04-24</p>

          <br></br>

          <strong>Contact Us</strong>
          <p>
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us at{" "}
            <a href="mailto: axolotl@appsly.in">axolotl@appsly.in</a>.
          </p>
          <br></br>
        </div>
      </div>
      <div className="space"></div>
      <LandingPageFooter />
    </div>
  );
};

export default Policy;
