import React, { useEffect, useState } from "react";
import { Table, Modal, Spin, Input, notification, DatePicker } from "antd";
import moment from "moment";
import {
  CaretRightOutlined,
  PauseOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import "chart.js/auto";

import { getTimeFromSeconds } from "../helpers/utils";
import Doughnut from "./charts/Doughnut";
import { structureDataForDoughnutChart } from "../helpers/project";
import Loader from "./Loader";
import * as Data from "../helpers/server";

const UserActivity = ({
  user,
  auth,
  setTasks,
  tasks,
  onPaginationStateChange,
  page,
  pageSize,
  total,
  latestTask,
  startTaskTimer,
  stopTaskTimer,
  graphData,
  loading,
  isGraphLoading,
}) => {
  const [labels, setLabels] = useState([]);
  const [colors, setColors] = useState([]);
  const [title, setTitle] = useState(null);
  const [projects, setProjects] = useState([]);
  const [percentages, setPercentages] = useState([]);

  useEffect(() => {
    const { labels, colors, title, projects, percentages } =
      structureDataForDoughnutChart(graphData);

    setLabels(labels);
    setColors(colors);
    setTitle(title);
    setProjects(projects);
    setPercentages(percentages);
  }, [graphData]);

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (type, err, message) => {
    api[type]({
      message: err || message || "Date and time updated",
      placement: "bottomRight",
    });
  };

  const columns = [
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      width: 300,
      render: (field, obj) => {
        return obj?.projectname;
      },
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
      render: (field, obj) => {
        return obj?.taskname;
      },
    },
    {
      title: "Started At",
      dataIndex: "started_at",
      key: "started_at",
      render: (field, obj, index) => {
        return obj.isusereditaccessed ? (
          <Input
            defaultValue={moment(obj?.started_at).format("hh:mm A")}
            onPressEnter={async (e) => {
              const date = moment(obj.started_at);
              const date1 = moment(e.target.value, "hh:mm A");

              date.set("hours", date1.get("hours"));
              date.set("minutes", date1.get("minutes"));

              const { err, message } = await Data.updateTrackingTime(
                {
                  taskhistory: obj?.taskhistoryid,
                  started_at: date.toISOString(),
                  project: obj?.project,
                  task: obj?.task,
                  field: "start-time",
                },
                auth?.token
              );
              openNotification(err ? "error" : "info", err, message);

              if (!obj?.needtimeeditingapproval) {
                setTasks(
                  tasks.map((row, i) => {
                    return i === index
                      ? { ...obj, started_at: date.toISOString() }
                      : row;
                  })
                );
              }
            }}
          />
        ) : (
          moment(obj?.started_at).format("hh:mm A")
        );
      },
    },
    {
      title: "Stopped At",
      dataIndex: "stopped_at",
      key: "stopped_at",
      render: (field, obj, index) => {
        return obj.isusereditaccessed ? (
          obj.stopped_at ? (
            <Input
              defaultValue={moment(obj?.stopped_at).format("hh:mm A")}
              onPressEnter={async (e) => {
                const date = moment(obj.stopped_at);
                const date1 = moment(e.target.value, "hh:mm A");

                date.set("hours", date1.get("hours"));
                date.set("minutes", date1.get("minutes"));

                const { err, message } = await Data.updateTrackingTime(
                  {
                    taskhistory: obj?.taskhistoryid,
                    stopped_at: date.toISOString(),
                    project: obj?.project,
                    task: obj?.task,
                    field: "stop-time",
                  },
                  auth?.token
                );
                openNotification(err ? "error" : "info", err, message);

                if (!obj?.needtimeeditingapproval) {
                  setTasks(
                    tasks.map((row, i) => {
                      return i === index
                        ? { ...obj, stopped_at: date.toISOString() }
                        : row;
                    })
                  );
                }
              }}
            />
          ) : (
            "In Progress"
          )
        ) : obj.stopped_at ? (
          moment(obj?.stopped_at).format("hh:mm A")
        ) : (
          "In Progress"
        );
      },
    },
    {
      title: "Date",
      dataIndex: "started_at",
      key: "date",
      render: (field, obj, index) => {
        return obj.isusereditaccessed ? (
          <DatePicker
            defaultValue={moment(obj?.started_at)}
            onChange={async (e) => {
              const startDate = moment(obj.started_at);
              const date1 = moment(e, "yyyy-MM-DD");

              startDate.set("years", date1.get("years"));
              startDate.set("months", date1.get("months"));
              startDate.set("days", date1.get("days"));

              const endDate = moment(obj.stopped_at);
              endDate.set("years", date1.get("years"));
              endDate.set("months", date1.get("months"));
              endDate.set("days", date1.get("days"));

              const stopped_at = obj.stopped_at
                ? endDate.toISOString()
                : undefined;
              const started_at = startDate.toISOString();

              const { err, message } = await Data.updateTrackingTime(
                {
                  taskhistory: obj?.taskhistoryid,
                  started_at,
                  stopped_at,
                  project: obj?.project,
                  task: obj?.task,
                  field: "date",
                },
                auth?.token
              );
              openNotification(err ? "error" : "info", err, message);

              if (!obj?.needtimeeditingapproval) {
                setTasks(
                  tasks.map((row, i) => {
                    return i === index
                      ? { ...obj, started_at, stopped_at }
                      : row;
                  })
                );
              }
            }}
          />
        ) : (
          moment(obj?.started_at).format("yyyy-MM-DD")
        );
      },
    },
    {
      title: "Tracked",
      dataIndex: "duration",
      key: "duration",
      render: (field, obj) => {
        const diff = moment(obj.stopped_at || moment()).diff(
          moment(obj?.started_at),
          "seconds"
        );
        const { hours, minutes, seconds } = getTimeFromSeconds(diff);
        return (
          <>
            <p style={{ display: "inline" }}>
              {hours}:{minutes}:{seconds}{" "}
            </p>
          </>
        );
      },
    },
  ];

  if (auth?.uid === user?.uid) {
    columns.push({
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (field, obj) => {
        return (
          <>
            <div style={{ fontSize: "30px" }}>
              {obj.stopped_at ? (
                <div
                  id={`${obj?.task}____${obj.project}`}
                  onClick={(e) => {
                    if (latestTask && !latestTask.stopped_at) {
                      const { confirm } = Modal;
                      const showConfirm = () => {
                        confirm({
                          title: "Attention!",
                          icon: <ExclamationCircleFilled />,
                          content: `Do you want to stop timer for ${latestTask?.projectname} with ${latestTask.taskname} and start timer for ${obj?.projectname} with ${obj.taskname}`,
                          onOk: () => {
                            startTaskTimer(obj?.task, obj?.project);
                          },
                          onCancel() {},
                        });
                      };
                      showConfirm();
                    } else startTaskTimer(obj?.task, obj?.project);
                  }}
                >
                  <CaretRightOutlined />
                </div>
              ) : (
                <div
                  id={`${obj?.task}____${obj.project}`}
                  onClick={(e) => {
                    stopTaskTimer(e);
                  }}
                >
                  <PauseOutlined />
                </div>
              )}
            </div>
          </>
        );
      },
    });
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {contextHolder}
        <div style={{ width: "70%", overflow: "auto" }}>
          {loading ? (
            <Loader />
          ) : (
            <Table
              dataSource={tasks}
              columns={columns}
              pagination={{
                pageSize,
                current: page,
                defaultCurrent: 1,
                pageSizeOptions: [10, 20, 50, 100],
                total,
                onChange: onPaginationStateChange,
              }}
            />
          )}
        </div>
        <div style={{ width: "30%", float: "right" }}>
          <div style={{ height: "55px", backgroundColor: "#f8f9fa" }}></div>
          {!isGraphLoading ? (
            <Doughnut
              title={title}
              percentages={percentages}
              width={350}
              height={400}
              labels={labels}
              colors={colors}
              projects={projects}
            />
          ) : (
            <Spin />
          )}{" "}
        </div>
      </div>
    </>
  );
};

export default UserActivity;
