import React from "react";
import { Image } from "@themesberg/react-bootstrap";

import AppLogo from "../../../assets/img/logo/app_nav_logo.png";

import "./style.css";

function Navbar() {
  return (
    <nav>
      <div className="logo">
        <Image className="h-12 w-auto" src={AppLogo} alt="Logo" />
      </div>
      <div className="quick-links">
        <a href="#about-us">About us</a>
        <a href="#working">How do axolotl work?</a>
      </div>
    </nav>
  );
}

export default Navbar;
