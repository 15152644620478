import { List, Button as AntButton, Switch, Checkbox } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { faRepeat } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import * as Data from "../../helpers/server";

const dateTimeFormat = "dddd, HH:mm";
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const ScheduleItem = (props) => {
  const {
    id,
    time,
    selectSchedule,
    auth,
    day,
    channel_name,
    message,
    editSchedule,
    deleteSchedule,
    form,
    enabled,
    isrepeating,
    exact_date,
    interval,
    schedule_date,
    schedule_month,
    selected,
    onItemSelect,
  } = props;

  const [selectedIds, setSelectedIds] = useState([]);

  const toggleScheduledMessageDisability = () => {
    Data.toggleScheduledMessageDisability(auth?.token, id);
  };

  const onScheduleFocus = (e) => {
    const id = e.target.value;
    selectSchedule(id, form);
  };

  let timeMessage = "";

  if (!isrepeating) {
    timeMessage = moment(exact_date).format("DD-MM-yyyy, HH:mm A");
  } else {
    if (interval === "daily") {
      const ISODate = moment.utc(`${time}`, "HH:mm");
      const localDate = moment.tz(ISODate, timeZone);
      timeMessage = `Daily (${localDate.format("HH:mm A")})`;
    } else if (interval === "weekly") {
      const ISODate = moment.utc(`${day}, ${time}`, dateTimeFormat);
      const localDate = moment.tz(ISODate, timeZone);
      timeMessage = `Weekly (${localDate.format("dddd")} - ${localDate.format(
        "HH:mm A"
      )})`;
    } else if (interval === "monthly") {
      const ISODate = moment.utc(`${schedule_date}, ${time}`, "D, HH:mm");
      const localDate = moment.tz(ISODate, timeZone);
      timeMessage = `Monthly (${localDate.format("DD")} - ${localDate.format(
        "HH:mm A"
      )})`;
    } else if (interval === "yearly") {
      const ISODate = moment.utc(
        `${schedule_date} ${schedule_month}, ${time}`,
        "D M, HH:mm"
      );
      const localDate = moment.tz(ISODate, timeZone);
      timeMessage = `Yearly (${localDate.format("MMM")}, ${localDate.format(
        "DD"
      )} - ${localDate.format("HH:mm A")})`;
    }
  }

  const handleItemClick = () => {
    onItemSelect(id);
  };

  return (
    <div className={`schedule-item ${selected ? "selected" : ""}`}>
      <List.Item key={id} onFocus={onScheduleFocus}>
        <Checkbox
          checked={selected}
          onChange={handleItemClick}
          style={{ marginRight: "10px" }}
        />
        <table
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.08) 2px 2px 20px",
            backgroundColor: "transparent",
            borderRadius: "15px",
          }}
        >
          <tr>
            <td
              style={{
                padding: "8px",
                backgroundColor: "#f9fafb",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                borderBottom: "2px solid #e9ebef",
                color: "#9198a2",
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ color: "#6D737A", fontSize: "14px" }}>
                  {isrepeating && <FontAwesomeIcon icon={faRepeat} />}{" "}
                  {timeMessage}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "8px",
                backgroundColor: "#FDFDFD",
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "5px",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    color: "#17273A",
                    fontSize: "14px",
                    width: "20%",
                  }}
                >
                  <span>{message}</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "unset",
                    width: "20%",
                    color: "#17273A",
                    fontSize: "14px",
                  }}
                >
                  {/* {day} */}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "unset",
                    width: "30%",
                    color: "#17273A",
                    fontSize: "14px",
                  }}
                >
                  {channel_name}
                </div>
                <div>
                  <Switch
                    defaultChecked={enabled}
                    onChange={toggleScheduledMessageDisability}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <AntButton
                    type="text"
                    hidden={!auth?.isadmin}
                    value={id}
                    onClick={editSchedule}
                    icon={<EditOutlined style={{ verticalAlign: "top" }} />}
                    style={{
                      color: "#25805b",
                      background: "#def7ec",
                      borderRadius: "13px",
                    }}
                  />
                  <AntButton
                    hidden={!auth?.isadmin}
                    type="text"
                    danger
                    value={id}
                    onClick={deleteSchedule}
                    icon={<DeleteOutlined style={{ verticalAlign: "super" }} />}
                    style={{
                      background: "#f6cccc",
                      borderRadius: "13px",
                      fontSize: "20px",
                      color: "#b34c4c",
                    }}
                  />
                </div>
              </div>
            </td>
          </tr>
        </table>
      </List.Item>
    </div>
  );
};

export default ScheduleItem;
