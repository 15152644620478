import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "@themesberg/react-bootstrap";
import { Card, Col, Form, Input, Row, Switch } from "antd";

const TriggerKeywords = ({
  status,
  form,
  triggers = [],
  updateConfigs,
  error,
  setState,
  title,
}) => {
  return (
    <Card
      loading={status === "pending"}
      style={{
        width: 580,
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: "0px 5px 10px rgba(207, 214, 222, 0.25);",
      }}
      title={title}
    >
      <Form
        form={form}
        style={{}}
        layout="vertical"
        labelAlign="left"
        labelWrap
        name="basic"
        initialValues={{ triggers }}
        onFinish={updateConfigs}
        onFieldsChange={() => {
          setState({ status: "idle", error: null });
        }}
      >
        <Form.List name="triggers">
          {(fields, { add, remove }) => {
            return (
              <div style={{ marginTop: "10px" }}>
                {fields.map((field, index) => {
                  return (
                    <div key={field.key}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name={[field.name, "keyword"]}
                            colon={false}
                            rules={[
                              {
                                required: true,
                                message: "Please input keyword.",
                              },
                            ]}
                          >
                            <Input placeholder="Enter Keyword" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={[field.name, "enabled"]}
                            colon={false}
                          >
                            <Switch
                              defaultChecked={triggers[field.name]?.enabled}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Form.Item colon={false}>
                        <Button
                          type="danger"
                          className="btn-link no-hover"
                          onClick={() => remove(field.name)}
                          icon={<MinusCircleOutlined />}
                        >
                          <span style={{ marginLeft: "-10px", color: "red" }}>
                            Remove Above Field
                          </span>
                        </Button>
                      </Form.Item>
                    </div>
                  );
                })}
                <Form.Item colon={false}>
                  <Button
                    className="btn-link no-hover"
                    type="dashed"
                    onClick={(e) => {
                      e.preventDefault();
                      add();
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    <span style={{ marginLeft: "-10px" }}>Add keyword</span>
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>

        <Row gutter={16}>
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <Form.Item
              label=" "
              colon={false}
              help={(() => {
                if (error) return error.message;

                if (status === "complete")
                  return "Trigger keywords updated successfully!";
              })()}
            >
              <Button
                disabled={status === "pending"}
                type="primary"
                htmlType="submit"
                className="mb-3"
                style={{ padding: "10px 80px" }}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default TriggerKeywords;
