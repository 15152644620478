import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/auth-context";
import * as Data from "../helpers/server";

const useProjectsTasks = ({
  onSelectProject,
  onClearProject,
  onClearTask,
  onSelectTask,
}) => {
  const auth = useContext(AuthContext);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [tasksLoading, setTasksLoading] = useState(false);

  useEffect(() => {
    if (auth?.token) {
      updateProjects();
    }
  }, [auth?.token]);

  const updateProjects = async (keyword) => {
    try {
      if (auth?.token) {
        setProjectsLoading(true);
        const { projects } = await Data.getProjects(
          { keyword, shouldPopulate: false },
          auth?.token
        );
        setProjectsLoading(false);

        setProjects(projects || []);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateTasks = async (project, keyword) => {
    try {
      if (project && auth?.token) {
        setTasksLoading(true);
        const { tasks, err } = await Data.getTasksByProject(
          { project, keyword },
          auth?.token
        );

        if (err) setTasksLoading(false);

        setTasks(tasks);
        setTasksLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const selectProject = (id) => {
    updateTasks(id);
    setSelectedProject(id);
    setSelectedTask(null);
    onSelectProject(id);
  };

  const clearProject = () => {
    setSelectedProject(null);
    setSelectedTask(null);
    onClearProject();
  };

  const clearTask = () => {
    setSelectedTask(null);
    onClearTask();
  };

  const selectTask = (id) => {
    setSelectedTask(id);
    onSelectTask(id);
  };

  return {
    projects,
    selectedProject,
    projectsLoading,
    tasks,
    selectedTask,
    tasksLoading,
    selectProject,
    clearProject,
    updateProjects,
    clearTask,
    updateTasks,
    selectTask,
    setSelectedProject,
    setSelectedTask,
  };
};

export default useProjectsTasks;
