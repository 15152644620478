import { useEffect, useCallback, useContext, useState } from "react";
import { Form, Card, Input, Select, TimePicker, Switch, Row, Col } from "antd";
import { Button } from "@themesberg/react-bootstrap";
import { useSetState } from "ahooks";
import moment from "moment";

import { AuthContext } from "../../context/auth-context";
import * as Data from "../../helpers/server";

const { Option } = Select;
const format = "HH:mm";
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const BirthdayReminders = () => {
  const auth = useContext(AuthContext);
  const [form] = Form.useForm();

  const [channels, setChannels] = useState([]);
  const [channelsKeyword, setChannelsKeyword] = useState(null);
  const [channelsLoading, setChannelsLoading] = useState(false);

  const [state, setState] = useSetState({
    status: "pending",
    message: "",
    error: null,
    channel: "",
    time: null,
    enabled: true,
    id: null,
  });

  const getUserChannels = useCallback(
    async (params = { page: 1, pageSize: 5 }) => {
      setChannelsLoading(true);
      const { channels } = await Data.getUserChannels(params, auth?.token);
      setChannels(channels);
      setChannelsLoading(false);
    },
    [auth?.token]
  );

  const getBirthdaySchedule = useCallback(async () => {
    setState({ status: "pending" });
    const { schedule, err } = await Data.getBirthdaySchedule(auth?.token);
    if (err) {
      return setState({ error: err, status: "error" });
    }

    form.resetFields();

    const ISODate = moment.utc(`${schedule.time}`, format);
    const localDate = moment.tz(ISODate, timeZone);

    form.setFieldValue("message", schedule.message);
    form.setFieldValue("time", localDate);
    form.setFieldValue("channel", schedule.channel_id);
    form.setFieldValue("enabled", schedule.enabled);

    setState({ status: "idle", id: schedule.id });
  }, [auth?.token, form, setState]);

  useEffect(() => {
    if (auth?.token) {
      getUserChannels();
      getBirthdaySchedule();
    }
  }, [auth?.token, getUserChannels, getBirthdaySchedule]);

  useEffect(() => {
    if (auth && status === "pending") {
      setState({
        status: "idle",
      });
    }
  }, [auth]);

  const updateBirthdaySchedule = useCallback(async (values) => {
    setState({ status: "pending" });

    const { channel: formChannel } = values;
    const selectedChannel = channels.filter(
      (channel) => channel.id === formChannel
    )[0];
    values.channel_id = selectedChannel?.id;
    values.id = state?.id;
    values.channel_name = selectedChannel?.name;
    const ISODate = moment(`${values.time.format(format)}`, format).utc();
    values.local_time = values.time.format("HH:mm");
    values.time = ISODate.format(format);

    const { err } = await Data.updateBirthdaySchedule(values, auth?.token);
    if (err) return setState({ status: "error", error: err });

    setState({
      status: "complete",
      error: null,
      message,
      channel,
      time,
      enabled,
    });
  });

  const { status, error, message, channel, time, enabled } = state;

  return (
    <div className="userSettingList">
      <Card
        title="Birthday Reminder Settings"
        loading={status === "pending"}
        style={{
          width: 580,
          backgroundColor: "#FFFF",
          borderRadius: "10px",
          boxShadow: "0px 5px 10px rgba(207, 214, 222, 0.25);",
        }}
      >
        <Form
          form={form}
          layout="vertical"
          labelAlign="left"
          labelWrap
          name="basic"
          initialValues={{
            message,
            channel,
            time,
            enabled,
          }}
          onFinish={updateBirthdaySchedule}
          onFieldsChange={() => {
            setState({ status: "idle", error: null });
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Message"
                name="message"
                tooltip={"use [name] for dynamic message"}
                rules={[
                  {
                    required: true,
                    message: "Please input message.",
                    min: 3,
                    message: "Must be 3 letters long.",
                  },
                ]}
                hasFeedback={!!error}
                validateStatus={error && "error"}
              >
                <Input style={{ padding: "5px" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Channel"
                name="channel"
                rules={[{ required: true, message: "Please select channel" }]}
              >
                <Select
                  allowClear
                  onSearch={getUserChannels}
                  filterOption={false}
                  //showSearch
                  loading={channelsLoading}
                >
                  {(channels || []).map((channel) => {
                    return (
                      <Option key={channel.id} value={channel.id}>
                        {channel.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Time"
                name="time"
                rules={[{ required: true, message: "Please select time" }]}
              >
                <TimePicker format={format} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Enabled?"
                name="enabled"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col
              span={24}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Form.Item
                label=" "
                colon={false}
                help={(() => {
                  if (error) {
                    return error.message;
                  }

                  if (status === "complete") {
                    return "Birthday Schedule updated successfully!";
                  }
                })()}
              >
                <Button
                  disabled={status === "pending"}
                  type="primary"
                  htmlType="submit"
                  className="mb-3"
                  style={{ padding: "10px 80px" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default BirthdayReminders;
