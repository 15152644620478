import { Col, Container, Image, Row } from "@themesberg/react-bootstrap";
import React from "react";

import SignInImage from "../assets/img/illustrations/signin.svg";
import { CLIENT_ID } from "../config";
import SlackButton from "./SlackButton";

const SuccessfulSignup = () => {
  return (
    <main>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row className="align-items-center">
            <Col
              xs={12}
              lg={5}
              className="order-2 order-lg-1 text-center text-lg-left"
            >
              <h1 className="text-primary mt-5">
                We have successfully installed axolotl to your Slack workspace.
              </h1>
              <p className="lead my-4">
                Now login with Slack to continue using axolotl services
              </p>
              <SlackButton
                href={`https://slack.com/openid/connect/authorize?client_id=${CLIENT_ID}&response_type=code&scope=openid,email,profile&redirect_uri=https://${window.location.hostname}/slack_oauth_redirect_login`}
                text="Log in with Slack"
              ></SlackButton>
            </Col>
            <Col
              xs={12}
              lg={7}
              className="order-1 order-lg-2 text-center d-flex align-items-center justify-content-center"
            >
              <Image src={SignInImage} className="img-fluid w-75" />
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default SuccessfulSignup;
