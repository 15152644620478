import { Button } from "@themesberg/react-bootstrap";
import { Form, notification } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/auth-context";

import * as Data from "../helpers/server";
import { removeDuplicates } from "../helpers/utils";

const useUsersPage = () => {
  const auth = useContext(AuthContext);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const pageSize = 15;

  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState(null);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [createUsersLoading, setCreateUsersLoading] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [deleteAllLoading, setDeleteAllLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [canAllEditTime, setCanAllEditTime] = useState(false);
  const [doesAllNeedApproval, setDoesAllNeedApproval] = useState(false);
  const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);

  const openNotification = (type, err, message) => {
    api[type]({
      message: err || message || "Birth date updated",
      placement: "bottomRight",
    });
  };

  const getUsers = async ({ page, pageSize, keyword }) => {
    setLoading(true);
    const { users, canAllEditTime, doesAllNeedApproval } = await Data.getUsers(
      { page, pageSize, keyword },
      auth?.token
    );

    if (users.length < pageSize) setShouldLoadMore(false);
    else setShouldLoadMore(true);

    setCanAllEditTime(canAllEditTime);
    setDoesAllNeedApproval(doesAllNeedApproval);
    setUsers((prev) => removeDuplicates(prev.concat(users), "id"));
    setLoading(false);
  };

  useEffect(() => {
    if (auth?.token) getUsers({ page, pageSize });
  }, [auth?.token]);

  const handleClose = () => {
    setShowModal(false);
    setType(null);
    setSelectedUser({});
    form.resetFields();
  };

  const openUserModal = async (type) => {
    if (type === "create") setSelectedUser({});

    setShowModal(true);
    setType(type);
  };

  const openDeleteAllModal = () => setShowDeleteAllModal(true);
  const closeDeleteAllModal = () => setShowDeleteAllModal(false);

  const deleteUser = async (e) => {
    try {
      setCreateUsersLoading(true);
      const { err } = await Data.deleteAdminUser(e.target.value, auth?.token);
      const newPageSize = users.length < pageSize ? pageSize : users.length;
      setCreateUsersLoading(false);
      if (err) return setErr(err);

      setUsers([]);
      setErr(null);
      setType(null);
      setShowModal(false);
      getUsers({ page: 1, pageSize: newPageSize, keyword });
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  const selectUser = (e) => {
    const selectedUser = users.filter(
      (u) => u.id === parseInt(e.target.value)
    )[0];
    form.setFieldsValue({
      name: selectedUser?.name,
      email: selectedUser?.email,
    });
    setSelectedUser(selectedUser);
  };

  const createUser = async (values) => {
    const { name, email } = values;
    const newPageSize = users.length < pageSize ? pageSize : users.length;

    let res;

    setCreateUsersLoading(true);
    if (type === "create") {
      res = await Data.createAdminUser({
        name,
        email,
        token: auth?.token,
      });
    } else {
      res = await Data.editUserProfile(
        { id: selectedUser?.id, name, image: selectedUser?.image },
        auth?.token
      );
    }

    if (auth?.userId === selectedUser?.id) {
      auth.login(
        res?.user?.id,
        auth?.token,
        res?.user?.name,
        res?.user?.email,
        res?.user?.image,
        res?.user?.isadmin,
        res?.user?.isprojectmanager,
        res?.user?.uid,
        res?.user?.isAppInstalled,
        res?.user?.workspace_id,
        res?.user?.breakstatus,
        res?.user?.isteammanager
      );
    }

    setCreateUsersLoading(false);

    if (res?.err) return setErr(res?.err);

    form.resetFields();
    setErr(null);
    setType(null);
    setShowModal(false);

    if (type === "create") {
      setUsers([]);
      getUsers({ page: 1, pageSize: newPageSize, keyword });
    } else {
      setUsers((users) =>
        users?.map((user) =>
          user?.id == selectedUser?.id ? { ...user, name } : user
        )
      );
    }
  };

  const onSearch = (value) => {
    setUsers([]);
    setPage(1);
    if (value) getUsers({ page, pageSize, keyword: value });
    else getUsers({ page, pageSize });
  };

  const onKeywordChange = (e) => {
    setKeyword(e.target.value);

    if (!e.target.value) {
      setPage(1);
      setUsers([]);
      getUsers({ page: 1, pageSize });
    }
  };

  const onLoadMore = async () => {
    const newPage = page + 1;
    setPage(newPage);
    await getUsers({ page: newPage, pageSize, keyword });
  };

  const updateUserTimeEditingAccess = async ({
    is_time_editing_allowed,
    need_time_editing_approval,
    id,
  }) => {
    const { canAllEditTime, doesAllNeedApproval } =
      await Data.editUserTimeEditingAccess(
        { is_time_editing_allowed, need_time_editing_approval, id },
        auth?.token
      );

    setCanAllEditTime(canAllEditTime);
    setDoesAllNeedApproval(doesAllNeedApproval);
  };

  let toggleUserEditTaskTimeRightsTimeout;
  const toggleUserEditTaskTimeRights = async (value) => {
    setCanAllEditTime(value);
    setUsers((users) =>
      users?.map((user) => ({ ...user, is_time_editing_allowed: value }))
    );
    toggleUserEditTaskTimeRightsTimeout = setTimeout(async () => {
      if (toggleUserEditTaskTimeRightsTimeout)
        clearTimeout(toggleUserEditTaskTimeRightsTimeout);
      await Data.toggleUserEditTaskTimeRights(
        { is_time_editing_allowed: value },
        auth?.token
      );
    }, [1000]);
  };

  let toggleUserNeedApprovalsTimeout;
  const toggleUserNeedApprovals = async (value) => {
    setDoesAllNeedApproval(value);
    setUsers((users) =>
      users?.map((user) => ({ ...user, need_time_editing_approval: value }))
    );
    toggleUserNeedApprovalsTimeout = setTimeout(async () => {
      if (toggleUserNeedApprovalsTimeout)
        clearTimeout(toggleUserNeedApprovalsTimeout);
      await Data.toggleUserNeedApprovals(
        { need_time_editing_approval: value },
        auth?.token
      );
    }, [1000]);
  };

  const updateUserAdminRights = async ({ isAdmin, userToBeEdited }) => {
    await Data.editUserAdminRights({ isAdmin, userToBeEdited }, auth?.token);
  };

  const updateUserDOB = async ({ date_of_birth, id }) => {
    const { err, message } = await Data.updateUserDOB(
      { date_of_birth, id },
      auth?.token
    );
    openNotification(err ? "error" : "info", err, message);
  };

  const handleDeleteAll = async () => {
    setDeleteAllLoading(true);
    const { err } = await Data.deleteAllUsers(selectedIds, auth?.token);
    setDeleteAllLoading(false);
    if (err) {
      console.error(err);
    } else {
      console.log("Users deleted successfully!");
    }

    closeDeleteAllModal();
    setUsers((users) => users.filter((user) => !selectedIds.includes(user.id)));
    setSelectedIds([]);
  };

  const handleItemClick = (item) => {
    const index = selectedIds.indexOf(item.id);
    if (index === -1) {
      setSelectedIds([...selectedIds, item.id]);
    } else {
      setSelectedIds([
        ...selectedIds.slice(0, index),
        ...selectedIds.slice(index + 1),
      ]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(users.map((user) => user.id));
    } else {
      setSelectedIds([]);
    }
  };

  const loadMore =
    shouldLoadMore && !loading ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore} hidden={!shouldLoadMore && loading}>
          Load More
        </Button>
      </div>
    ) : null;

  return {
    selectedIds,
    form,
    contextHolder,
    err,
    showModal,
    canAllEditTime,
    deleteAllLoading,
    doesAllNeedApproval,
    createUsersLoading,
    showDeleteAllModal,
    navigate,
    loadMore,
    selectedUser,
    loading,
    type,
    users,
    setUsers,
    handleClose,
    openUserModal,
    deleteUser,
    selectUser,
    createUser,
    onSearch,
    onKeywordChange,
    updateUserTimeEditingAccess,
    toggleUserEditTaskTimeRights,
    toggleUserNeedApprovals,
    updateUserAdminRights,
    updateUserDOB,
    handleDeleteAll,
    handleItemClick,
    handleSelectAll,
    openDeleteAllModal,
    closeDeleteAllModal,
  };
};

export default useUsersPage;
