import React from "react";
import { MDBCardText, MDBCardImage, MDBTypography } from "mdb-react-ui-kit";
import ProfileLogo from "../assets/img/team/profile-icon.png";
import Loader from "./Loader";
import { Spin } from "antd";

const UserProfile = ({ user, tasksCount, projectsCount, isProfileLoading }) => {
  if (isProfileLoading) {
    return <Spin />;
  }

  return (
    <>
      <div
        className="rounded-top text-white d-flex flex-row"
        style={{ backgroundColor: "#262B40", height: "200px" }}
      >
        {isProfileLoading ? (
          <Loader />
        ) : (
          <>
            {" "}
            <div
              className="ms-4 mt-5 d-flex flex-column"
              style={{ width: "150px" }}
            >
              <MDBCardImage
                src={user?.image || ProfileLogo}
                alt={user?.name}
                className="mt-4 mb-2 img-thumbnail"
                fluid
                style={{ width: "150px", zIndex: "1" }}
              />
            </div>
            <div className="ms-3" style={{ marginTop: "130px" }}>
              <MDBTypography tag="h5">{user?.name}</MDBTypography>
              <MDBCardText>{user?.email}</MDBCardText>
            </div>
          </>
        )}
      </div>
      {!isProfileLoading && (
        <div className="p-4 text-black" style={{ backgroundColor: "#f8f9fa" }}>
          <div className="d-flex justify-content-end text-center py-1">
            <div>
              <MDBCardText className="mb-1 h5">{tasksCount}</MDBCardText>
              <MDBCardText className="small text-muted mb-0">Tasks</MDBCardText>
            </div>
            <div className="px-3">
              <MDBCardText className="mb-1 h5">{projectsCount}</MDBCardText>
              <MDBCardText className="small text-muted mb-0">
                Projects
              </MDBCardText>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
