import { Card } from "@themesberg/react-bootstrap";
import { useSetState } from "ahooks";
import { Form } from "antd";
import React, { useCallback, useContext, useEffect } from "react";
import SlackStatus from "../components/SlackStatus";
import TriggerKeywords from "../components/TriggerKeywords";
import { AuthContext } from "../context/auth-context";
import * as Data from "../helpers/server";
import { getUpdatedObjects } from "../helpers/utils";

const CustomEmoji = () => {
  const auth = useContext(AuthContext);
  const [form] = Form.useForm();
  const [taskForm] = Form.useForm();
  const [projectForm] = Form.useForm();

  const [state, setState] = useSetState({
    workingstatus: "",
    error: null,
    workingstatuses: [],
    status: "pending",
  });

  const [taskState, setTaskState] = useSetState({
    error: null,
    triggers: [],
    status: "pending",
  });

  const [projectState, setProjectState] = useSetState({
    error: null,
    triggers: [],
    status: "pending",
  });

  useEffect(() => {
    if (auth?.token) {
      getUpdatedConfig();
      getTaskTriggerConfigs("project");
      getProjectTriggerConfigs("task");
    }
  }, [auth?.token]);

  const getUpdatedConfig = async () => {
    setState({ status: "pending" });

    const { configs, err } = await Data.getConfigs(auth?.token);
    setState({ status: "idle" });
    if (err) return setState({ error: err, status: "error" });

    form.resetFields();
    const workingstatuses = [];
    for (let i = 0; i < configs.length; i++) {
      if (!configs[i].key.match(/^workingstatus_.*$/)) {
        setState({ [configs[i].key]: configs[i].value });
        form.setFieldValue(configs[i].key, configs[i].value);
      } else {
        configs[i].key = configs[i].key.replace("workingstatus_", "");
        workingstatuses.push({
          workingstatus_keyword: configs[i].key,
          workingstatus_status: configs[i].value,
        });
      }
    }
    form.setFieldValue("workingstatuses", workingstatuses);
  };

  const getTaskTriggerConfigs = async () => {
    setTaskState({ status: "pending" });

    const { triggers, err } = await Data.getKeywordConfigs(auth?.token, "task");
    setTaskState({ status: "idle", triggers });
    if (err) return setTaskState({ error: err, status: "error" });

    taskForm.resetFields();
    taskForm.setFieldValue("triggers", triggers);
  };

  const getProjectTriggerConfigs = async () => {
    setProjectState({ status: "pending" });

    const { triggers, err } = await Data.getKeywordConfigs(
      auth?.token,
      "project"
    );
    setProjectState({ status: "idle", triggers });
    if (err) return setProjectState({ error: err, status: "error" });

    projectForm.resetFields();
    projectForm.setFieldValue("triggers", triggers);
  };

  const updateConfigs = useCallback(async ({ workingstatuses }) => {
    setState({ status: "pending" });
    const { err } = await Data.updateConfigs({ workingstatuses }, auth?.token);

    if (err) return setState({ status: "error", error: err });

    setState({
      status: "complete",
      error: null,
      workingstatuses,
    });
  });

  const updateTaskKeywordConfigs = async ({ triggers: added }) => {
    const existingTriggers = taskState.triggers;

    const updatedTriggers = getUpdatedObjects(existingTriggers, added) || [];
    const newTriggers = added?.filter((item) => !item?.id) || [];
    const addedTriggers = [...updatedTriggers, ...newTriggers];
    const removedTriggers = existingTriggers.filter(
      (obj1) => !added.find((obj2) => obj1.id === obj2.id)
    );

    if (addedTriggers?.length <= 0 && removedTriggers?.length <= 0) return;

    setTaskState({ status: "pending" });
    const { err } = await Data.updateKeywordConfigs(
      { added: addedTriggers, removed: removedTriggers, type: "task" },
      auth?.token
    );

    if (err) return setTaskState({ status: "error", error: err });

    setTaskState({
      status: "complete",
      error: null,
      triggers: added?.map((item) => ({ ...item, id: item.id || Date.now() })),
    });
    getTaskTriggerConfigs();
  };

  const updateProjectKeywordConfigs = async ({ triggers: added }) => {
    const existingTriggers = projectState.triggers;

    const updatedTriggers = getUpdatedObjects(existingTriggers, added) || [];
    const newTriggers = added?.filter((item) => !item?.id) || [];
    const addedTriggers = [...updatedTriggers, ...newTriggers];
    const removedTriggers = existingTriggers.filter(
      (obj1) => !added.find((obj2) => obj1.id === obj2.id)
    );

    if (addedTriggers?.length <= 0 && removedTriggers?.length <= 0) return;

    setProjectState({ status: "pending" });
    const { err } = await Data.updateKeywordConfigs(
      { added: addedTriggers, removed: removedTriggers, type: "project" },
      auth?.token
    );

    if (err) return setProjectState({ status: "error", error: err });

    setProjectState({
      error: null,
      triggers: added?.map((item) => ({
        ...item,
        id: item.id,
      })),
    });
    getProjectTriggerConfigs();
  };

  const { status, error, workingstatuses } = state;

  return (
    <main className="content container-new">
      <div className="container userSetting">
        <div className="row">
          <div className="col-md-auto">
            <Card rd style={{ border: "none" }}>
              <Card.Body>
                <SlackStatus
                  error={error}
                  workingstatuses={workingstatuses}
                  form={form}
                  setState={setState}
                  status={status}
                  updateConfigs={updateConfigs}
                />
              </Card.Body>
            </Card>
          </div>
          <div className="col-5">
            <Card rd style={{ border: "none" }}>
              <Card.Body>
                <TriggerKeywords
                  error={projectState.error}
                  triggers={projectState.triggers}
                  form={projectForm}
                  setState={setProjectState}
                  status={projectState.status}
                  updateConfigs={updateProjectKeywordConfigs}
                  title="Project Keywords"
                />
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-md-auto">
            <Card rd style={{ border: "none" }}>
              <Card.Body>
                <TriggerKeywords
                  error={taskState.error}
                  triggers={taskState.triggers}
                  form={taskForm}
                  setState={setTaskState}
                  status={taskState.status}
                  updateConfigs={updateTaskKeywordConfigs}
                  title="Task Keywords"
                />
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CustomEmoji;
