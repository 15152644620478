import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "@themesberg/react-bootstrap";
import { Card, Col, Form, Input, Row } from "antd";
import React from "react";

const SlackStatus = ({
  status,
  form,
  workingstatuses,
  updateConfigs,
  setState,
  error,
}) => {
  return (
    <Card
      loading={status === "pending"}
      style={{
        width: 580,
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: "0px 5px 10px rgba(207, 214, 222, 0.25);",
      }}
      title="Slack Status"
    >
      <Form
        form={form}
        style={{}}
        layout="vertical"
        labelAlign="left"
        labelWrap
        name="basic"
        initialValues={{ workingstatuses }}
        onFinish={updateConfigs}
        onFieldsChange={() => {
          setState({ status: "idle", error: null });
        }}
      >
        <Form.List name="workingstatuses">
          {(fields, { add, remove }) => {
            return (
              <div style={{ marginTop: "10px" }}>
                {fields.map((field, index) => (
                  <div key={field.key}>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, "workingstatus_keyword"]}
                          colon={false}
                          rules={[
                            {
                              required: true,
                              message: "Please input keyword.",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Keyword" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, "workingstatus_status"]}
                          colon={false}
                          rules={[
                            {
                              required: false,
                              message: "Please input status string.",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Status String" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item colon={false}>
                      <Button
                        type="danger"
                        className="btn-link no-hover"
                        onClick={() => remove(field.name)}
                        icon={<MinusCircleOutlined />}
                      >
                        <span style={{ marginLeft: "-10px", color: "red" }}>
                          Remove Above Field
                        </span>
                      </Button>
                    </Form.Item>
                  </div>
                ))}
                <Form.Item colon={false}>
                  <Button
                    className="btn-link no-hover"
                    type="dashed"
                    onClick={(e) => {
                      e.preventDefault();
                      add();
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    <span style={{ marginLeft: "-10px" }}>
                      Add Working Status
                    </span>
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>

        <Row gutter={16}>
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <Form.Item
              label=" "
              colon={false}
              help={(() => {
                if (error) return error.message;

                if (status === "complete")
                  return "Status Emoji updated successfully!";
              })()}
            >
              <Button
                disabled={status === "pending"}
                type="primary"
                htmlType="submit"
                className="mb-3"
                style={{ padding: "10px 80px" }}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default SlackStatus;
