import { Modal } from "antd";
import { Button } from "@themesberg/react-bootstrap";
import BootstrapLoader from "../../components/BootstrapLoader";

const DeleteScheduleModal = (props) => {
  const {
    showModal,
    handleDeleteScheduleSubmit,
    handleClose,
    selectedSchedule,
    err,
    loading,
  } = props;

  return (
    <Modal
      title="Delete Confirmation!"
      open={showModal}
      className="select-none"
      onOk={handleDeleteScheduleSubmit}
      centered
      onCancel={handleClose}
      footer={[
        <div>
          <Button key="back" onClick={handleClose}>
            Cancel
          </Button>{" "}
          <Button
            value={selectedSchedule?.id}
            key="submit"
            type="primary"
            onClick={handleDeleteScheduleSubmit}
            disabled={loading}
          >
            Delete
            {loading && <BootstrapLoader />}
          </Button>
        </div>,
      ]}
    >
      {`Are you sure to delete scheduled message "${selectedSchedule?.message}"?`}
      <p style={{ color: "red" }}>{err || ""}</p>
    </Modal>
  );
};

export default DeleteScheduleModal;
