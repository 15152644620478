import { useContext, useEffect, useState, useCallback } from "react";
import moment from "moment-timezone";

import { AuthContext } from "../context/auth-context";
import * as Data from "../helpers/server";
import { removeDuplicates } from "../helpers/utils";

const pageSize = 10;
const dateTimeFormat = "dddd, HH:mm";
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const useSchedules = () => {
  const auth = useContext(AuthContext);

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState("desc");
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [err, setErr] = useState(null);
  const [createScheduleLoading, setCreateScheduleLoading] = useState(false);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);

  const getSchedules = useCallback(
    async (params) => {
      try {
        setLoading(true);
        const { schedules } = await Data.getScheduledMessages(
          auth?.token,
          params
        );

        if (schedules.length < pageSize) setShouldLoadMore(false);
        else setShouldLoadMore(true);

        setLoading(false);
        setSchedules((prev) => removeDuplicates(prev.concat(schedules), "id"));
      } catch (err) {
        console.log(err);
      }
    },
    [auth?.token]
  );

  const createSchedule = async (values, type, { isBirthdaySchedule }) => {
    let res;
    setCreateScheduleLoading(true);

    const newPageSize =
      schedules.length < pageSize ? pageSize : schedules.length;

    values.isBirthdaySchedule = isBirthdaySchedule;
    if (type === "create") {
      res = await Data.createNewMessageSchedule(values, auth?.token);
    } else {
      res = await Data.updateScheduledMessage(values.id, values, auth?.token);
    }
    setCreateScheduleLoading(false);

    if (res?.err) {
      setErr(res?.err);
      return { err: res?.err };
    }

    setSchedules([]);
    setErr(null);
    getSchedules({ page: 1, pageSize: newPageSize, order, isBirthdaySchedule });
    return {};
  };

  const changeSelectedSchedule = (id, form) => {
    const selectedSchedule =
      schedules.filter((schedule) => schedule.id == id)[0] || {};

    if (id) {
      const {
        day,
        time,
        channel_id,
        message,
        isrepeating,
        enabled,
        exact_date,
        start_date,
        end_date,
        interval,
        schedule_date,
        schedule_month,
        isbirthdayschedule,
      } = selectedSchedule;

      const ISODate = moment.utc(`${day}, ${time}`, dateTimeFormat);
      const localDate = moment.tz(ISODate, timeZone);

      form.setFieldsValue({
        message,
        channel: channel_id,
        isrepeating,
        enabled,
      });

      if (!isrepeating) form.setFieldsValue({ exact_date: moment(exact_date) });
      if (isrepeating) {
        form.setFieldsValue({ start_date: moment(start_date) });
        form.setFieldsValue({ end_date: moment(end_date) });
        form.setFieldsValue({ interval: interval });
        form.setFieldsValue({ time: localDate });
      }
      if (isbirthdayschedule) {
        const ISODate = moment.utc(
          `${schedule_date} ${schedule_month}, ${time}`,
          "D M, HH:mm"
        );
        const localDate = moment.tz(ISODate, timeZone);

        form.setFieldsValue({
          schedule_month: localDate.format("M").toString(),
        });
        form.setFieldsValue({
          schedule_date: localDate.format("D"),
        });
      }

      if (interval === "weekly") {
        form.setFieldsValue({ day: localDate.format("dddd") });
      }

      if (interval === "monthly" || interval === "yearly") {
        const ISODate = moment.utc(
          `${schedule_date} ${schedule_month ?? 1}, ${time}`,
          "D M, HH:mm"
        );
        const localDate = moment.tz(ISODate, timeZone);

        form.setFieldsValue({ schedule_date: localDate.format("D") });

        if (interval === "yearly") {
          form.setFieldsValue({ schedule_month: localDate.format("M") });
        }
      }
    } else {
      form.resetFields();
      form.setFieldsValue({ enabled: true });
    }
    setSelectedSchedule(selectedSchedule);
  };

  const deleteSchedule = async (id, { isBirthdaySchedule }) => {
    try {
      const newPageSize =
        schedules.length < pageSize ? pageSize : schedules.length;

      setCreateScheduleLoading(true);
      const { err } = await Data.deleteScheduledMessage(id, auth?.token);
      setCreateScheduleLoading(false);

      if (err) return { err };

      setSchedules([]);
      getSchedules({
        page: 1,
        pageSize: newPageSize,
        order,
        isBirthdaySchedule,
      });
      return {};
    } catch (err) {
      console.log(err);
    }
  };

  const deleteMultipleSchedules = async (ids) => {
    await Data.deleteAllScheduleMessage(ids, auth?.token);
    setSchedules((schedules) =>
      schedules.filter((schedule) => !ids.includes(schedule.id))
    );
  };

  const changeOrder = async ({ isBirthdaySchedule }) => {
    const newOrder = order === "desc" ? "asc" : "desc";
    setOrder((order) => (order === "desc" ? "asc" : "desc"));
    setPage(1);
    setSchedules([]);
    await getSchedules({
      page: 1,
      pageSize,
      order: newOrder,
      isBirthdaySchedule,
    });
  };

  return {
    loading,
    schedules,
    createSchedule,
    createScheduleLoading,
    selectedSchedule,
    setSelectedSchedule,
    changeSelectedSchedule,
    deleteSchedule,
    changeOrder,
    order,
    err,
    setErr,
    shouldLoadMore,
    getSchedules,
    page,
    setPage,
    deleteMultipleSchedules,
  };
};

export default useSchedules;
