import { Button } from "@themesberg/react-bootstrap";
import { useSetState } from "ahooks";
import { Card, Form, Input } from "antd";
import { useCallback, useContext, useEffect } from "react";

import { AuthContext } from "../context/auth-context";
import { chromeExtensionId } from "../data/constants";
import * as Data from "../helpers/server";
import ImageUpload from "./ImageUpload";

/* global chrome */

const UpdateProfile = () => {
  const auth = useContext(AuthContext);

  const [state, setState] = useSetState({
    status: "pending",
    name: auth?.name || "",
    error: null,
    image: auth?.image || "",
  });

  useEffect(() => {
    if (auth && status === "pending") {
      setState({
        status: "idle",
      });
    }
  }, [auth]);

  const updateProfile = useCallback(async ({ name, image }) => {
    setState({ status: "pending" });
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", image);

    const { user, err } = await Data.editUserProfile(formData, auth?.token);
    if (err) return setState({ status: "error", error: err });

    if (window.navigator.userAgent.toLowerCase().includes("chrome")) {
      chrome.runtime?.sendMessage(chromeExtensionId, {
        userId: user?.id,
        token: auth?.token,
        ...user,
      });
    }

    auth.login(
      user?.id,
      auth?.token,
      user?.name,
      user?.email,
      user?.image,
      user?.isadmin,
      user?.isprojectmanager,
      user?.uid,
      user?.isAppInstalled,
      user?.workspace_id,
      user?.breakstatus,
      user?.isteammanager
    );
    setState({
      status: "complete",
      error: null,
      name: user.name,
    });
  });

  const onImageUplaod = (field, value, isValid) => {
    setState({ [field]: value });
  };

  const { status, error, name, image } = state;

  return (
    <div className="userSettingList">
      <Card title="Update Profile" loading={status === "pending"}>
        <Form
          style={{}}
          wrapperCol={{ span: 16 }}
          labelCol={{ span: 7 }}
          labelAlign="left"
          labelWrap
          name="basic"
          initialValues={{
            name,
          }}
          onFinish={({ name }) => {
            updateProfile({ name, image });
          }}
          onFieldsChange={() => {
            setState({ status: "idle", error: null });
          }}
        >
          <Form.Item
            label="Display Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your new display name.",
                min: 3,
                message: "Name must be 3 letters long.",
              },
            ]}
            hasFeedback={!!error}
            validateStatus={error && "error"}
          >
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item label="Profile Image" name="image">
            <ImageUpload
              center
              id="image"
              onInput={onImageUplaod}
              errorText="Please select a valid image."
              image={auth?.image}
            />
          </Form.Item>

          <Form.Item
            label=" "
            colon={false}
            help={(() => {
              if (error) {
                return error.message;
              }

              if (status === "complete") {
                return "Profile updated successfully!";
              }
            })()}
          >
            <Button
              disabled={status === "pending"}
              type="primary"
              htmlType="submit"
              className="mb-1"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default UpdateProfile;
