import { Button } from "@themesberg/react-bootstrap";
import { useSetState } from "ahooks";
import { Card, Col, Form, Input, InputNumber, Row } from "antd";
import { useCallback, useContext, useEffect } from "react";

import { AuthContext } from "../context/auth-context";
import * as Data from "../helpers/server";

const UpdateEmojiSettings = () => {
  const auth = useContext(AuthContext);
  const [form] = Form.useForm();

  const [state, setState] = useSetState({
    status: "pending",
    workingstatus: "",
    error: null,
    pausestatus: "",
    breakstatus: "",
    notificationHours: "",
    eodstatus: "",
    focusstatus: "",
    awaystatus: "",
  });

  const getUpdatedConfig = async () => {
    setState({ status: "pending" });
    const { configs, err } = await Data.getConfigs(auth?.token);
    setState({ status: "idle" });
    if (err) {
      return setState({ error: err, status: "error" });
    }

    form.resetFields();

    for (let i = 0; i < configs.length; i++) {
      if (!configs[i].key.match(/^workingstatus_.*$/)) {
        setState({ [configs[i].key]: configs[i].value });
        form.setFieldValue(configs[i].key, configs[i].value);
      } else {
        configs[i].key = configs[i].key.replace("workingstatus_", "");
      }
    }
  };

  useEffect(() => {
    if (auth && status === "pending") {
      setState({
        status: "idle",
      });
    }
  }, [auth]);

  useEffect(() => {
    getUpdatedConfig();
  }, []);

  const updateConfigs = useCallback(
    async ({
      workingstatus,
      pausestatus,
      breakstatus,
      notificationHours,
      eodstatus,
      focusstatus,
      awaystatus,
    }) => {
      setState({ status: "pending" });

      const { err } = await Data.updateConfigs(
        {
          workingstatus,
          pausestatus,
          breakstatus,
          notificationHours,
          eodstatus,
          focusstatus,
          awaystatus,
        },
        auth?.token
      );
      if (err) return setState({ status: "error", error: err });

      setState({
        status: "complete",
        error: null,
        workingstatus,
        pausestatus,
        breakstatus,
        notificationHours,
        eodstatus,
        focusstatus,
        awaystatus,
      });
    }
  );

  const {
    status,
    error,
    workingstatus,
    pausestatus,
    breakstatus,
    notificationHours,
    eodstatus,
    focusstatus,
    awaystatus,
  } = state;
  return (
    <div className="userSettingList">
      <Card title="Update Workspace Configs" loading={status === "pending"}>
        <Form
          form={form}
          style={{}}
          layout="vertical"
          labelAlign="left"
          labelWrap
          name="basic"
          initialValues={{
            workingstatus,
            pausestatus,
            breakstatus,
            notificationHours,
            eodstatus,
            focusstatus,
            awaystatus,
          }}
          onFinish={(values) => {
            updateConfigs({
              ...values,
            });
          }}
          onFieldsChange={() => {
            setState({ status: "idle", error: null });
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Working Status"
                name="workingstatus"
                tooltip={"use [project] and [task] for dynamic status"}
                rules={[
                  {
                    required: true,
                    message: "Please input working status.",
                    min: 3,
                    message: "Must be 3 letters long.",
                  },
                ]}
                hasFeedback={!!error}
                validateStatus={error && "error"}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Pause Status"
                name="pausestatus"
                rules={[
                  {
                    required: true,
                    message: "Please input pause status.",
                    min: 3,
                    message: "Must be 3 letters long.",
                  },
                ]}
                hasFeedback={!!error}
                validateStatus={error && "error"}
              >
                <Input maxLength={50} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Break Status"
                name="breakstatus"
                rules={[
                  {
                    required: true,
                    message: "Please input break status.",
                    min: 3,
                    message: "Must be 3 letters long.",
                  },
                ]}
                hasFeedback={!!error}
                validateStatus={error && "error"}
              >
                <Input maxLength={50} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Notification Hours"
                name="notificationHours"
                rules={[
                  {
                    required: true,
                    message: "Please input notification hours.",
                  },
                ]}
                hasFeedback={!!error}
                validateStatus={error && "error"}
              >
                <InputNumber min={1} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="EOD Status"
                name="eodstatus"
                rules={[
                  {
                    required: true,
                    message: "Please input EOD status.",
                    min: 3,
                    message: "Must be 3 letters long.",
                  },
                ]}
                hasFeedback={!!error}
                validateStatus={error && "error"}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Focus Status"
                name="focusstatus"
                rules={[
                  {
                    required: true,
                    message: "Please input focus status.",
                    min: 3,
                    message: "Must be 3 letters long.",
                  },
                ]}
                hasFeedback={!!error}
                validateStatus={error && "error"}
              >
                <Input maxLength={50} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Away Status"
                name="awaystatus"
                rules={[
                  {
                    required: true,
                    message: "Please input away status.",
                    min: 3,
                    message: "Must be 3 letters long.",
                  },
                ]}
                hasFeedback={!!error}
                validateStatus={error && "error"}
              >
                <Input maxLength={50} />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>

          <Row gutter={16}>
            <Col span={9}></Col>
            <Col span={15}>
              <Form.Item
                colon={false}
                help={(() => {
                  if (error) {
                    return error.message;
                  }

                  if (status === "complete") {
                    return "Status Emoji updated successfully!";
                  }
                })()}
              >
                <Button
                  disabled={status === "pending"}
                  type="primary"
                  htmlType="submit"
                  className="mb-1"
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default UpdateEmojiSettings;
