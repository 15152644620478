import "./styles.css";

import DeletionImg from "../../../assets/img/SlashCommands/DeletionImg.png";
import HelpImg from "../../../assets/img/SlashCommands/HelpImg.png";
import StartResetImg from "../../../assets/img/SlashCommands/Start-Reset.jpg";
import StatusImg from "../../../assets/img/SlashCommands/StatusImg.png";
import StopImg from "../../../assets/img/SlashCommands/StopImg.png";
import SummaryImg from "../../../assets/img/SlashCommands/SummaryImg.png";

const SlackCommand = () => {
  return (
    <div className="slack-card-container">
      <div className="slack-content-left">
        <div className="image-div">
          <img src={StatusImg} alt="" />
        </div>
        <div className="content-div">
          <h3>Show teammates when you're busy</h3>
          <p>
            Tired of getting pinged when you are in a meeting? Automatically
            update your status in Slack so your teammates know why you might be
            slow to respond.
          </p>
        </div>
      </div>

      <div className="slack-content">
        <div className="image-div">
          <img src={StartResetImg} alt="" />
        </div>
        <div className="content-div">
          <h3>Track your activity</h3>
          <p>
            Easily start tracking your activity by /axolotl_start command and
            select the task you are working on and also reset your current timer
            by /axolotl_reset command
          </p>
        </div>
      </div>

      <div className="slack-content-left">
        <div className="image-div">
          <img src={StopImg} alt="" />
        </div>
        <div className="content-div">
          <h3>Pause your activity</h3>
          <p>
            You can easily stop or resume your current activity timer by
            /axolotl _stop command
          </p>
        </div>
      </div>

      <div className="slack-content">
        <div className="image-div">
          <img src={SummaryImg} alt="" />
        </div>
        <div className="content-div">
          <h3>Activity summary</h3>
          <p>
            Get summary of your current task by /axolotl_summary command. You
            can also end your tracking for day by /axolotl_end command and get
            summary of your work done
          </p>
        </div>
      </div>

      <div className="slack-content-left">
        <div className="image-div">
          <img src={HelpImg} alt="" />
        </div>
        <div className="content-div">
          <h3>Help commands</h3>
          <p>Get help of our slash commands by our /axolotl_info command</p>
        </div>
      </div>

      <div className="slack-content">
        <div className="image-div">
          <img src={DeletionImg} alt="" />
        </div>
        <div className="content-div">
          <h3>Request data deletion</h3>
          <p>
            axolotl_delete_data can be used if you want to remove your personal
            details from our database.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SlackCommand;
