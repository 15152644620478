import { faSignOutAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Button,
  Dropdown,
  Image,
  Nav,
  Navbar,
} from "@themesberg/react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import SimpleBar from "simplebar-react";

import moment from "moment";
import AppLogo from "../assets/img/logo/app_nav_logo.png";
import { AuthContext } from "../context/auth-context";
import { chromeExtensionId } from "../data/constants";
import navItemsData from "../data/sidebar";
import { PagesRoutes } from "../routes";

/* global chrome */
const Sidebar = ({ unreadNotificationsCount, pendingRequestsCount }) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const showClass = show ? "show" : "";
  const auth = useContext(AuthContext);
  const isAxolotlOwner = localStorage.getItem("isAxolotlOwner") === "true";
  let navItems = navItemsData;

  useEffect(() => {
    const expirationDate = JSON.parse(
      localStorage.getItem("userData")
    )?.expiration;
    if (moment(expirationDate).isBefore(moment())) auth?.logout();
  }, []);

  const onCollapse = () => setShow(!show);

  if (auth.isadmin) {
    navItems = navItems.filter(
      (item) =>
        !item.disabled || (item.disabled && !item.disabled.includes("admin"))
    );
  }

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, isAppLogo } =
      props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link
          {...linkProps}
          target={target}
          className={classNames}
          as={linkProps.as}
          to={linkProps.to}
        >
          <span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon imageRendering={<Image src={image} />} />
              {icon ? (
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={icon} />
                </span>
              ) : null}
              {/* {!isAppLogo && image && (
                <Image
                  src={image}
                  style={{ width: "17px", height: "20px", paddingTop: "6px" }}
                  className="sidebar-icon"
                />
              )} */}
              {isAppLogo && (
                <Image
                  src={image}
                  width={50}
                  height={50}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              )}
              <span className="sidebar-text">{title}</span>{" "}
              {unreadNotificationsCount &&
              linkProps.to === PagesRoutes.Notifications.path ? (
                <Badge
                  pill
                  className="badge-md notification-count ms-2"
                  style={{ backgroundColor: "#fbd5d5", color: "#b34c4c" }}
                >
                  {unreadNotificationsCount}
                </Badge>
              ) : null}
              {parseInt(pendingRequestsCount) &&
              linkProps.to === PagesRoutes.TimeEditingRequests.path ? (
                <Badge
                  pill
                  className="badge-md notification-count ms-2"
                  style={{ backgroundColor: "#fbd5d5", color: "#b34c4c" }}
                >
                  {pendingRequestsCount}
                </Badge>
              ) : null}
            </div>
          </span>
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="light"
        bg="light"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={PagesRoutes.Dashboard.path}
        >
          <Image src={AppLogo} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-black`}
        >
          <div className="sidebar-inner pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={auth.image}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h6>{auth.name}</h6>
                  <Button
                    onClick={() => {
                      if (
                        window.navigator.userAgent
                          .toLowerCase()
                          .includes("chrome")
                      ) {
                        chrome.runtime?.sendMessage(
                          chromeExtensionId,
                          "logout"
                        );
                      }
                      auth.logout();
                      navigate(PagesRoutes.HomePage.path);
                    }}
                    variant="secondary"
                    className="text-dark"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <div style={{}}>
              <Nav className="flex-column pt-3 pt-md-0">
                {navItems.map((navItem) => {
                  if (auth.isprojectmanager && navItem.roles.indexOf("pm") >= 0)
                    return <NavItem {...navItem} />;
                  if (auth.isteammanager && navItem.roles.indexOf("tm") >= 0)
                    return <NavItem {...navItem} />;
                  else if (auth.isadmin && navItem.roles.indexOf("admin") >= 0)
                    return <NavItem {...navItem} />;
                  else if (auth.isadmin && navItem.roles.indexOf("admin") < 0)
                    return null;
                  else if (
                    isAxolotlOwner &&
                    navItem.roles.indexOf("axolotl_owner") >= 0
                  )
                    return <NavItem {...navItem} />;
                  else if (
                    isAxolotlOwner &&
                    navItem.roles.indexOf("axolotl_owner") < 0
                  )
                    return null;
                  else if (navItem.roles.indexOf("all") >= 0)
                    return <NavItem {...navItem} />;
                })}
              </Nav>
            </div>
            <Dropdown.Divider className="my-3 border-indigo" />
            <div
              style={{
                height: "150px",
                backgroundColor: "white",
              }}
            ></div>
            <div
              style={{
                position: "fixed",
                bottom: "0px",
                backgroundColor: "white",
                width: "260px",
                height: "130px",
              }}
            >
              <div style={{ height: "30px", backgroundColor: "white" }}></div>

              <Nav.Link
                href={PagesRoutes.HomePage.path}
                onClick={() => {
                  if (
                    window.navigator.userAgent.toLowerCase().includes("chrome")
                  ) {
                    chrome.runtime?.sendMessage(chromeExtensionId, "logout");
                  }
                  auth.logout();
                  navigate(PagesRoutes.HomePage.path);
                }}
                style={{
                  boxShadow: "-5px -4px 20px rgba(0, 0, 0, 0.02)",
                  paddingLeft: "18px",
                }}
              >
                <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign
                Out
              </Nav.Link>
            </div>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

export default Sidebar;
