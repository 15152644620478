import { Bar as BarChart } from "react-chartjs-2";
import EmptyComponent from "../EmptyComponent";

const Bar = (props) => {
  const { labels, title, dataSets = [] } = props;

  if (dataSets.length === 0) {
    return (
      <div
        style={{
          width: "95%",
          height: "400px",
          position: "relative",
          margin: "0 auto",
        }}
      >
        <div
          style={{
            width: "300px",
            position: "absolute",
            top: "40%",
            left: "40%",
          }}
        >
          <EmptyComponent description="No data available" />
        </div>
      </div>
    );
  }

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: 0,
        },
        min: labels[0],
      },
      y: {
        stacked: true,
        grid: {
          display: 0,
        },
        beginAtZero: true
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: title,
      },
      tooltip: {
        usePointStyle: true,
        boxWidth: 6,
        boxPadding: 2,
        padding: { top: 8, left: 5, right: 5, bottom: 6 },
        borderWidth: 1,
        callbacks: {
          label: (itemObj) => {
            const index = itemObj.dataIndex;
            const dataSetIndex = itemObj.datasetIndex;
            const item = itemObj.dataset;
            return `${item.label} ${dataSets[dataSetIndex].time[index]}`;
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: dataSets,
  };

  return <BarChart options={options} data={data} width={1120} height={400} />;
};

export default Bar;
