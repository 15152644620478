import { Empty } from "antd";

const EmptyComponent = ({ description }) => {
  return (
    <>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={description}
        className="empty-component m-0"
      />
    </>
  );
};

export default EmptyComponent;
