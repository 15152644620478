import React from "react";

const UserTaskHeader = ({
  taskname,
  user,
  hours,
  minutes,
  seconds,
  projectname,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
      <div style={{ width: "20%" }}>{taskname}</div>
      <div style={{ width: "15%" }}>{user}</div>
      <div style={{ width: "10%" }}>
        {hours}:{minutes}:{seconds}
      </div>
      <div
        style={{
          margin: "0 auto",
          width: "20%",
        }}
      >
        <b>{projectname}</b>
      </div>
    </div>
  );
};

export default UserTaskHeader;
